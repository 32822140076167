<template>
  <div class="card mb-5">
    <!--Header-->
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h4 class="mb-0 text-primary">
            {{ $t('investorDetails.investment.title') }}
          </h4>
        </div>
        <cp-edit-buttons
          v-if="hasInvestment"
          :edit-mode="isDetailsEditableCurrentBlock"
          :disable-edit="isEditButtonDisabled"
          @changeMode="changeModeProxy"
          @update="saveChanges"
        />
      </div>
    </div>
    <div v-if="showMainContent">
      <!--Rounds & status-->
      <hr class="my-0 mx-0">
      <div class="card-body bg-light">
        <div class="row align-items-center">
          <div class="col-md-3">
            <div class="row align-items-center">
              <label class="col-form-label col-sm-3 text-sm cp-label-override"> {{ $t('investorDetails.investment.label.token') }} </label>
              <cp-local-select-token
                :controlled-token-id="tokensSelectorModel"
                :token-names-list="allTokensList"
                @onTokenSelect="onTokenSelect"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="row align-items-center">
              <label class="col-form-label col-sm-3 text-sm cp-label-override">{{ $t('investorDetails.investment.label.round') }}</label>
              <cp-local-select-round
                class="col-sm-9"
                :disabled="disableSelectRound"
                :controlled-round-id="roundIdSelectorModel"
                :round-names-list="allRoundNamesList"
                @onRoundSelect="onRoundSelect"
              />
            </div>
          </div>
          <div class="mr-lg-3 ml-lg-2">
            <span class="small">{{ $t('investorDetails.investment.label.status') }}</span>
            <span :class="activeStatusColor">{{ roundStatusLabel }}</span>
            <span
              v-if="isEvergreenRound"
              :class="activeStatusColor"
            >{{ $t('investorDetails.investment.label.isEvergreenRoundStatus') }}</span>
          </div>
          <div class="col-md-4">
            <div class="row align-items-center">
              <label class="col-form-label col-sm-4 text-sm">{{ $t('investorDetails.investment.label.investmentStatus') }}</label>
              <investment-status
                :round-id="currentRound && currentRound.id"
                :has-investment="hasInvestment"
                :investment-status="model.status"
                class="status-select"
              />
            </div>
          </div>
        </div>
      </div>
      <hr class="my-0 mx-0">
      <!--general data-->
      <div
        class="card-body"
      >
        <div
          v-if="!hasInvestment || !currentRound "
          class="d-flex justify-content-center"
        >
          <div class="d-flex flex-column justify-content-center">
            <h6
              v-if="!hasInvestment && currentRound "
              class="text-center"
            >
              {{ $t('investorDetails.investment.message.noData') }}
            </h6>
            <h6
              v-if="!hasInvestment && !currentRound "
              class="text-center"
            >
              {{ $t('investorDetails.investment.message.noRound') }}
            </h6>
            <div
              v-if="!hasInvestment && currentRound"
              class="d-flex flex-column justify-content-center"
            >
              <cp-button
                icon="ios-add-circle-outline"
                @click="handleShowInvestmentModal"
              >
                {{
                  loading
                    ? $t('investorDetails.investment.button.investment.loading')
                    : $t('investorDetails.investment.button.investment.add')
                }}
              </cp-button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-11">
            <div
              v-if="hasInvestment"
              class="row"
            >
              <div class="col-3">
                <div class="text-muted small">
                  {{ $t('investorDetails.investment.label.pledgedAmount') }}
                </div>
                <div class="pledgedBlock">
                  <strong>
                    <span>
                      {{ pledgedCurrencyByIdentifier }} {{ formatToken(amount, pledgedCurrencyByIdentifier) }}
                    </span>
                    <br>
                    <span v-if="!!amount">
                      ({{ getPriceRate(pledgedCurrencyByIdentifier, amount) }})
                    </span>
                  </strong>
                  <div
                    v-if="isDetailsEditableCurrentBlock"
                    class="d-flex"
                  >
                    <div class="col-5">
                      <cp-input
                        v-model="amount"
                        input-type="number"
                      />
                    </div>
                    <div
                      v-if="isInvestmentFunded"
                      class="col-6"
                    >
                      <cp-select
                        v-model="currency"
                        name="currency"
                        placeholder
                        :options="currencyList"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-2">
                <div class="text-muted small">
                  {{ $t('investorDetails.investment.label.pledgedDate') }}
                </div>
                <cp-date-picker
                  v-if="isDetailsEditableCurrentBlock"
                  v-model="date"
                  name="date"
                />
                <h5
                  v-else
                  class="m-0"
                >
                  <strong>
                    {{ date | dateFilter }}
                  </strong>
                </h5>
              </div>
              <div
                v-if="!!!isDetailsEditableCurrentBlock"
                class="col-2"
              >
                <div class="text-muted small">
                  {{ $t('investorDetails.investment.label.pledgedInitiator') }}
                </div>
                <h5>{{ model.pledged.initiator }}</h5>
              </div>
              <div class="col-1">
                <div class="text-muted small">
                  {{ $t('investorDetails.investment.label.totalFunded') }}
                </div>
                <h5 class="m-0">
                  <strong>
                    {{ totalFundedIdentifier }}
                    <span v-if="isTotalFunded">
                      {{ getTotalFunded.amount | exponentialToDecimal }}
                    </span>
                    <br>
                    <span
                      v-if="isTotalFunded"
                      class="small"
                    >
                      ({{ getPriceRate(totalFundedIdentifier, getTotalFunded.amount) }})
                    </span>
                  </strong>
                </h5>
              </div>
              <div class="col-2">
                <div class="text-muted small">
                  {{ $t('investorDetails.investment.label.subscriptionAgreement') }}
                </div>
                <h5 class="m-0">
                  <cp-editable-input
                    v-model="model.subscriptionAgreementStatus"
                    :edit-mode="isDetailsEditableCurrentBlock"
                    :text="subscriptionAgreementName"
                    :options="subscriptionAgreementFields"
                  />
                </h5>
              </div>
              <div class="col-2">
                <div class="text-muted small">
                  {{ $t('investorDetails.investment.label.subscriptionAgreementSignedAt') }}
                </div>
                <cp-date-picker
                  v-if="isDetailsEditableCurrentBlock"
                  v-model="model.subscriptionAgreementSignedAt"
                />
                <h5
                  v-else
                  class="m-0"
                >
                  <strong>
                    {{ model.subscriptionAgreementSignedAt | dateFilter }}
                  </strong>
                </h5>
              </div>
            </div>
          </div>

          <div
            v-if="hasInvestment"
            class="col-sm-1 column-align-button-wrap"
          >
            <div class="column-align-button">
              <div class="pb-1">
                <b-tooltip
                  v-if="!savedPledgedAmount"
                  target="send-instructions-email"
                  triggers="hover"
                >
                  {{ $t('investorDetails.investment.button.investment.sendInstructionsEmail.toolTip') }}
                </b-tooltip>
                <span id="send-instructions-email">
                  <cp-button
                    v-if="isFboInvestor"
                    :disabled="!savedPledgedAmount"
                    variant="outline-primary"
                    icon="ios-finger-print"
                    @click="sendInstructionsEmail"
                  >
                    {{ $t('investorDetails.investment.button.investment.sendInstructionsEmail.sendInstructions') }}
                  </cp-button>
                </span>
              </div>
              <div>
                <cp-button
                  v-if="isFboInvestor"
                  :disabled="!savedPledgedAmount"
                  variant="outline-primary"
                  icon="ios-finger-print"
                  @click="sendAgreementEmails"
                >
                  {{ $t('investorDetails.investment.button.investment.sendAgreementEmail.sendAgreement') }}
                </cp-button>
              </div>

              <cp-button
                v-if="currentRound && !currentRound.subscriptionAgreementDocusignId"
                variant="outline-primary"
                @click="showSubscriptionAgreementStatusLogsModal"
              >
                {{ $t('investorDetails.subscriptionAgreementStatusLogsModal.title') }}
              </cp-button>

              <subscription-agreement-status-logs-modal
                id="subscriptionAgreementStatusLogs"
                ref="subscriptionAgreementStatusLogsModal"
                :title="$t('investorDetails.subscriptionAgreementStatusLogsModal.title')"
                :round-id="queryParams.roundId"
                :investment-request-id="model.investmentRequestForDisplay && model.investmentRequestForDisplay.id"
                :token-id="currentTokenId || ''"
                :issuer-id="queryParams.issuerId || ''"
                :investor-id="queryParams.userId || ''"
              />

              <!-- add investment request for evergreen -->
              <div
                v-if="shouldDisplayAddInvestmentForEvergreen"
                class="pt-1"
              >
                <div class="column-align-button">
                  <cp-button
                    variant="outline-primary"
                    :disabled="shouldDisableAddInvestmentForEvergreen"
                    icon="ios-add-circle-outline"
                    @click="handleShowNextInvestmentModal"
                  >
                    {{
                      loading
                        ? $t('investorDetails.investment.button.investment.loading')
                        : $t('investorDetails.investment.button.investment.add')
                    }}
                  </cp-button>
                </div>
              </div>
              <!-- add investment request for evergreen END -->
            </div>
          </div>
        </div>
      </div>

      <hr class="my-0 mx-0">

      <!-- investment table section -->
      <div v-if="showInvestmentTable">
        <div class="card-header">
          <div class="row align-items-end">
            <div class="col-md-6">
              <h5 class="my-0 mt-4">
                {{ $t('investorDetails.investment.subTitle.investments') }}
              </h5>
            </div>
          </div>
        </div>
        <cp-table
          ref="investmentRequestsTable"
          without-search
          :filters="filters"
          get-data-action="investors/getInvestorInvestmentNoStateChange"
          :url-params="queryParams"
          :data-parser="investmentsParser"
          :fields="investmentsFieldsLocalized"
        >
          <template
            slot="createdAt"
            slot-scope="{ rowData }"
          >
            {{ rowData.item.createdAt | standardDateFilter }}
          </template>
          <template
            slot="subscriptionAgreementSignedAt"
            slot-scope="{ rowData }"
          >
            {{ rowData.item.subscriptionAgreementSignedAt | standardDateFilter }}
          </template>
          <template
            slot="pledged"
            slot-scope="{ rowData }"
          >
            {{ displayAmountAndCurrency(rowData.item.pledged) }}
          </template>
          <template
            slot="totalFunded"
            slot-scope="{ rowData }"
          >
            {{ displayAmountAndCurrency(rowData.item.totalFunded) }}
          </template>
        </cp-table>
      </div>
      <!-- investment table section END -->

      <!--add transactions button-->
      <div class="card-header">
        <div class="row align-items-end">
          <div class="col-md-6">
            <h5 class="my-0 mt-4">
              {{ $t('investorDetails.investment.subTitle.transactions') }}
            </h5>
          </div>
          <div class="col-md-6 d-flex justify-content-end">
            <div>
              <b-tooltip
                v-if="shouldDisplayAddInvestmentToolTip"
                target="display-add-investment-tool-tip"
                triggers="hover"
              >
                {{ $t('investorDetails.investment.button.transaction.toolTip') }}
              </b-tooltip>
              <div id="display-add-investment-tool-tip">
                <cp-button
                  :disabled="shouldDisplayAddInvestmentToolTip"
                  variant="outline-primary"
                  icon="ios-add-circle-outline"
                  @click="showAddTransactionModal"
                >
                  {{ $t('investorDetails.investment.button.transaction.add') }}
                </cp-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--add transactions modal-->
      <add-transaction-modal
        ref="addTransaction"
        :round-id="currentRound && currentRound.id"
        :token-id="currentTokenId"
        :deposit-wallets="model.depositWallets"
        :investment-request="model.investmentRequestForDisplay"
        @transactionIsAdded="updateInvestment"
      />

      <!--edit transactions modal-->
      <edit-transaction-modal
        ref="editTransaction"
        :transaction-data="editableTransaction"
        @onTransactionIsEdit="onTransactionIsEdit"
      />

      <!--transactions table-->
      <cp-table
        ref="transactionsTable"
        without-search
        :filters="filters"
        get-data-action="investors/getInvestorInvestmentNoStateChange"
        :url-params="queryParams"
        :data-parser="transactionsParser"
        :fields="transactionsFieldsLocalized"
      >
        <template
          slot="transactionTime"
          slot-scope="{ rowData }"
        >
          {{ rowData.item.transactionTime | transactionTimeFilter }}
        </template>

        <template
          slot="usdWorth"
          slot-scope="{ rowData }"
        >
          {{ formatCurrency(rowData.item.usdWorth, issuerMainCurrencyIdentifier) }}
        </template>

        <template
          slot="amount"
          slot-scope="{ rowData }"
        >
          <cp-ellipsis :data-value="rowData.item.amount" />
        </template>

        <template
          slot="direction"
          slot-scope="{ rowData }"
        >
          <span>
            {{ $t(`investorDetails.investment.status.transactionType.${rowData.item.direction}`) }}
          </span>
        </template>

        <template
          slot="currencyId"
          slot-scope="{ rowData }"
        >
          {{ getCurrencyValues(rowData.item.currencyId, 'name') }}
        </template>

        <template
          slot="source"
          slot-scope="{ rowData }"
        >
          {{ $t(i18nKeys.sources[rowData.item.source]) }}
        </template>

        <template
          slot="actions"
          slot-scope="{ rowData }"
        >
          <div class="d-flex">
            <cp-button
              v-if="isEditable(rowData.item)"
              variant="default btn-xs icon-btn md-btn-flat"
              @click="showEditTransactionModal(rowData.item)"
            >
              <i class="ion ion-md-create" />
            </cp-button>

            <cp-button
              v-if="isDeletable(rowData.item)"
              variant="default btn-xs icon-btn md-btn-flat ml-3"
              @click="openDeleteModal(rowData.item, 'transaction')"
            >
              <i class="ion ion-ios-trash" />
            </cp-button>
          </div>
        </template>
      </cp-table>

      <hr class="my-0 mx-0">

      <!--add deposit addresses button & modal-->
      <div class="card-header">
        <div class="row align-items-end">
          <div class="col-md-6">
            <h5 class="my-0 mt-4">
              {{ $t('investorDetails.investment.subTitle.depositAddresses') }}
            </h5>
          </div>
        </div>
      </div>

      <!--deposit addresses table-->
      <cp-table
        ref="depositTable"
        without-search
        get-data-action="investors/getInvestorInvestmentNoStateChange"
        :url-params="queryParams"
        :data-parser="depositAddressParser"
        :fields="depositAddressFields"
      >
        <template
          slot="address"
          slot-scope="{ rowData }"
        >
          <div>
            <a
              v-if="getAddressUrl(rowData.item)"
              :href="getAddressUrl(rowData.item)"
              target="_blank"
            >
              {{ rowData.item.address }}
            </a>
            <span v-else>
              {{ rowData.item.address }}
            </span>
          </div>
        </template>

        <template
          slot="investorData"
          slot-scope="{ rowData }"
        >
          {{ rowData.item.investorData || '-' }}
        </template>

        <template
          slot="actions"
          slot-scope="{ rowData }"
        >
          <div class="d-flex">
            <cp-button
              variant="default btn-xs icon-btn md-btn-flat"
              @click="showEditDepositWalletModal(rowData.item)"
            >
              <i class="ion ion-md-create" />
            </cp-button>
          </div>
        </template>
      </cp-table>

      <edit-deposit-wallet-modal
        ref="cpEditDepositWalletModal"
        :wallet="editableDepositWallet"
        @depositWalletIsUpdated="updateDepositWalletsTable"
      />
    </div>

    <cp-confirm-modal
      ref="cpConfirmModal"
      :title="confirmModalTitle"
      :item="deleteItem"
      @onOk="removeEntity"
    >
      {{ confirmModalText }}
    </cp-confirm-modal>
  </div>
</template>

<script>
import {
  mapGetters, mapActions, mapMutations, mapState,
} from 'vuex';

import { investment as investmentFields } from '../options';
import CpEditButtons from '~/components/common/edit-buttons';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import CpEditableInput from '~/components/common/editable-input';
import CpDatePicker from '~/components/common/standalone-components/inputs/cp-date-picker';
import CpButton from '~/components/common/standalone-components/cp-button';
import cpLocalSelectToken from '~/components/modules/cp-local-select-token/selector';
import cpLocalSelectRound from '~/components/modules/cp-local-select-round/selector';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';
import CpTable from '~/components/shared/cp-table';
import CpEllipsis from '~/components/common/ellipse';
import EditTransactionModal from './modals/edit-transaction';
import AddTransactionModal from './modals/add-transaction-modal';
import investmentStatus from './components/investmentStatus';
import EditDepositWalletModal from './modals/edit-deposit-wallet';

import CpEditableMixin from '~/mixins/editable-invester-details-component';
import cpLocalSelectTokenMixin from '~/components/modules/cp-local-select-token/selector.mixin';
import cpLocalSelectRoundMixin from '~/components/modules/cp-local-select-round/selector.mixin';
import CpCurrencyFormatter from '~/mixins/currency-formatter';
import SubscriptionAgreementStatusLogsModal from './modals/subscription-agreement-status-logs-modal';

export default {
  name: 'CpInvestorsDetailsInvestment',
  components: {
    CpEditButtons,
    CpEditableInput,
    investmentStatus,
    CpInput,
    CpDatePicker,
    CpButton,
    AddTransactionModal,
    CpConfirmModal,
    CpTable,
    CpEllipsis,
    EditTransactionModal,
    EditDepositWalletModal,
    cpLocalSelectToken,
    cpLocalSelectRound,
    CpSelect,
    SubscriptionAgreementStatusLogsModal,
  },
  mixins: [
    CpEditableMixin('investment'),
    CpCurrencyFormatter,
    cpLocalSelectTokenMixin,
    cpLocalSelectRoundMixin,
  ],
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const {
      depositAddressFields,
      transactionsFields,
      subscriptionAgreementFields,
      i18nKeys,
      investmentsFields,
    } = investmentFields;

    return {
      depositAddressFields,
      investmentsFields,
      transactionsFields,
      subscriptionAgreementFields,
      i18nKeys,
      currentRound: null,
      savedPledgedAmount: 0,
      deleteItem: {},
      investmentCache: {},
      editableTransaction: {},
      editableDepositWallet: {},
      currentTokenId: this.$route.params.tokenId,
      isEvergreenRound: false,
      showInvestmentTable: false,
      model: {
        bonusTokens: null,
        depositWallets: [],
        investmentRequestForDisplay: null,
        investmentRequests: [],
        pledged: null,
        status: '',
        subscriptionAgreementSignedAt: null,
        subscriptionAgreementStatus: '',
        totalFunded: null,
        transactions: [],
      },
      confirmModalEntity: null,
      isLoadingNewStatus: false,
      currency: null,
      amount: 0,
      date: null,
      initiator: '',
      queryParams: {
        issuerId: this.$route.params.idIssuer,
        tokenId: this.$route.params.tokenId,
        userId: this.$route.params.detailsId,
        roundId: undefined,
      },
    };
  },
  computed: {
    ...mapGetters('rounds', ['rounds', 'roundsOption']),
    ...mapGetters('investors', ['getDetailGeneral', 'supportedCurrencies']),
    ...mapGetters('global', ['currencyById', 'currencies', 'currencyByIdentifier']),
    ...mapState('global', ['issuerMainCurrencyIdentifier']),
    currencyList() {
      const currenciesList = this.currencies.filter(item => this.supportedCurrencies.includes(item.identifier));
      return (currenciesList || []).map(item => ({
        text: item.identifier,
        value: item.id,
      }));
    },
    filters() {
      return {
        roundId: this.queryParams.roundId,
      };
    },
    showMainContent() {
      return Boolean(this.rounds);
    },
    getAddressUrl() {
      return ({ address, type }) => {
        if (type === 'ETH' && address) {
          return `https://etherscan.io/address/${address}`;
        }
        if ((type === 'BTC' || type === 'BCH') && address) {
          return `https://bitcoinwhoswho.com/address/${address}`;
        }
        return null;
      };
    },
    hasInvestment() {
      return Boolean(this.model.investmentRequestForDisplay);
    },
    confirmModalTitle() {
      const entityLabel = this.confirmModalEntity && this.$t(`investorDetails.investment.label.${this.confirmModalEntity}`);
      return this.$t('investorDetails.removeModal.title', [entityLabel]);
    },
    confirmModalText() {
      const entityLabel = this.confirmModalEntity && this.$t(`investorDetails.investment.label.${this.confirmModalEntity}`);
      return this.$t('investorDetails.removeModal.message', [entityLabel]);
    },
    isDeletable() {
      return ({ source, direction }) => direction === 'liquidation' || source === 'manual';
    },
    shouldDisplayAddInvestmentToolTip() {
      return !this.model.investmentRequestForDisplay;
    },
    depositWalletsAvailable() {
      return this.model.depositWallets && this.model.depositWallets.length > 0;
    },
    isTotalFunded() {
      return this.getTotalFunded.amount || this.totalFundedIdentifier !== '-';
    },
    isInvestmentFunded() {
      return this.getTotalFunded.amount === 0;
    },
    activeStatusColor() {
      return `badge ${this.currentRound && this.currentRound && this.currentRound.status === 'active' ? 'badge-outline-success margin-end' : 'badge-outline-default'}`;
    },
    roundStatusLabel() {
      return this.currentRound && this.currentRound.status ? this.$t(`investorDetails.investment.status.round.${this.currentRound.status}`) : '-';
    },
    subscriptionAgreementName() {
      const currentStatus = this.subscriptionAgreementFields.find(
        ({ value }) => value === this.model.subscriptionAgreementStatus,
      );
      return currentStatus ? this.$t(currentStatus.i18nKey) : '-';
    },
    correctData() {
      let subscriptionAgreementSignedAt = this.model.subscriptionAgreementSignedAt || null;
      if (subscriptionAgreementSignedAt) {
        subscriptionAgreementSignedAt = new Date(new Date(subscriptionAgreementSignedAt).setHours(0, 0, 0)).toISOString();
      }

      const data = {
        ...this.model,
        pledged: {
          currencyId: +this.currency,
          amount: +this.amount,
          date: this.date,
        },
        subscriptionAgreementSignedAt,
        tokenId: this.queryParams.tokenId,
      };

      return Object.keys(data).reduce((res, key) => {
        if (data[key] || data[key] === null) {
          res[key] = data[key];
        }
        return res;
      }, {});
    },
    totalFundedIdentifier() {
      const { totalFunded } = this.model;
      return this.getCurrencyValues((totalFunded || {}).currencyId, 'identifier');
    },
    getTotalFunded() {
      const { amount, currencyId, identifier } = this.model.totalFunded || {};
      return { amount, currencyId, identifier };
    },
    investmentsFieldsLocalized() {
      return this.investmentsFields;
    },
    transactionsFieldsLocalized() {
      return this.transactionsFields.map((item) => {
        if (this.roundId && item.key === 'roundName') item = {};
        if ((this.roundId || this.currentTokenId) && item.key === 'tokenName') item = {};
        if (item.key === 'usdWorth') {
          const field = {
            ...item,
            label: this.$t(item.i18nKey, [this.issuerMainCurrencyIdentifier]),
          };
          delete field.i18nKey;
          return field;
        }
        return item;
      });
    },
    pledgedCurrencyByIdentifier() {
      const { pledged } = this.model;
      if (pledged) {
        return this.currencyById(pledged.currencyId).identifier;
      }
      return '';
    },
    getPriceRate() {
      return (identifier, value) => {
        const currency = this.currencyByIdentifier(identifier);
        if (!currency) {
          return 0;
        }
        const { rate } = currency;
        const currencyValue = value * (rate || 0);
        return this.formatCurrencyValue(currencyValue, this.issuerMainCurrencyIdentifier);
      };
    },
    isFboInvestor() {
      return ['fbo-individual', 'fbo-entity'].includes(this.getDetailGeneral.investorType);
    },
    shouldDisableAddInvestmentForEvergreen() {
      return this.loading || !this.currentRound || !this.currentRound.tokenId || this.model.status !== 'confirmed';
    },
    shouldDisplayAddInvestmentForEvergreen() {
      return this.isEvergreenRound && this.hasInvestment;
    },
  },
  created() {
    this.selectedToken = this.currentTokenId;
    this.queryParams.roundId = this.getActiveRoundIdByTokenId(this.currentTokenId, this.rounds);
    this.getInvestorInvestmentNoStateChange({
      params: {
        ...this.queryParams,
      },
    }).then(({ data }) => {
      this.updateLocalState(data);
    });
    this.changeMode(true);
  },
  methods: {
    ...mapActions('rounds', ['updateRound']),
    ...mapActions('investors', [
      'getInvestorInvestment',
      'updateInvestorInvestmentById',
      'deleteInvestorDetailTransaction',
      'updateDepositWalletById',
      'getInvestorDetailsTokens',
      'getInvestorInvestmentNoStateChange',
      'updateInvestorDetailTransaction',
      'sendFundingInstructionsEmail',
      'sendAgreementEmail',
    ]),
    ...mapMutations('investors', ['SET_INVESTOR_DETAILS']),
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    displayAmountAndCurrency(data) {
      return `${data.amount} ${this.currencyById(data.currencyId).identifier}`;
    },
    isEditable(data) {
      return !(data.source === 'automated' && data.direction === 'deposit');
    },
    async onRoundSelect(roundId) {
      this.currentRoundId = roundId;
      this.queryParams.roundId = roundId;

      if (roundId) {
        this.currentActiveRound = this.rounds.find(round => (round.id === roundId));
      } else {
        this.currentActiveRound = this.rounds.find(round => (round.status === 'active' && round.tokenId === this.currentTokenId));
      }
      this.model.investmentRequestForDisplay = null;
      const { data } = await this.getInvestorInvestmentNoStateChange({ params: { ...this.queryParams } });
      this.updateLocalState(data);
      this.changeMode(true);
    },
    async onTransactionIsEdit(transaction) {
      const { idIssuer: issuerId, detailsId: userId } = this.$route.params;
      await this.updateInvestorDetailTransaction({
        issuerId,
        userId,
        transaction: {
        ...transaction, roundId: this.currentActiveRound.id,
      },
      });
      this.updateInvestment();
    },
    updateLocalState(data) {
      this.currentRound = null;
      this.model = {
        ...data,
        ...(data.investmentRequestForDisplay || {}),
      };
      this.showInvestmentTable = !!this.model.investmentRequests.length;
      this.isEvergreenRound = this.model.investmentRound && this.model.investmentRound.isEvergreenRound;
      const { pledged } = this.model;
      this.savedPledgedAmount = pledged ? pledged.amount : null;
      this.currency = pledged ? pledged.currencyId : null;
      this.amount = pledged ? +pledged.amount : null;
      this.date = pledged ? pledged.date : null;
      this.currentRound = this.model.investmentRound;
    },
    onTokenSelect(selectedTokenId) {
      this.selectedToken = selectedTokenId;
      this.currentTokenId = selectedTokenId;
      this.queryParams.roundId = selectedTokenId ? this.getActiveRoundIdByTokenId(selectedTokenId, this.rounds) : null;
      this.queryParams.tokenId = selectedTokenId;
      this.model.investmentRequestForDisplay = null;
      this.getInvestorInvestmentNoStateChange({
        params: {
          ...this.queryParams,
        },
      }).then(({ data }) => {
        this.updateLocalState(data);
      });
    },
    updateInvestment() {
      this.getInvestorInvestmentNoStateChange({
        params: {
          ...this.queryParams,
        },
      }).then(({ data }) => {
        this.updateLocalState(data);
        this.changeMode(true);
        this.$refs.transactionsTable.updateTableData();
      });
    },
    updateDepositWalletsTable() {
      this.$refs.depositTable.updateTableData();
    },
    showEditDepositWalletModal(item) {
      this.editableDepositWallet = item;
      this.$refs.cpEditDepositWalletModal.show();
    },
    investmentsParser({ investmentRequests }) {
      return investmentRequests;
    },
    transactionsParser({ depositTransactions }) {
      return depositTransactions;
    },
    transactionRemove(id) {
      const { tokenId, depositWalletId } = this.deleteItem;
      this.deleteInvestorDetailTransaction({
        ...this.queryParams,
        depositTransactionId: id,
        tokenId,
        depositWalletId,
      })
        .then(() => {
          this.updateInvestment();
        });
    },
    showEditTransactionModal(item) {
      this.editableTransaction = item;
      this.$refs.editTransaction.show();
    },
    showAddTransactionModal() {
      this.changeMode(true);
      this.$refs.addTransaction.show();
    },
    showSubscriptionAgreementStatusLogsModal() {
      this.$refs.subscriptionAgreementStatusLogsModal.show();
    },
    depositAddressParser({ depositWallets }) {
      return depositWallets;
    },
    updateDepositWallets(item) {
      this.updateInvestorInvestmentById({
        ...this.queryParams,
        body: {
          depositWallets: [item],
          investmentRoundId: this.queryParams.roundId,
          tokenId: this.queryParams.tokenId,
        },
      });
    },

    removeEntity({ id }) {
      this[`${this.confirmModalEntity.replace(' ', '')}Remove`](id);
    },
    openDeleteModal(item, entity) {
      this.changeMode(true);
      this.confirmModalEntity = entity;
      this.deleteItem = item;
      this.$refs.cpConfirmModal.$refs.confirmModal.show();
    },
    changeModeProxy() {
      if (this.isDetailsEditableCurrentBlock) {
        // on cancel clear the image IDs to prevent accidental upload
        this.restoreState();
      } else {
        this.storeState();
      }

      this.changeMode();
    },
    storeState() {
      this.investmentCache = JSON.parse(JSON.stringify(this.model)); // Immutable is hard... need to get cloner
    },
    restoreState() {
      this.model = JSON.parse(JSON.stringify(this.investmentCache)); // Immutable is hard... need to get cloner
      const { pledged } = this.model;
      if (pledged) {
        this.amount = +pledged.amount;
      }
    },
    getCurrencyValues(id, valueName) {
      if (id) {
        const currency = this.currencies.find(item => item.id === Number(id) || item.identifier === id);
        return currency ? currency[valueName] : '-';
      }
      return '';
    },
    formatCurrencyValue(value, identifier = 'USD') {
      const excludedIdentifiers = ['ETH', 'BTC', 'DAI', 'BCH'];
      if (excludedIdentifiers.find(currencyToExclude => currencyToExclude === identifier)) {
        return `${this.formatCurrency(Math.trunc(value), identifier)}`;
      }
      return `${identifier} ${this.formatCurrency(Math.trunc(value), identifier)}`;
    },
    saveChanges() {
      if (!this.queryParams.roundId) {
        this.CALL_ERROR_TOASTER(this.$t('investorDetails.investment.message.selectRound'));
        return;
      }
      this.updateInvestorInvestmentById({
        ...this.queryParams,
        body: this.correctData,
      })
        .then((data) => {
            this.updateLocalState(data);
            this.storeState();
            this.changeMode(true);
          });
    },
    handleShowInvestmentModal() {
      this.$emit('showInvestmentModal', this.currentActiveRound);
    },
    handleShowNextInvestmentModal() {
      this.$emit('showNextInvestmentModal', this.currentActiveRound);
    },
    sendInstructionsEmail() {
      this.sendFundingInstructionsEmail({ ...this.queryParams });
    },
    sendAgreementEmails() {
      this.sendAgreementEmail({ ...this.queryParams });
    },
  },
};
</script>

<style lang="scss" scoped>
  .cp-label-override{
   min-width: 70px;
  }
  .pledgedBlock {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.1;
    margin: 0;
    display: flex;
  }
  .margin-end {
    margin-inline-end: 5px
  }
  .column-align-button-wrap {
    display: flex;
    justify-content: flex-end;
  }
  .column-align-button {
    display: flex;
    flex-wrap: nowrap;
    flex-grow: 0;
    justify-content: flex-end;
    flex-direction: column;
    button {
      max-height: 26px;
      min-width: 133px;
    }
  }
</style>
