var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card mb-5"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row align-items-center mt-4"},[_c('div',{staticClass:"col-8"},[_c('h5',{staticClass:"my-0"},[_vm._v(" "+_vm._s(_vm.$t('investorDetails.payouts.title'))+" ")])])])]),_c('div',[_c('cp-table',{ref:"cpTable",attrs:{"without-search":"","get-data-action":"investors/getHistoryPayouts","url-params":_vm.queryParams,"fields":_vm.payoutsFields},scopedSlots:_vm._u([{key:"distributionType",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(" "+_vm._s(rowData.item.distributionType)+" "),_c('br')])]}},{key:"confirmationDate",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(rowData.item.confirmationDate))+" "),_c('br')])]}},{key:"payoutMethod",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-badge',{staticClass:"align-text-bottom ml-1",attrs:{"variant":"outline-secondary"}},[_vm._v(" "+_vm._s(rowData.item.payoutMethod)+" ")])]}},{key:"units",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s(rowData.item.tokensAmount))])]}},{key:"withholding",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s(_vm.formatPercentage(rowData.item.withholdingRate)))])]}},{key:"netAmount",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s((rowData.item.payoutAmount && _vm.formatCurrency(rowData.item.payoutAmount)) || '-'))])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }