<template>
  <div>
    <div v-if="isReady">
      <div class="row align-items-center mb-4">
        <div class="col-md-8">
          <b-button
            class="btn btn-outline-primary btn-sm"
            @click="goToList"
          >
            <i class="ion ion-ios-arrow-back" />
            {{ $t('investorDetails.button.back') }}
          </b-button>
        </div>
        <div class="col-md-4 d-md-flex justify-content-end">
          <span class="text-muted">
            {{ $t('investorDetails.label.lastUpdate') }} {{ getDetailGeneral.updatedAt | dateFilter }}
          </span>
        </div>
      </div>

      <div class="row align-items-end mb-2 row-overwrite justify-content-between">
        <div class="py-3">
          <div class="investor-title">
            <h4 class="font-weight-bold">
              {{ getInvestorTitle }}
            </h4>
            <div
              v-if="hasSecuritizeId"
              class="ml-3"
              style="max-width: 150px;"
            >
              <cp-image
                class="securitize-id-logo"
                :src="getImagePath('securitize-id-logo.svg', 'logo')"
                :default-src="getImagePath('logo.png', 'logo')"
                :title="$t('investorDetails.label.securitizeId')"
                @click="goToSecuritizeId"
              />
            </div>
            <div
              v-if="hasSecuritizeIdMarkets"
              class="ml-3"
              style="max-width: 150px;"
            >
              <cp-image
                class="sec-markets-logo"
                :src="getImagePath('sec-markets-logo.svg', 'logo')"
                :default-src="getImagePath('logo.png', 'logo')"
                :title="$t('investorDetails.label.securitizeIdMarkets')"
                @click="goToSecuritizeIdMarkets"
              />
            </div>
          </div>
          <div class="d-flex align-items-center">
            <span class="text-muted">
              <a :href="`mailto:${getDetailGeneral.email}`">
                {{ getDetailGeneral.email }}
              </a> | {{ $t('investorDetails.label.externalId') }} {{ getDetailGeneral.externalId || '-' }}
            </span>
            <cp-button
              variant="primary"
              class="ml-3"
              size="sm"
              icon="ion ion-ios-mail"
              @click="sendInviteModal"
            >
              {{ $t('investorDetails.button.invite') }}
            </cp-button>
            <cp-button
              variant="primary"
              class="ml-3"
              icon="ion ion-ios-refresh"
              size="sm"
              @click="handleResetPass"
            >
              {{ $t('investorDetails.button.reset') }}
            </cp-button>
            <cp-button
              variant="primary"
              class="ml-3"
              icon="ion ion-ios-remove-circle"
              size="sm"
              :disabled="!is2FaEnabled"
              @click="openDisable2FaModal"
            >
              {{ $t('investorDetails.button.disable') }}
            </cp-button>
            <cp-button
              variant="primary"
              icon="ion ion-ios-trash"
              class="ml-3"
              size="sm"
              @click="$emit('onRemoveElem', { id: queryParams.userId, ...getDetailGeneral })"
            >
              {{ $t('investorDetails.button.delete') }}
            </cp-button>
            <cp-button
              v-if="hasSecuritizeId"
              variant="primary"
              class="ml-3"
              icon="ion ion-ios-sync"
              size="sm"
              :disabled="!hasSecuritizeId || isDisabledSyncIdButton"
              @click="openSyncSecuritizeIdModal"
            >
              {{ $t('investorDetails.button.syncSecuritizeId') }}
            </cp-button>
            <cp-button
              v-if="hasSecuritizeId && unlinkSecIdFlag"
              variant="primary"
              class="ml-3"
              icon="ion ion-ios-link"
              size="sm"
              :disabled="!hasSecuritizeId || isUnlinked"
              @click="openUnlinkSecuritizeIdModal"
            >
              {{ $t('investorDetails.button.securitizeIdUnLink') }}
            </cp-button>
          </div>
        </div>
        <div
          v-if="generalTokenAmount"
          class="card bg-light my-3"
        >
          <div class="card-body py-2 px-4">
            <div class="d-flex align-items-center justify-content-center">
              <h4 class="mb-0 mr-2 d-inline-block">
                {{ generalTokenAmount | exponentialToDecimal }}
              </h4>
              <img
                class="ui-w-30 rounded-circle d-inline-block"
                :src="getImagePath('securitize.png', 'logo')"
                alt="Securitize"
              >
            </div>
          </div>
        </div>
      </div>
      <div class="sticky-header">
        <scrollactive
          active-class="active"
          scroll-container-selector="#scroll-container"
          :modify-url="false"
          :always-track="true"
          :offset="300"
          :highlight-first-item="true"
          class="nav nav-tabs tabs-alt nav-responsive-xl"
          :scroll-offset="60"
        >
          <div class="nav-item d-flex">
            <a
              v-for="anchor in anchors"
              :key="anchor.value"
              :class="isActive(anchor.value)? 'nav-link active' : 'nav-link'"
              :href="`#${anchor.value}`"
              class="scrollactive-item"
            >
              {{ anchor.text }}
            </a>
          </div>
        </scrollactive>
      </div>
      <cp-investor-details-general
        id="general"
      />

      <cp-investor-details-legal-signers
        v-if="showLegalSignersSection"
        @viewLegalSigner="goToLegalSignerView"
      />

      <cp-investor-details-kyc
        id="detailsKyc"
      />

      <cp-investor-details-investment
        id="investorDetailsInvestment"
        ref="investorDetailsInvestment"
        :loading="creatingInvestment"
        @showInvestmentModal="showInvestmentModal"
        @showNextInvestmentModal="showNextInvestmentModal"
      />

      <cp-investor-detail-tokens-wallets
        id="tokensWallets"
      />

      <cp-investor-details-transaction-history
        v-if="showTransactionHistorySection"
        id="transactionHistory"
      />

      <cp-investor-details-payouts
        id="payouts-component"
      />

      <cp-investor-details-affiliate
        id="documents"
      />

      <cp-investor-details-documents
        v-if="showDocumentsSection"
        ref="documents"
      />
    </div>

    <div
      v-else
      class="row align-items-center mb-2"
    >
      <div class="col-md-12 text-center mt-4">
        {{ $t('investorDetails.loading') }}
      </div>
    </div>

    <b-modal
      id="addInvestment"
      ref="addInvestmentModal"
      :title="$t('investorDetails.addInvestmentModal.title')"
      @ok="addInvestment"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label">
                {{ $t('investorDetails.addInvestmentModal.label.rounds') }}
              </label>
              <cp-local-select-round
                disabled
                :controlled-round-id="addInvestmentModalRound"
                :round-names-list="currentRoundNames"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label">
                {{ $t('investorDetails.addInvestmentModal.label.currency') }}
              </label>
              <b-select
                v-model="currencyId"
                :options="getSupportedCurrenciesForSelectElement"
              />
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="addNextInvestment"
      ref="addNextInvestmentModal"
      :title="$t('investorDetails.addNextInvestmentModal.title', [addInvestmentModalRoundName])"
      :ok-disabled="okDisabled"
      @ok="addNextInvestment"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label">
                {{ $t('investorDetails.investment.label.pledgedAmount') }}
              </label>
              <cp-input
                v-model="addNextInvestmentModal.pledgeAmount"
                input-type="number"
                :placeholder="$t('operationalProcedures.input.tbeAmount.placeholder')"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label">
                {{ $t('investorDetails.addInvestmentModal.label.currency') }}
              </label>
              <b-select
                v-model="addNextInvestmentModal.currencyId"
                name="currency"
                placeholder
                :options="getSupportedCurrenciesForSelectElement"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <cp-date-picker
                v-model="addNextInvestmentModal.subscriptionAgreementSignedAt"
                :label="$t('investorDetails.investment.label.subscriptionAgreementSignedAt')"
                validate="required"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label">
                {{ $t('investorDetails.investment.label.subscriptionAgreement') }}
              </label>
              <b-select
                v-model="addNextInvestmentModal.subscriptionAgreementStatus"
                :options="subscriptionAgreementFields"
              />
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <cp-send-invite-email-modal ref="CpSendInviteEmail" />
    <cp-disable2fa-modal
      ref="CpDisable2fa"
      @confirm="handleDisable2FA"
    />
    <cp-confirm-modal
      ref="CpConfirmSyncSecuritizeId"
      :title="syncSecIdIActionsMap[selectedSyncIdInvestorAction].title"
      :ok-text="syncSecIdIActionsMap[selectedSyncIdInvestorAction].okText"
      :ok-variant="syncSecIdIActionsMap[selectedSyncIdInvestorAction].okVariant"
      @onOk="syncSecIdIActionsMap[selectedSyncIdInvestorAction].onOk"
      @onCancel="syncSecIdIActionsMap[selectedSyncIdInvestorAction].onCancel"
    >
      <p v-if="shouldDisplaySyncError">
        <strong>{{ syncSecIdIActionsMap[selectedSyncIdInvestorAction].syncSecIdError }}</strong>
      </p>
      {{ syncSecIdIActionsMap[selectedSyncIdInvestorAction].message }}
    </cp-confirm-modal>
    <cp-confirm-modal
      ref="CpConfirmUnlinkSecuritizeId"
      :title="unlinkSecIdActionMap.title"
      :ok-text="unlinkSecIdActionMap.okText"
      :ok-variant="unlinkSecIdActionMap.okVariant"
      @onOk="unlinkSecIdActionMap.onOk"
      @onCancel="unlinkSecIdActionMap.onCancel"
    >
      {{ unlinkSecIdActionMap.message }}
    </cp-confirm-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import routeNames from '~/utilities/routes';
import CpDisable2faModal from './disable-2fa-modal';
import CpSendInviteEmailModal from './send-invite-modal';
import CpInvestorDetailsGeneral from './general';
import CpInvestorDetailsKyc from './kyc';
import CpInvestorDetailsInvestment from './investment';
import CpInvestorDetailsDocuments from './documents';
import CpInvestorDetailTokensWallets from './tokens-wallets';
import CpInvestorDetailsPayouts from './payouts';
import CpInvestorDetailsAffiliate from './affiliate';
import CpInvestorDetailsLegalSigners from './legal-signers';
import CpButton from '~/components/common/standalone-components/cp-button';
import CpImage from '~/components/common/standalone-components/cp-image';
import cpLocalSelectRound from '~/components/modules/cp-local-select-round/selector';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';
import { investment as investmentFields, syncSecIdActionEnum } from './options';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpDatePicker from '~/components/common/standalone-components/inputs/cp-date-picker';
import CpStaticPathGenerationMixin from '~/mixins/static-path-generation';
import CpTimeConverter from '~/mixins/time-converter';
import CpInvestorDetailsTransactionHistory from '@/pages/_idIssuer/investors/details/components/transaction-history';

export default {
  name: 'CpInvestorDetails',
  components: {
    CpInvestorDetailsTransactionHistory,
    CpButton,
    CpInvestorDetailsGeneral,
    cpLocalSelectRound,
    CpInvestorDetailsKyc,
    CpInvestorDetailsInvestment,
    CpInvestorDetailsDocuments,
    CpInvestorDetailTokensWallets,
    CpSendInviteEmailModal,
    CpInvestorDetailsPayouts,
    CpInvestorDetailsAffiliate,
    CpDisable2faModal,
    CpInput,
    CpDatePicker,
    CpImage,
    CpConfirmModal,
    CpInvestorDetailsLegalSigners,
  },
  mixins: [
    CpStaticPathGenerationMixin,
    CpTimeConverter,
  ],
  props: {
    linkToList: {
      type: String,
      required: true,
    },
  },
  data() {
    let {
      subscriptionAgreementFields,
    } = investmentFields;
    subscriptionAgreementFields = subscriptionAgreementFields.map(item => ({ ...item, text: this.$t(item.i18nKey) }));
    return {
      unlinkSecIdFlag: process.env.VUE_APP_SEC_ID_UNLINK_ENABLED === 'true',
      subscriptionAgreementFields,
      creatingInvestment: false,
      isUnlinked: false,
      isReady: false,
      round: undefined,
      kycReportWorking: false,
      currencyId: 3,
      addInvestmentModalRound: null,
      currentRoundNames: [],
      localSelectorTokenId: '',
      disableSyncSecIdButton: false,
      selectedSyncIdInvestorAction: syncSecIdActionEnum.SYNC,
      addInvestmentModalRoundName: '',
      addNextInvestmentModal: {
        pledgeAmount: '',
        subscriptionAgreementStatus: null,
        currencyId: null,
        subscriptionAgreementSignedAt: null,
      },
      syncSecIdIActionsMap: {
        sync: {
          title: this.$t('investorDetails.confirmSyncSecuritizeIdModal.title'),
          okText: this.$t('investorDetails.confirmSyncSecuritizeIdModal.okText'),
          message: this.$t('investorDetails.confirmSyncSecuritizeIdModal.message'),
          okVariant: 'primary',
          onOk: this.submitSyncSecIdInvestor,
          onCancel: this.resetSyncIdInvestorButton,
        },
        forceSync: {
          title: this.$t('investorDetails.confirmForceSyncSecuritizeIdModal.title'),
          okText: this.$t('investorDetails.confirmForceSyncSecuritizeIdModal.okText'),
          message: this.$t('investorDetails.confirmForceSyncSecuritizeIdModal.message'),
          okVariant: 'danger',
          onOk: () => this.submitSyncSecIdInvestor(true),
          onCancel: this.resetSyncIdInvestorButton,
          syncSecIdError: '',
        },
      },
      unlinkSecIdActionMap: {
        title: this.$t('investorDetails.confirmUnlinkSecuritizeIdModal.title'),
        okText: this.$t('investorDetails.confirmUnlinkSecuritizeIdModal.okText'),
        message: this.$t('investorDetails.confirmUnlinkSecuritizeIdModal.message'),
        okVariant: 'primary',
        onOk: this.unlinkAccount,
        onCancel: '',
        unlinkSecIdError: '',
      },
      anchors: [
        { text: this.$t('investorDetails.anchors.generalInformation'), value: 'general' },
        { text: this.$t('investorDetails.anchors.kycAccreditation'), value: 'detailsKyc' },
        { text: this.$t('investorDetails.anchors.investment'), value: 'investorDetailsInvestment' },
        { text: this.$t('investorDetails.anchors.tokenWallets'), value: 'tokensWallets' },
        { text: this.$t('investorDetails.anchors.documents'), value: 'documents' },
      ],
    };
  },
  computed: {
    ...mapGetters('investors', ['getDetailGeneral', 'getDetailTokens', 'getDetailInvestment', 'getDetailKyc', 'supportedCurrencies']),
    ...mapGetters('global', ['currencies']),
    ...mapGetters('currentOperator', ['operatorData']),
    roundId: {
      get() {
        return this.round;
      },
      set(round) {
        this.round = round;
      },
    },
    okDisabled() {
      return Boolean(this.addNextInvestmentModal.currencyId === null || this.addNextInvestmentModal.pledgeAmount === '' || this.addNextInvestmentModal.pledgeAmount <= 0);
    },
    getSupportedCurrenciesForSelectElement() {
      let supportedCurrencies = [];
      let errorFiltering;
      this.supportedCurrencies.forEach((currency) => {
        const currencyData = this.currencies.find(currencyInfo => currencyInfo.identifier === currency);
        if (!currencyData) {
          errorFiltering = true;
          return;
        }
        supportedCurrencies.push({ value: currencyData.id, text: currency });
      });

      if (errorFiltering) {
        supportedCurrencies = this.currencies.map(({ id, identifier }) => ({
          value: id,
          text: identifier,
        }));
      }

      this.updateCurrencyId(supportedCurrencies);

      return supportedCurrencies;
    },
    getCurrenciesForSelectElement() {
      return this.currencies.map(({ id, identifier }) => ({
        value: id,
        text: identifier,
      }));
    },
    generalTokenAmount() {
      const { tokensHeld, tokensTreasury } = this.getDetailTokens;
      return tokensHeld + tokensTreasury;
    },
    is2FaEnabled() {
      return this.getDetailGeneral.is2FaEnabled;
    },
    getInvestorTitle() {
      const {
        investorType, entityName, firstName, middleName, lastName,
      } = this.getDetailGeneral;
      const fullName = `${firstName || ''} ${middleName || ''} ${lastName || ''}`;
      if (this.isEntityInvestorType(investorType)) {
        return `${entityName || ''}${fullName.trim() ? `(${fullName})` : ''}`;
      }
      return fullName;
    },
    showDocumentsSection() {
      const { authorizationLevel } = this.operatorData;
      const { brokerDealerGroupId: investorBdGroupId } = this.getDetailGeneral;

      return !investorBdGroupId || ['megaadmin', 'brokerdealer'].includes(authorizationLevel);
    },
    showLegalSignersSection() {
      return this.isEntityInvestorType(this.getDetailGeneral.investorType);
    },
    showTransactionHistorySection() {
      return this.operatorData.authorizations.includes('transfer-agent');
    },
    queryParams() {
      const { idIssuer: issuerId, tokenId, detailsId: userId } = this.$route.params;
      return { issuerId, tokenId, userId };
    },
    securitizeIdProfileId() {
      return this.getDetailGeneral.securitizeIdProfileId;
    },
    hasSecuritizeId() {
      const hasSecuritizeIdAuthorization = this.operatorData.authorizations.includes('securitize-id');
      const { securitizeIdProfileId } = this.getDetailGeneral;
      return Boolean(hasSecuritizeIdAuthorization && securitizeIdProfileId);
    },
    hasSecuritizeIdMarkets() {
      const hasSecuritizeIdMarketsAuthorization = this.operatorData.authorizations.includes('securitize-markets');
      const { isSecuritizeMarkets } = this.getDetailGeneral;
      return Boolean(hasSecuritizeIdMarketsAuthorization && isSecuritizeMarkets);
    },
    isDisabledSyncIdButton() {
      return this.disableSyncSecIdButton;
    },
  },
  created() {
    const investorId = Number(this.queryParams.userId);
    if (!Number.isInteger(investorId)) {
      this.$log.error('Cannot display Investor Details - InvestorID was not found');
      this.$toast.open({
        message: 'Cannot display Investor Details - InvestorID was not found',
        type: 'error',
        duration: 5000,
      });
      this.$router.push(
          routeNames.dashboard(
              this.queryParams.issuerId,
              this.queryParams.tokenId,
          ),
      );
    } else {
      Promise.all([
        this.getInvestorDetailsGeneral(this.queryParams),
        this.getInvestorDetailsTokens(this.queryParams),
        this.getInvestorKYCStatusById({
          ...this.queryParams,
          params: { tokenId: this.$route.params.tokenId },
        }),
      ])
          .catch((err) => {
            if (
                err.response.status >= 403
                && err.response.data
                && (err.response.data.error === 'FORBIDDEN'
                    || err.response.data.error === 'VALIDATION_ERROR')
            ) {
              this.$router.push(
                  routeNames.dashboard(
                      this.queryParams.issuerId,
                      this.queryParams.tokenId,
                  ),
              );
            }
          })
          .finally(() => {
            this.isReady = true;
          });
    }
  },
  methods: {
    ...mapActions('investors', [
      'getInvestorDetailsTokens',
      'getInvestorKYCStatusById',
      'createInvestorInvestment',
      'getInvestorDetailsGeneral',
      'sendResetPasswordEmail',
      'remove2Fa',
      'syncSecuritizeIdInvestor',
      'unlinkInvestor',
    ]),
    updateCurrencyId(supportedCurrencies) {
      if (supportedCurrencies.length && !supportedCurrencies.find(currency => currency.value === this.currencyId)) {
        this.currencyId = supportedCurrencies[supportedCurrencies.length - 1].value;
      }
    },
    isActive(value) {
      return this.anchors[0].value === value;
    },
    handleRoundChange(round) {
      this.addInvestmentModalRound = round;
    },
    showInvestmentModal({ name, id, tokenId }) {
      this.currentRoundNames.push({ text: name, value: id });
      this.localSelectorTokenId = tokenId;
      this.addInvestmentModalRound = id;
      this.$refs.addInvestmentModal.show();
    },
    showNextInvestmentModal({ name, id, tokenId }) {
      this.currentRoundNames.push({ text: name, value: id });
      this.localSelectorTokenId = tokenId;
      this.addInvestmentModalRound = id;
      this.addInvestmentModalRoundName = name;
      this.$refs.addNextInvestmentModal.show();
    },
    handleResetPass() {
      this.sendResetPasswordEmail(this.queryParams);
    },
    sendInviteModal() {
      this.$refs.CpSendInviteEmail.show();
    },
    goToList() {
      const { params: { idIssuer, tokenId }, query: { from, search } } = this.$route;
      const investorList = from || 'onboarding';
      this.$router.push(routeNames[investorList](idIssuer, tokenId, search));
    },
    async addInvestment() {
      const { currencyId } = this;
      this.creatingInvestment = true;
      await this.createInvestorInvestment({
        ...this.queryParams,
        tokenId: this.localSelectorTokenId,
        body: {
          currencyId,
          roundId: this.addInvestmentModalRound,
          tokenId: this.localSelectorTokenId,
        },
      }).finally(() => {
        this.creatingInvestment = false;
        const { onRoundSelect, updateDepositWalletsTable } = this.$refs.investorDetailsInvestment;
        onRoundSelect(this.addInvestmentModalRound);
        updateDepositWalletsTable();
      });
    },
    async addNextInvestment() {
      this.creatingInvestment = true;
      const { currencyId, pledgeAmount, subscriptionAgreementSignedAt, subscriptionAgreementStatus } = this.addNextInvestmentModal;

      let body = {
        currencyId,
        roundId: this.addInvestmentModalRound,
        tokenId: this.localSelectorTokenId,
        pledgeAmount,
      };

      if (this.addNextInvestmentModal.subscriptionAgreementStatus) body = { ...body, subscriptionAgreementStatus };
      if (this.addNextInvestmentModal.subscriptionAgreementSignedAt) body = { ...body, subscriptionAgreementSignedAt: new Date(new Date(subscriptionAgreementSignedAt).setHours(0, 0, 0)).toISOString() };

      await this.createInvestorInvestment({
        ...this.queryParams,
        tokenId: this.localSelectorTokenId,
        body,
      }).finally(() => {
        this.creatingInvestment = false;
        const { onRoundSelect, updateDepositWalletsTable } = this.$refs.investorDetailsInvestment;
        onRoundSelect(this.addInvestmentModalRound);
        updateDepositWalletsTable();
      });
    },
    openDisable2FaModal() {
      this.$refs.CpDisable2fa.show();
    },
    handleDisable2FA() {
      this.remove2Fa(this.queryParams);
    },
    goToSecuritizeId() {
      window.open(routeNames.securitizeIdInvestor(this.getDetailGeneral.securitizeIdProfileId), '_blank');
    },
    goToSecuritizeIdMarkets() {
      window.open(routeNames.securitizeIdMarketsInvestor(this.getDetailGeneral.securitizeIdProfileId), '_blank');
    },
    openSyncSecuritizeIdModal() {
      this.disableSyncSecIdButton = true;
      this.$refs.CpConfirmSyncSecuritizeId.show();
    },
    openUnlinkSecuritizeIdModal() {
      this.$refs.CpConfirmUnlinkSecuritizeId.show();
    },
    async submitSyncSecIdInvestor(forceUpdate = false) {
      await this.syncSecuritizeIdInvestor({ issuerId: this.queryParams.issuerId, securitizeIdProfileId: this.getDetailGeneral.securitizeIdProfileId, forceUpdate })
        .then(async () => {
          this.resetSyncIdInvestorButton();
          await this.getInvestorDetailsGeneral(this.queryParams);
        })
        .catch((err) => {
          if (!forceUpdate && err.response.status === 400) {
            this.setForceSyncSecIdAction(err);
          } else {
            this.resetSyncIdInvestorButton();
          }
        });
    },
    async unlinkAccount() {
      await this.unlinkInvestor({ issuerId: this.queryParams.issuerId, userId: this.queryParams.userId });
      this.isUnlinked = true;
      this.$router.go(0);
    },
    shouldDisplaySyncError() {
      return this.selectedSyncIdInvestorAction === syncSecIdActionEnum.FORCE_SYNC;
    },
    resetSyncIdInvestorButton() {
      this.selectedSyncIdInvestorAction = syncSecIdActionEnum.SYNC;
      this.disableSyncSecIdButton = false;
    },
    setForceSyncSecIdAction(err) {
      this.selectedSyncIdInvestorAction = syncSecIdActionEnum.FORCE_SYNC;
      this.syncSecIdIActionsMap[this.selectedSyncIdInvestorAction].syncSecIdError = (err.response && err.response.data && err.response.data.message) || '';
      this.$refs.CpConfirmSyncSecuritizeId.show();
    },
    onSelectAnchors(element) {
      const scrollTo = this.$refs[element].$el;
      const options = {
        container: '#scroll-container',
        duration: 1000,
        offset: -60,
      };
      this.$scrollTo(scrollTo, options);
    },
    isEntityInvestorType(investorType) {
      return ['entity', 'fbo-entity'].includes(investorType);
    },
    goToLegalSignerView(item) {
      const legalSignerId = item.signerId || item.id;
      const { idIssuer: issuerId, tokenId, detailsId: userId } = this.$route.params;
      if (legalSignerId && issuerId && tokenId && userId && legalSignerId) {
        this.$router.push(routeNames.viewLegalSigner(issuerId, tokenId, userId, legalSignerId));
      }
    },
  },
};
</script>

<style src="~/vendor/styles/pages/users.scss" lang="scss"></style>
<style scoped lang="scss">
  .scrollactive-item {
    cursor: pointer;
  }
  .sticky-header{
    position: sticky; top: -32px;
    z-index: 2;
    background-color: #f7fafc;
    margin-left: -32px;
    margin-right: -32px;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 20px;
  }
  .ion-ios-arrow-back {
    padding-right: 5px;
  }
  .header-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2% 0;
    h4 {
      margin: 0;
    }
  }
  .row.row-overwrite {
    margin-right: 0;
    margin-left: 0;
  }
  .primary {
    font-family: "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", sans-serif;
  }
  .investor-title {
    display: flex;
    align-items: flex-start;
  }
  .securitize-id-logo:hover {
    cursor: pointer;
  }
  .sec-markets-logo:hover {
    cursor: pointer;
  }
</style>
