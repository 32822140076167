<template>
  <div class="row mt-4">
    <div class="col-12">
      <div class="card bg-light">
        <div class="card-header d-flex justify-content-between">
          <h6 class="mb-0">
            {{ title }}
          </h6>
          <h6
            v-if="isCommented"
            class="mb-0"
          >
            {{ lastCommentBy + ' at ' + lastCommentTime }}
          </h6>
        </div>
        <div class="card-body">
          <div
            v-if="isEditable"
            :style="{'height':customHeight}"
            class="cp-comment-height"
          >
            <cp-textarea
              :value="value"
              @input="$emit('input', $event)"
            />
          </div>
          <div
            v-else
            class="cp-comment-height overflow-auto"
          >
            <span
              v-for="(comment, index) in commentsArray"
              :key="index"
            >
              {{ comment }}
              <br>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CpTextarea from '~/components/common/standalone-components/inputs/cp-textarea';

export default {
  name: 'CpComments',
  components: {
    CpTextarea,
  },
  props: {
    commentsArray: {
      type: Array,
      required: true,
    },
    lastCommentBy: {
      type: String,
      default: '',
    },
    lastCommentTime: {
      type: String,
      default: '',
    },
    isCommented: {
      type: Boolean,
      default: false,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Comment:',
    },
    customHeight: {
      type: String,
      default: 'auto',
    },
    value: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
  .cp-comment-height {
    .form-group {
      margin: 0;
      height: 100%;
      textarea {
        height: 100%;
      }
    }
  }
</style>
