<template>
  <div class="card mb-5">
    <div class="card-header">
      <div class="row align-items-center mt-4">
        <div class="col-8">
          <h5 class="my-0">
            {{ $t('investorDetails.payouts.title') }}
          </h5>
        </div>
      </div>
    </div>
    <div>
      <cp-table
        ref="cpTable"
        without-search
        get-data-action="investors/getHistoryPayouts"
        :url-params="queryParams"
        :fields="payoutsFields"
      >
        <template
          slot="distributionType"
          slot-scope="{ rowData }"
        >
          <span> {{ rowData.item.distributionType }} <br> </span>
        </template>
        <template
          slot="confirmationDate"
          slot-scope="{ rowData }"
        >
          <span> {{ formatDate(rowData.item.confirmationDate) }} <br> </span>
        </template>
        <template
          slot="payoutMethod"
          slot-scope="{ rowData }"
        >
          <b-badge
            class="align-text-bottom ml-1"
            variant="outline-secondary"
          >
            {{ rowData.item.payoutMethod }}
          </b-badge>
        </template>
        <template
          slot="units"
          slot-scope="{ rowData }"
        >
          <span>{{ rowData.item.tokensAmount }}</span>
        </template>
        <template
          slot="withholding"
          slot-scope="{ rowData }"
        >
          <span>{{ formatPercentage(rowData.item.withholdingRate) }}</span>
        </template>
        <template
          slot="netAmount"
          slot-scope="{ rowData }"
        >
          <span>{{
            (rowData.item.payoutAmount &&
              formatCurrency(rowData.item.payoutAmount)) ||
              '-'
          }}</span>
        </template>
      </cp-table>
    </div>
  </div>
</template>

<script>
import { payouts } from '../options';

import CpTable from '~/components/shared/cp-table';
import { formatDatePayout } from '@/utilities/date-utils';

export default {
  name: 'CpInvestorDetailsPayouts',
  components: {
    CpTable,
  },
  data() {
    return {
      payoutsFields: payouts.fields,
      queryParams: {
        investorId: this.$route.params.detailsId,
        tokenId: this.$route.params.tokenId,
        issuerId: this.$route.params.idIssuer,
      },
    };
  },
  watch: {
    $route({ params: { tokenId: newTokenId } }, { params: { tokenId: oldTokenId } }) {
      if (newTokenId !== oldTokenId) this.queryParams.tokenId = newTokenId;
    },
  },
  methods: {
    formatCurrency(numberStr) {
      return Number(numberStr).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    },
    formatPercentage(numberStr) {
      return `${Number(numberStr).toFixed(2)} %`;
    },
    formatDate(date) {
      return formatDatePayout(date);
    },
  },
};
</script>
