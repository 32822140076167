<template>
  <div class="card-header">
    <div class="row align-items-center p-0">
      <div class="col-md-2">
        <h4 class="mb-0 text-primary">
          {{ cardName }}
        </h4>
      </div>
      <div
        v-if="isSecIdKycProvider"
        class="col-md-1 sec-id-logo"
      >
        <cp-image
          :src="getImagePath('securitize-id-logo.svg', 'logo')"
          :default-src="getImagePath('logo.png', 'logo')"
          :title="$t('investorDetails.legalSigners.logoLabel')"
        />
      </div>
      <div
        v-if="createElem"
        :class="[createElemClass,'d-flex', 'justify-content-end']"
      >
        <b-button
          variant="info"
          class="btn btn-primary btn-sm"
          @click="$emit('onCreateElem')"
        >
          <span
            v-if="buttonIcon"
            :class="['ion',`ion-${buttonIcon}`]"
            class="right-spacing"
          />
          <span class="d-none d-sm-inline-block">{{ buttonTitle }}</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

import CpImage from '~/components/common/standalone-components/cp-image';
import CpStaticPathGenerationMixin from '~/mixins/static-path-generation';

export default {
  name: 'CardHead',
  components: {
    CpImage,
  },
  mixins: [
    CpStaticPathGenerationMixin,
  ],
  props: {
    cardName: {
      type: String,
      default: '',
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    disableEdit: {
      type: Boolean,
      default: false,
    },
    withoutEdit: {
      type: Boolean,
      default: false,
    },
    createElem: {
      type: Boolean,
      default: false,
    },
    buttonTitle: {
      type: String,
      default: '',
    },
    buttonIcon: {
      type: String,
      default: '',
    },
    isSecIdKycProvider: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    createElemClass() {
      return this.hasSecuritizeId ? 'col-md-7' : 'col-md-10 ';
    },
  },
};
</script>
<style scoped>
  .sec-id-logo {
    min-width: 150px;
  }
  .right-spacing {
    padding-right: 2px;
  }
</style>
