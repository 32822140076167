<template>
  <div
    class="long-string-wrapper"
  >
    <p
      v-b-tooltip="{
        trigger: 'hover blur',
      }"
      :title="tooltipContent"
      class="layover"
      :style="{color: displayWarning?'#bd121c':'inherit', textOverflow: 'ellipsis'}"
    >
      {{ dataValue }}
      <slot />
    </p>
  </div>
</template>
<script>
export default {
  name: 'CpEllipsis',
  props: {
    dataValue: {
      type: undefined,
      required: true,
    },
    limitDecimal: {
      type: Number,
      default: 20,
    },
    tooltipValue: {
      type: [Number, String],
      default: '',
    },
    displayWarning: {
      type: Boolean,
      default: false,
    },
    warningMessage: {
      type: String,
      default: '',
    },
  },
  computed: {
    tooltipContent() {
      if (this.displayWarning) return this.warningMessage;
      return this.tooltipValue || this.$options.filters.exponentialToDecimal(this.dataValue);
    },
  },
};
</script>

<style lang="scss" scoped>
  .info{
    background-color: #1cbb84;
  }
  .long-string-wrapper {
    position: relative;
    p {
      width:80px;
      margin: 0;
      text-overflow: ellipsis;
      cursor: pointer;
      word-break: break-all;
      overflow:hidden;
      white-space: nowrap;
    }
  }
</style>
