export default {
  computed: {
    formatCurrency() {
      return (value, customCurrency = 'USD', limitPercentagesForTwoDigits = false) => {
        try {
          value = +value || 0;
          const decimals = parseInt(value) === value ? 0 : customCurrency === 'USD' || limitPercentagesForTwoDigits ? 2 : 5;
          const options = {
            currency: customCurrency,
            style: 'currency',
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
          };
          const locale = customCurrency === 'THB' ? 'th-TH' : 'en-US';
          return value.toLocaleString(locale, options);
        } catch (e) {
          console.warn(e);
        }
      };
    },
    formatToken() {
      return (value, locales = 'en-US', decimals = 2) => {
        value = +value || 0;

        const options = {
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        };
        locales = 'en-US';
        return value.toLocaleString(locales, options);
      };
    },
  },
};
