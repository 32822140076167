<template>
  <div :class="rootClass">
    <div v-if="editMode">
      <b-form-select
        v-if="options"
        :value="value"
        :options="getOptions()"
        @input="input"
        @change="change"
      />
      <b-input
        v-else
        :value="value"
        :placeholder="placeholder"
        @input="input"
      />
    </div>
    <div v-if="!editMode">
      <strong
        v-if="strong"
        :class="textClass"
      >
        {{ text || '-' }}
      </strong>
      <a
        v-else-if="isEmail || isUrl"
        :href="getUrlParams.href"
        :target="getUrlParams.target"
      >
        {{ text || '-' }}
      </a>
      <span
        v-else
        :class="textClass"
      >
        {{ text }}
      </span>
    </div>
  </div>
</template>

<script>
import { i18nKeyListConvert } from '~/utilities/i18n-util';

export default {
  name: 'CpEditableInput',
  props: {
    editMode: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Array,
      default: () => null,
    },
    text: {
      type: [String, Number],
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    strong: {
      type: Boolean,
      default: false,
    },
    textClass: {
      type: [String, Array],
      default: '',
    },
    rootClass: {
      type: String,
      default: '',
    },
    isEmail: {
      type: Boolean,
      default: false,
    },
    isUrl: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      default: '',
    },
  },
  computed: {
    getUrlParams() {
      return this.isEmail
        ? { href: `mailto:${this.src || this.text}`, target: '_self' }
        : { href: this.src || this.text, target: '_blank' };
    },
  },
  methods: {
    input(value) {
      this.$emit('input', value);
    },
    change(value) {
      this.$emit('change', value);
    },
    getOptions() {
      if (!this.options || this.options.length === 0) {
        return this.options;
      }
      return i18nKeyListConvert(this.options, 'text');
    },
  },
};
</script>
