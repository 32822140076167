<template>
  <b-modal
    ref="uploadDocument"
    :title="$t('investorDetails.addDocumentModal.title')"
    :ok-title="$t('common.button.ok')"
    :cancel-title="$t('common.button.cancel')"
    @show="clearModal"
    @ok="sendDocument"
  >
    <cp-file-uploader
      id="investor-document-uploader"
      :data-check="checkDockSize"
      :file-rules="checkDockSize"
      :use-secure="true"
      @onChange="onLogoUploaded"
    />
    <hr>
    <cp-select
      v-model="documentData.type"
      :label="$t('investorDetails.addDocumentModal.input.category')"
      :options="categories.type"
    />
    <cp-select
      v-model="documentData.face"
      :label="$t('investorDetails.addDocumentModal.input.documentFace')"
      :options="categories.face"
    />
    <cp-input
      v-model="documentData.title"
      class="mb-3"
      name="title"
      :label="$t('investorDetails.addDocumentModal.input.title')"
    />
    <cp-select
      v-model="documentData.tokenId"
      class="mb-3"
      name="tokenName"
      :options="tokensList"
      :label="$t('investorDetails.addDocumentModal.input.tokenName')"
    />
    <b-check v-model="documentData.showToInvestor">
      {{ this.$t('investorDetails.addDocumentModal.input.showToInvestor') }}
    </b-check>
  </b-modal>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';

import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import CpFileUploader from '~/components/common/standalone-components/cp-file-uploader';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';

export default {
  name: 'AddDocumentModal',
  components: {
    CpInput,
    CpSelect,
    CpFileUploader,
  },
  props: {
    categories: {
      type: Object,
      required: true,
    },
    tokensList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      newFile: {},
      fileKey: '',
      documentData: {
        face: 'front',
        type: 'passport',
        showToInvestor: false,
        title: '',
        tokenId: '',
      },
      createProcess: false,
    };
  },
  methods: {
    ...mapActions('investors', ['addInvestorDetailDocument']),
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    checkDockSize({ size }) {
      return size > 10 * 10000000; // If more than 10 MB, reject
    },
    onLogoUploaded({ file, fileKey }) {
      this.newFile = file;
      this.fileKey = fileKey;
    },
    clearModal() {
      this.newFile = {};
      this.documentData = {
        face: 'front',
        type: 'passport',
        showToInvestor: false,
        title: '',
      };
    },
    sendDocument(env) {
      env.preventDefault();
      if (!this.newFile) {
        this.CALL_ERROR_TOASTER(this.$t('investorDetails.addDocumentModal.message.noFileFailure'));
        return;
      } if (!this.newFile.accepted) {
        this.CALL_ERROR_TOASTER(this.$t('investorDetails.addDocumentModal.message.fileSizeFailure'));
        return;
      }

      if (this.createProcess) {
        return;
      }
      this.createProcess = true;

      this.addInvestorDetailDocument({
        issuerId: this.$route.params.idIssuer,
        userId: this.$route.params.detailsId,
        fileKey: this.fileKey,
        type: this.documentData.type,
        documentFace: this.documentData.face,
        showToInvestor: this.documentData.showToInvestor,
        documentTitle: this.documentData.title,
        tokenId: this.documentData.tokenId,
      }).then(() => {
        this.$refs.uploadDocument.hide();
        this.$emit('docIsAdded');
      }).finally(() => {
        this.createProcess = false;
      });
    },
  },
};
</script>
