<template>
  <cp-general-modal
    ref="editTransaction"
    :title="$t('investorDetails.editTransactionModal.title')"
    :default-value="transactionData"
    :ok-disabled="disabled"
    @onOk="handleSubmit"
    @onInput="updateData"
  >
    <cp-input
      v-if="transactionData.source!=='automated'"
      name="externalTransactionConfirmation"
      :label="$t('investorDetails.editTransactionModal.input.txid')"
      placeholder="Write your text here..."
    />

    <cp-select
      name="direction"
      :label="$t('investorDetails.editTransactionModal.input.direction')"
      :options="typeOptions[transactionData.direction]"
    />

    <cp-input
      v-show="isLiquidation && notUsdCurrency"
      name="usdWorth"
      :label="$t('investorDetails.editTransactionModal.input.worth.text', ['USD'])"
      :placeholder="$t('investorDetails.editTransactionModal.input.worth.placeholder')"
    />
  </cp-general-modal>
</template>

<script>
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpGeneralModal from '~/components/common/modals-components/general-modal';

export default {
  name: 'EditTransaction',
  components: {
    CpInput,
    CpSelect,
    CpGeneralModal,
  },
  props: {
    transactionData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      disabled: false,
      isLiquidation: false,
      typeOptions: {
        withdraw: [
          { value: 'withdraw', i18nKey: 'investorDetails.option.transactionType.withdraw', disabled: true },
          { value: 'liquidation', i18nKey: 'investorDetails.option.transactionType.liquidation' },
          { value: 'refund', i18nKey: 'investorDetails.option.transactionType.refund' },
        ],
        liquidation: [
          { value: 'liquidation', i18nKey: 'investorDetails.option.transactionType.liquidation', disabled: true },
          { value: 'withdraw', i18nKey: 'investorDetails.option.transactionType.withdraw' },
          { value: 'refund', i18nKey: 'investorDetails.option.transactionType.refund' },
        ],
        refund: [
          { value: 'refund', i18nKey: 'investorDetails.option.transactionType.refund', disabled: true },
          { value: 'withdraw', i18nKey: 'investorDetails.option.transactionType.withdraw' },
          { value: 'liquidation', i18nKey: 'investorDetails.option.transactionType.liquidation' },
        ],
        deposit: [
          { value: 'deposit', i18nKey: 'investorDetails.option.transactionType.deposit', disabled: true },
        ],
      },
    };
  },
  computed: {
    notUsdCurrency() {
      return this.transactionData.currencyId !== 1;
    },
  },
  methods: {
    handleSubmit(transaction) {
      this.disabled = true;
      transaction.usdWorth = this.isLiquidation ? +transaction.usdWorth : +this.transactionData.usdWorth;
      delete transaction.amount;
      delete transaction.transactionTime;
      if (this.transactionData.direction === transaction.direction) {
        delete transaction.direction;
      }

      this.$emit('onTransactionIsEdit', transaction);
      this.disabled = false;
      this.$refs.editTransaction.hide();
    },
    show() {
      this.$refs.editTransaction.show();
    },
    updateData({ direction }) {
      this.isLiquidation = direction === 'liquidation';
    },
  },
};
</script>
