import { render, staticRenderFns } from "./index.vue?vue&type=template&id=fe271e8c&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "~/vendor/styles/pages/users.scss?vue&type=style&index=0&prod&lang=scss&"
import style1 from "./index.vue?vue&type=style&index=1&id=fe271e8c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe271e8c",
  null
  
)

export default component.exports