<template>
  <cp-general-modal
    ref="CpSubscriptionAgreementStatusLogs"
    :title="$t('investorDetails.subscriptionAgreementStatusLogsModal.title')"
  >
    <div
      class="modal-body"
    >
      <cp-table
        ref="subscriptionAgreementStatusLogsTable"
        without-search
        without-pagination
        style="max-height: 50vh; overflow-y: auto"
        get-data-action="investors/getSubscriptionAgreementStatusLogs"
        :url-params="{roundId,investmentRequestId,tokenId,issuerId,investorId }"
        :fields="fields"
      >
        <template
          slot="subscriptionAgreementSignedAt"
          slot-scope="{ rowData }"
        >
          <span>{{ rowData.item.subscriptionAgreementSignedAt | dateFilter }}</span>
        </template>
        <template
          slot="signerType"
          slot-scope="{ rowData }"
        >
          <span>{{ rowData.item.signerType }}</span>
        </template>
        <template
          slot="signerDetails"
          slot-scope="{ rowData }"
        >
          <span>{{ (rowData.item.signerDetails && rowData.item.signerDetails.name) || (getDetailGeneral && getDetailGeneral.name)
          }}</span>
        </template>
        <template
          slot="subscriptionAgreementStatus"
          slot-scope="{ rowData }"
        >
          <span>{{ rowData.item.subscriptionAgreementStatus }}</span>
        </template>
      </cp-table>
    </div>
  </cp-general-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpTable from '~/components/shared/cp-table';

export default {
  name: 'SubscriptionAgreementStatusLogsModal',
  components: {
    CpGeneralModal,
    CpTable,
  },
  props: {
    roundId: {
      type: [String, Number],
      default: null,
    },
    investmentRequestId: {
      type: Number,
      default: null,
    },
    tokenId: {
      type: String,
      default: '',
    },
    issuerId: {
      type: String,
      default: '',
    },
    investorId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'subscriptionAgreementSignedAt', i18nKey: 'investorDetails.subscriptionAgreementStatusLogsModal.tableColumns.date', tdClass: 'align-middle', sortable: false,
        },
        {
          key: 'signerType', i18nKey: 'investorDetails.subscriptionAgreementStatusLogsModal.tableColumns.signedBy', tdClass: 'align-middle', sortable: false,
        },
        {
          key: 'signerDetails', i18nKey: 'investorDetails.subscriptionAgreementStatusLogsModal.tableColumns.signerDetails', tdClass: 'align-middle', sortable: false,
        },
        {
          key: 'subscriptionAgreementStatus', i18nKey: 'investorDetails.subscriptionAgreementStatusLogsModal.tableColumns.status', tdClass: 'align-middle', sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('investors', ['getDetailGeneral']),
  },
  watch: {},
  methods: {
    show() {
      this.$refs.CpSubscriptionAgreementStatusLogs.show();
    },

  },
};
</script>
