import { render, staticRenderFns } from "./cp-json-textarea.vue?vue&type=template&id=1b0bacca&scoped=true&"
import script from "./cp-json-textarea.vue?vue&type=script&lang=js&"
export * from "./cp-json-textarea.vue?vue&type=script&lang=js&"
import style0 from "./cp-json-textarea.vue?vue&type=style&index=0&id=1b0bacca&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b0bacca",
  null
  
)

export default component.exports