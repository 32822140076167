<template>
  <div class="card mb-5">
    <div class="card-header">
      <div class="row align-items-center mt-4">
        <div class="col-8">
          <h5 class="my-0">
            {{ this.$t('investorDetails.affiliate.title') }}
          </h5>
        </div>
        <div>
          <b-tooltip
            v-if="isAffiliateStatus"
            target="set-affiliate"
            triggers="hover"
          >
            {{ getTooltipByStatus }}
          </b-tooltip>
        </div>
        <div
          id="set-affiliate"
          class="col-4 d-flex justify-content-end"
        >
          <cp-button
            variant="outline-primary"
            icon="ios-person-add"
            :disabled="isAffiliateStatus"
            @click="showAffiliateModal"
          >
            {{ this.$t('investorDetails.affiliate.button.affiliate.set') }}
          </cp-button>
        </div>
      </div>
    </div>

    <cp-table
      ref="cpTable"
      without-search
      get-data-action="investors/getAffiliateRecords"
      :url-params="queryParams"
      :fields="affiliateFields"
      @tableDataUpdated="tableIsLoaded"
    >
      <template
        slot="status"
        slot-scope="{ rowData }"
      >
        <b-badge :variant="affiliateStatusVisible(rowData).color">
          {{ affiliateStatusVisible(rowData).status }}
        </b-badge>
      </template>

      <template
        slot="createdAt"
        slot-scope="{ rowData }"
      >
        {{ rowData.item.createdAt | dateFilter }}
      </template>
    </cp-table>

    <div class="card-footer">
      <small
        style="cursor: pointer"
        @click="mailToSupport"
      >
        <i class="ion ion-ios-information-circle-outline" />
        {{ this.$t('investorDetails.affiliate.message.issuerSupportLink') }}
      </small>
    </div>

    <cp-set-affiliate-modal
      ref="cpAffiliateModal"
      @affiliateIsSet="refreshAffiliateTable"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { affiliateFields, statusStyle } from '../options';

import CpButton from '~/components/common/standalone-components/cp-button';
import CpTable from '~/components/shared/cp-table';
import CpSetAffiliateModal from './modals/setAffiliateModal';

export default {
  name: 'Affiliate',
  components: {
    CpButton,
    CpTable,
    CpSetAffiliateModal,
  },
  data() {
    return {
      affiliateFields,
      statusStyle,
      isAffiliateStatus: false,
      currentStatus: 'no',
    };
  },
  computed: {
    ...mapGetters('investors', ['getDetailGeneral']),
    queryParams() {
      return {
        issuerId: this.$route.params.idIssuer,
        userId: this.$route.params.detailsId,
        tokenId: this.$route.params.tokenId,
      };
    },
    affiliateStatusVisible() {
      return ({ item }) => ({
        status: item.status,
        color: this.affiliateColor(item.status),
      });
    },
    getTooltipByStatus() {
      if (this.currentStatus === 'yes') {
        return this.$t('investorDetails.affiliate.message.setAffiliateYesToolTip');
      }
      if (this.currentStatus === 'pending') {
        return this.$t('investorDetails.affiliate.message.setAffiliatePendingToolTip');
      }
      return '';
    },
  },
  methods: {
    mailToSupport() {
      const {
        entityName,
        investorType,
        firstName,
        middleName,
        lastName,
      } = this.getDetailGeneral;
      const fullName = `${firstName} ${middleName || ''} ${lastName}`;
      const support = 'issuersupport@securitize.io';
      const subject = this.$t('investorDetails.affiliate.message.issuerSupportMail', [investorType === 'entity' ? entityName : fullName]);
      const body = `user id: ${this.queryParams.userId}, name: ${fullName}`;
      window.open(`mailto:${support}?subject=${subject}&body=${body}`);
    },
    tableIsLoaded() {
      const [firstAffiliateRecord] = this.$refs.cpTable.getTableData();
      this.isAffiliateStatus = firstAffiliateRecord && (firstAffiliateRecord.status === 'pending' || firstAffiliateRecord.status === 'yes');
      this.currentStatus = firstAffiliateRecord.status;
    },
    refreshAffiliateTable() {
      this.$refs.cpTable.updateTableData();
    },
    showAffiliateModal() {
      this.$refs.cpAffiliateModal.show();
    },
    affiliateColor(affiliateStatus) {
      if (affiliateStatus === 'no') return 'danger';
      if (affiliateStatus === 'pending') return 'warning';
      if (affiliateStatus === 'yes') return 'success';
    },
  },
};
</script>
