<template>
  <div>
    <cp-investor-details
      :link-to-list="getListPath"
      @onGoToList="goToList"
      @onEditProfile="toEditPage"
      @onRemoveElem="handlerRemoveInvestor"
    />
    <cp-remove-investor
      ref="removeInvestModal"
      :item="deleteItem"
      @deleteIsApproved="deleteIsApproved"
    />
  </div>
</template>

<script>
import Cookie from 'js-cookie';
import { mapActions } from 'vuex';
import routeNames from '~/utilities/routes';
import CpInvestorDetails from './components';
import CpRemoveInvestor from '~/pages/_idIssuer/investors/remove-investor';

export default {
  name: 'Details',
  metaInfo: {
    title: 'Details',
  },
  layout: 'main',
  components: {
    CpInvestorDetails,
    CpRemoveInvestor,
  },
  data() {
    return {
      deleteItem: {},
    };
  },
  computed: {
    fields() {
      return import(`../${Cookie.get('currentTypeInvestors')}/fields`);
    },
    getListPath() {
      const { idIssuer, tokenId } = this.$route.params;
      return routeNames.viewDetails(idIssuer, tokenId);
    },
  },
  methods: {
    ...mapActions('investors', ['deleteInvestorByUserId']),
    toEditPage() {
      const { idIssuer, detailsId } = this.$route.params;
      this.$router.push(routeNames.edit.details(idIssuer, detailsId));
    },
    goToList() {
      this.$router.push(this.getListPath);
    },
    handlerRemoveInvestor(data) {
      this.deleteItem = data;
      this.$refs.removeInvestModal.show();
    },
    deleteIsApproved(params) {
      this.deleteInvestorByUserId(params).then(() => {
        const { params: { idIssuer, tokenId }, query: { from, search } } = this.$route;
        const investorList = from || 'onboarding';
        this.$router.push(routeNames[investorList](idIssuer, tokenId, search));
      });
    },
  },
};
</script>

<style src="~/vendor/styles/pages/users.scss" lang="scss"></style>
<style scoped lang="scss">
  .user-view-table .label {
    width: 12rem;
  }
</style>
