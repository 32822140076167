<template>
  <div>
    <cp-general-modal
      ref="CpEditTokenTransactionModal"
      ok-title="Confirm transaction Edit"
      :title="$t('investorDetails.editTokenTransaction.title')"
      :default-value="tokenTransaction"
      @onOk="editTokenTransaction"
    >
      <cp-time-date-picker
        v-model="tokenTransaction.transactionDate"
        name="transactionDate"
        :label="$t('investorDetails.editTokenTransaction.input.transactionDate')"
        disabled
      />
      <br>

      <cp-select
        v-model="lowercaseTransactionType"
        name="transactionType"
        :disabled="!isAllowToEdit"
        :label="$t('investorDetails.editTokenTransaction.input.transactionType')"
        :options="generateTransactionTypesOptions"
      />

      <cp-input
        v-model="tokenTransaction.tokensAmount"
        name="tokensAmount"
        :label="$t('investorDetails.editTokenTransaction.input.tokensAmount')"
        disabled
      />

      <cp-input
        name="price"
        :label="$t('investorDetails.editTokenTransaction.input.pricePerUnit')"
      />

      <cp-input
        v-model="tokenTransaction.transactionAmount"
        name="transactionAmount"
        :label="$t('investorDetails.editTokenTransaction.input.transactionAmount')"
        disabled
      />

      <br>
    </cp-general-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CpGeneralModal from '~/components/common/modals-components/general-modal.vue';
import {
  CpInput,
  CpTimeDatePicker,
} from '~/components/common/standalone-components/inputs';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select.vue';
import { TRANSACTION_TYPE_CONSTANTS } from '../transaction-history.constants';
import { tokensWallets } from '../../options';

export default {
  name: 'CpEditTokenTransactionModal',
  components: {
    CpInput,
    CpTimeDatePicker,
    CpGeneralModal,
    CpSelect,
  },
  props: {
    tokenTransaction: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tokensWallets,
      TRANSACTION_TYPE_CONSTANTS,
      lowercaseTransactionType: '',
    };
  },
  computed: {
    ...mapGetters('currentOperator', ['operatorData']),
    isAllowToEdit() {
      return ([TRANSACTION_TYPE_CONSTANTS.DESTROY, TRANSACTION_TYPE_CONSTANTS.REDEMPTION].includes(this.lowercaseTransactionType));
    },
    generateTransactionTypesOptions() {
      if (([TRANSACTION_TYPE_CONSTANTS.DESTROY, TRANSACTION_TYPE_CONSTANTS.REDEMPTION].includes(this.lowercaseTransactionType))) {
        return this.tokensWallets.transactionHistoryTypes.filter(item => [TRANSACTION_TYPE_CONSTANTS.DESTROY, TRANSACTION_TYPE_CONSTANTS.REDEMPTION].includes(item.value));
      }
      return this.tokensWallets.transactionHistoryTypes;
    },
  },
  watch: {
    'tokenTransaction.transactionType': {
      deep: true,
      handler(transactionType) {
        this.lowercaseTransactionType = transactionType.toLowerCase();
      },
    },
  },
  methods: {
    ...mapActions('investors', ['updateTokenTransaction']),
    show() {
      this.$refs.CpEditTokenTransactionModal.show();
    },
    editTokenTransaction(tokenTransaction) {
      const { idIssuer, tokenId } = this.$route.params;
      this.requestPayload = {
        tokenTransactionId: tokenTransaction.id,
        issuerId: idIssuer,
        tokenId,
        body: {
          costBasisUpdatedBy: 'operator',
          costBasisUpdatedById: Number(this.operatorData.externalId),
          manualPricePerUnit: tokenTransaction.price,
          type: ([TRANSACTION_TYPE_CONSTANTS.DESTROY, TRANSACTION_TYPE_CONSTANTS.REDEMPTION].includes(this.lowercaseTransactionType)) ? this.lowercaseTransactionType : undefined,
        },
      };
      this.updateTokenTransaction(this.requestPayload)
          .then(() => {
            this.$emit('tokenTransactionEdited');
          })
          .finally(() => {
            this.$refs.CpEditTokenTransactionModal.hide();
          });
    },
  },
};
</script>
<style scoped>
.radio-section {
  border-top: 1px solid rgba(10, 23, 39, 0.06);
  border-bottom: 1px solid rgba(10, 23, 39, 0.06);
  padding: 18px 0 0 18px;
  margin-bottom: 18px;
}
.radio-row {
  padding-bottom: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.label-override {
  margin-bottom: 0;
  padding-right: 5px;
  flex-grow: 1;
  flex-basis: 25px;
}
.input-class-override {
  margin-bottom: 0;
  flex-grow: 1;
  flex-basis: 125px;
}
</style>
<style>
.input-class {
  margin-bottom: 0;
}
</style>
