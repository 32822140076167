<template>
  <cp-general-modal
    ref="addLegalSignerModal"
    :title="$t('investorDetails.legalSigners.addLegalSignerModal.title')"
    title-icon="ion ion-ios-person-add"
    :ok-text="$t('investorDetails.legalSigners.addLegalSignerModal.okText')"
    @shown="setInitial"
    @onOk="saveChanges"
    @formInvalid="handleInvalidForm"
    @onCancel="resetModalData"
  >
    <cp-select
      v-model="model.signerType"
      :label="$t('investorDetails.legalSigners.addLegalSignerModal.signerType')"
      name="signerType"
      :options="signerTypeList"
    />

    <div v-if="isIndividual">
      <div class="d-flex justify-content-between">
        <div class="w-80 mr-3">
          <cp-input
            v-model="model.firstName"
            name="firstName"
            :label="$t('investorDetails.legalSigners.addLegalSignerModal.individual.input.firstName')"
            validate="required"
          />
        </div>
        <div class="w-80 mr-3">
          <cp-input
            v-model="model.middleName"
            name="middleName"
            :label="$t('investorDetails.legalSigners.addLegalSignerModal.individual.input.middleName')"
          />
        </div>
        <div class="w-80">
          <cp-input
            v-model="model.lastName"
            name="lastName"
            :label="$t('investorDetails.legalSigners.addLegalSignerModal.individual.input.lastName')"
            validate="required"
          />
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="w-100 mr-3">
          <cp-input
            v-model="model.email"
            name="email"
            :label="$t('investorDetails.legalSigners.addLegalSignerModal.individual.input.email')"
            validate="required|email"
          />
        </div>

        <div class="w-100">
          <cp-date-picker
            v-model="model.birthdate"
            name="birthdate"
            :label="$t('investorDetails.legalSigners.addLegalSignerModal.individual.input.birthDate')"
            validate="required"
            initial-view="year"
            :disabled-dates="disabledDates"
          />
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="w-100 mr-3">
          <cp-select
            v-model="model.countryCode"
            name="countryCode"
            placeholder
            :label="$t('investorDetails.legalSigners.addLegalSignerModal.individual.input.country')"
            :options="countryList"
            validate="required"
          />
        </div>
        <div class="w-100">
          <cp-select
            v-if="isUsa"
            v-model="model.state"
            placeholder
            name="state"
            :label="$t('investorDetails.legalSigners.addLegalSignerModal.individual.input.state')"
            :validate="isUsa ? 'required' : null"
            :options="stateList"
          />
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="w-100 mr-3">
          <cp-select
            v-model="model.taxCountry"
            name="taxCountry"
            placeholder
            :label="$t('investorDetails.legalSigners.addLegalSignerModal.individual.input.taxCountry')"
            :options="countryList"
          />
        </div>
        <div class="w-100 mr-3">
          <cp-input
            v-model="model.taxId"
            name="taxId"
            :label="$t('investorDetails.legalSigners.addLegalSignerModal.individual.input.taxId')"
          />
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="w-100 mr-3">
          <cp-input
            v-model="model.city"
            name="city"
            :label="$t('investorDetails.legalSigners.addLegalSignerModal.individual.input.city')"
            validate="required"
          />
        </div>

        <div class="w-100">
          <cp-input
            v-model="model.street"
            name="street"
            :label="$t('investorDetails.legalSigners.addLegalSignerModal.individual.input.streetName')"
            validate="required"
          />
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="w-100 mr-3">
          <cp-input
            v-model="model.streetNumber"
            name="streetNumber"
            :label="$t('investorDetails.legalSigners.addLegalSignerModal.individual.input.streetNumber')"
            validate="required"
          />
        </div>
        <div class="w-100">
          <cp-input
            v-model="model.zipCode"
            name="zipCode"
            :label="$t('investorDetails.legalSigners.addLegalSignerModal.individual.input.postalCode')"
            validate="required"
          />
        </div>
      </div>
    </div>
    <div v-if="!isIndividual">
      <cp-input
        v-model="model.legalName"
        name="legalName"
        :label="$t('investorDetails.legalSigners.addLegalSignerModal.entity.input.name')"
        validate="required"
      />
      <cp-input
        v-model="model.email"
        name="email"
        :label="$t('investorDetails.legalSigners.addLegalSignerModal.entity.input.email')"
        validate="required|email"
      />
      <cp-input
        v-model="model.businessName"
        name="businessName"
        :label="$t('investorDetails.legalSigners.addLegalSignerModal.entity.input.dba')"
        validate="required"
      />
      <cp-select
        v-model="model.entityType"
        name="entityType"
        placeholder
        :label="$t('investorDetails.legalSigners.addLegalSignerModal.entity.input.type')"
        :options="entityTypeList"
        validate="required"
      />
      <cp-input
        v-model="model.taxId"
        name="taxId"
        placeholder
        :label="$t('investorDetails.legalSigners.addLegalSignerModal.entity.input.taxId')"
        validate="required"
      />
    </div>
    <b-form-checkbox
      v-model="model.ultimateBeneficialOwner"
      name="ultimateBeneficialOwner"
      class="mt-3 visibility-checkbox"
    >
      <span>{{ $t('legalSigner.generalInformation.inputs.ultimateBeneficialOwner') }}</span>
    </b-form-checkbox>
    <cp-file-uploader
      id="create-legal-signer-uploader"
      class="mt-3"
      validate="required"
      :document-error="documentError"
      :max-file-size="maxDocSize"
      class-style-override="small-footprint"
      show-default-message
      :use-secure="true"
      @onChange="onDocumentUpload"
    />
    <cp-select
      v-if="!isIndividual"
      v-model="entityDocumentType"
      name="docType"
      placeholder
      :label="$t('investorDetails.legalSigners.addLegalSignerModal.entity.input.docType')"
      :options="documentTypeList"
      validate="required"
    />
  </cp-general-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import states from '~/utilities/us-states-list';
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import CpFileUploader from '~/components/common/standalone-components/cp-file-uploader';
import CpDatePicker from '~/components/common/standalone-components/inputs/cp-date-picker';
import { legalSigners } from '../../options';

export default {
  name: 'CpAddLegalSignerModal',
  components: {
    CpGeneralModal,
    CpInput,
    CpSelect,
    CpFileUploader,
    CpDatePicker,
  },
  data() {
    return {
      maxDocSize: 50,
      signerTypeList: legalSigners.signerTypeList,
      entityTypeList: legalSigners.entityTypeList,
      states,
      issuerId: this.$route.params.idIssuer,
      userId: this.$route.params.detailsId,
      fileLoaded: false,
      documentError: false,
      entityDocumentData: {
        side: 'front',
        docType: 'other',
        docCategory: 'signer-entity',
      },
      individualDocumentData: {
        side: 'front',
        docType: 'passport',
        docCategory: 'signer-identification',
      },
      entityDocumentType: '',
      documentTypeEntity: legalSigners.documentTypeEntity,
      documentTypeIndividual: legalSigners.documentTypeIndividual,
      model: {},
    };
  },
  computed: {
    ...mapGetters('global', ['countries']),
    countryList() {
      return (this.countries || []).map(item => ({
        text: item.name,
        value: item.countryCode,
      }));
    },
    stateList() {
      return Object.keys(this.states).map(key => ({
        value: key,
        text: this.states[key],
      }));
    },
    isIndividual() {
      return this.model.signerType === 'individual';
    },
    isUsa() {
      return this.model.countryCode === 'US';
    },
    documentTypeList() {
      if (!this.isIndividual) {
        return this.model.entityType ? this.documentTypeEntity[this.model.entityType] : [];
      }
      return this.documentTypeIndividual;
    },
    disabledDates() {
      const today = new Date();
      return {
        from: new Date(today.getFullYear() - 18, today.getMonth()),
      };
    },
  },
  methods: {
    ...mapActions('investors', ['createLegalSigner']),
    handleInvalidForm(isFormInvalid) {
      this.documentError = !!(isFormInvalid && !this.model.documents);
    },
    resetModalData() {
      this.model = {};
      this.documentError = false;
    },
    show() {
      this.$refs.addLegalSignerModal.show();
    },
    onDocumentUpload({ file, fileKey }) {
      if (!fileKey) {
        this.onUploadError(file);
        return;
      }
      this.documentError = false;
      this.model.documents = [{
        fileKey,
        fileName: file.name,
      }];
    },
    setInitial() {
      this.model = {
        signerType: 'individual',
      };
    },
    saveChanges() {
      if (!this.model.documents) {
        this.handleInvalidForm(true);
        return;
      }
      if (this.model.signerType === 'entity') {
        this.model.documents[0] = { ...this.model.documents[0], ...this.entityDocumentData };
        this.model.documents[0].docType = this.entityDocumentType.docType;
      }
      if (this.model.signerType === 'individual') {
        this.model.documents[0] = { ...this.model.documents[0], ...this.individualDocumentData };
      }
      this.createLegalSigner({
        issuerId: this.issuerId,
        userId: this.userId,
        body: this.model,
      })
        .then(() => {
          this.$refs.addLegalSignerModal.hide();
          this.$emit('legalSignerCreated');
        })
        .finally(() => {
          this.resetModalData();
        });
    },
  },
};
</script>
