// eslint-disable-next-line import/prefer-default-export
export const transactionHistory = {
    fields: [
        { key: 'transactionDate', i18nKey: 'investorDetails.transactionHistory.label.table.transactionDate', tdClass: 'align-middle' },
        { key: 'transactionType', i18nKey: 'investorDetails.transactionHistory.label.table.transactionType', tdClass: 'align-middle' },
        { key: 'tokensAmount', i18nKey: 'investorDetails.transactionHistory.label.table.units', tdClass: 'align-middle' },
        { key: 'price', i18nKey: 'investorDetails.transactionHistory.label.table.price', tdClass: 'align-middle' },
        { key: 'transactionAmount', i18nKey: 'investorDetails.transactionHistory.label.table.transactionAmount', tdClass: 'align-middle' },
        { key: 'actions', label: ' ', tdClass: 'text-nowrap d-inline float-right' },
    ],
    transactionTypes: {
        sell: 'investorDetails.transactionHistory.option.transactionType.sell',
        buy: 'investorDetails.transactionHistory.option.transactionType.buy',
        redemption: 'investorDetails.transactionHistory.option.transactionType.redemption',
        issuance: 'investorDetails.transactionHistory.option.transactionType.issuance',
        reallocation: 'investorDetails.transactionHistory.option.transactionType.reallocation',
        destroy: 'investorDetails.transactionHistory.option.transactionType.destroy',
        p2p: 'investorDetails.transactionHistory.option.transactionType.p2p',
    },
};
