import { mapActions, mapGetters } from 'vuex';

export default (blockName, type = 'investors') => ({
  computed: {
    ...mapGetters(type, ['isDetailsEditableBlock', 'getDetailsEditableBlock']),
    isDetailsEditableCurrentBlock() {
      return this.isDetailsEditableBlock(blockName);
    },
    isEditButtonDisabled() {
      if (this.getDetailsEditableBlock) {
        return this.getDetailsEditableBlock !== blockName;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(type, ['changeDetailsEditableBlock']),
    changeMode(val) {
      const activeBlock = this.isDetailsEditableBlock(blockName);

      if (val) {
        this.changeDetailsEditableBlock(false);
        return;
      }
      this.changeDetailsEditableBlock(!activeBlock ? blockName : null);
    },
  },
});
