// TO BE USED with selector.vue parent component/
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      roundIdSelectorModel: '',
      currentActiveRound: '',
      currentTokenId: '',
      currentRoundId: '',
      selectedToken: '',
      currentRound: {
        id: '',
        name: '',
        status: '',
        tokenId: '',
      },
      disableSelectRound: false,
      allRoundsField: { value: null, text: 'All Rounds' },
    };
  },
  computed: {
    ...mapGetters('rounds', ['rounds']),
    allRoundNamesList() {
      let roundsByTokenId = [];
      if (this.rounds.length && this.selectedToken) {
        this.disableSelectRound = false;
        roundsByTokenId = this.getRoundsByTokenId(this.rounds, this.selectedToken);
      }
      if (this.selectedToken === null) {
        this.disableSelectRound = true;
        this.roundIdSelectorModel = null;
        roundsByTokenId = [this.allRoundsField];
      }
      return roundsByTokenId;
    },
  },
  watch: {
    $route({ params: { tokenId: newTokenId } }, { params: { tokenId: oldTokenId } }) {
      if (newTokenId && newTokenId !== oldTokenId) {
        this.selectedToken = newTokenId;
      }
    },
    currentRoundId(newId, oldId) {
      if (newId !== oldId) {
        if (newId === null) {
          this.currentRound.id = undefined;
        } else {
          const roundCopy = this.rounds.find(round => (round.id === newId));
          this.currentRound = JSON.parse(JSON.stringify(roundCopy));
        }
      }
    },
  },
  methods: {
    getRoundsByTokenId(rounds, currentTokenId) {
      return rounds.reduce((roundAccumulator, { id: roundId, tokenId, status, name }) => {
        if (tokenId === currentTokenId) {
          if (status === 'active') {
            this.currentRoundId = roundId;
            this.currentActiveRound = { id: roundId, tokenId, status, name };
            this.roundIdSelectorModel = roundId;
          }
          roundAccumulator.push({ value: roundId, text: name });
        }
        return roundAccumulator;
      }, [this.allRoundsField]);
    },
    getActiveRoundIdByTokenId(tokenId, rounds) {
      rounds = rounds.find(round => (round.tokenId === tokenId && round.status === 'active'));
      return rounds && rounds.id;
    },
  },
};
