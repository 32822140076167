<template>
  <div>
    <cp-general-modal
      ref="issueModal"
      ok-title="Confirm transaction"
      :title="$t('investorDetails.addIssuanceModal.title')"
      :default-value="initialValue"
      :ok-disabled="disabled"
      @onCancel="generalModelCancel"
      @onOk="addIssuance"
    >
      <div>
        <div class="text-muted small">
          {{ $t("investorDetails.addIssuanceModal.label.roundIssuanceDate") }}
        </div>
        <h5 class="m-0">
          <strong>{{ roundIssuanceDate() }}</strong>
        </h5>
      </div>

      <hr class="my-3">

      <cp-select
        :options="options"
        name="tokenWalletId"
        :label="$t('investorDetails.addIssuanceModal.input.issueTo')"
      />

      <cp-input
        name="reason"
        :label="$t('investorDetails.addIssuanceModal.input.reason.text')"
        :placeholder="
          $t('investorDetails.addIssuanceModal.input.reason.placeholder')
        "
      />
      <div class="form-label">
        {{ $t('investorDetails.addIssuanceModal.input.tokenAmount') }}
      </div>
      <div class="radio-section">
        <div class="radio-row">
          <b-form-radio
            v-model="activeRadio"
            class="custom-radio-position"
            name="some-radios"
            value="roundAssignedTokens"
            :disabled="!roundTokensAssigned || tokensDetails.hasActiveIssuance"
          />
          <label class="label-override">{{ $t('investorDetails.addIssuanceModal.option.tokenAmount.roundsAssignedTokens') }}</label>
          <cp-input
            class="input-class-override"
            :value="roundTokensAssigned"
            name="roundAssignedTokens"
            input-class="input-class"
            placeholder="0"
            disabled
            :error="tokensDetails.hasActiveIssuance?$t('investorDetails.tokensWallets.info.hasActiveIssuance'):null"
          />
        </div>
        <div class="radio-row">
          <b-form-radio
            v-model="activeRadio"
            class="custom-radio-position"
            name="some-radios"
            value="customTokenAmount"
          />
          <label class="label-override">{{ $t('investorDetails.addIssuanceModal.option.tokenAmount.customTokenAmount') }}</label>
          <cp-input
            input-class="input-class"
            class="input-class-override"
            name="issueAmount"
            :placeholder="$t('investorDetails.addIssuanceModal.input.numberOfTokens.placeholder')"
            :validate="isCustomTokenAmountSelected ? 'required|decimal': ''"
            @input="setRadioToActive"
          />
        </div>
      </div>
      <div class="form-label">
        {{ $t('investorDetails.addIssuanceModal.label.costBasisCalculation') }}
      </div>
      <div class="label-section">
        <div class="label-row">
          <label>{{ $t('investorDetails.addIssuanceModal.label.totalAmountFunded') }}</label>
          <span>
            <strong class="span-text">
              {{ getTotalAmountFunded }} {{ getCurrencyIdentifier }}
            </strong>
          </span>
        </div>
        <div class="label-row">
          <label>{{ $t('investorDetails.addIssuanceModal.label.pricePerUnit') }}</label>
          <span>
            <strong class="span-text">
              {{ getPricePerUnit | nullToMinus }}
            </strong>
            <strong v-if="getPricePerUnit"> {{ getCurrencyIdentifier | currencySymbol }} </strong>
          </span>
        </div>
      </div>
      <cp-checkbox-group
        :options="getClearFlag"
        name="clearAfterIssuance"
      />
      <cp-time-date-picker
        v-model="currentDate"
        name="issuanceDate"
        validate="required"
        read-only
      />
    </cp-general-modal>
    <cp-confirm-modal
      ref="cpConfirmModal"
      :title="$t('investorDetails.addIssuanceModal.title')"
      @onCancel="confirmModelCancel"
      @onOk="handleConfirm"
    >
      <p>
        {{
          $t('investorDetails.addIssuanceModal.message.confirm', {
            amount: isCustomTokenAmountSelected ? issueAmount : roundTokensAssigned,
            name: investorDetails.name,
          })
        }}
      </p>
    </cp-confirm-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters, mapState } from 'vuex';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';

import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpCheckboxGroup from '~/components/common/standalone-components/inputs/cp-checkbox-group';
import {
  CpInput,
  CpTimeDatePicker,
  CpSelect,
} from '~/components/common/standalone-components/inputs';
import cpLocalSelectRoundMixin from '~/components/modules/cp-local-select-round/selector.mixin';
import { TOKEN_WALLET_STATUSES_CONSTANTS } from '../token-wallet.constants';

export default {
  name: 'AddIssuanceModal',
  components: {
    CpSelect,
    CpInput,
    CpCheckboxGroup,
    CpTimeDatePicker,
    CpGeneralModal,
    CpConfirmModal,
  },
  mixins: [
    cpLocalSelectRoundMixin,
  ],
  props: {
    investorDetails: {
      type: Object,
      required: true,
    },
    tokensDetails: {
      type: Object,
      required: true,
    },
    tokenId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      initialValue: {
        clearAfterIssuance: ['clearAfterIssuance'],
      },
      model: {},
      disabled: false,
      currentDate: '',
      issueAmount: '',
      currentTokenId: this.tokenId || this.$route.params.tokenId,
      roundTokensAssigned: this.tokensDetails.roundTokensAssigned,
      activeRadio: this.modelOpenActiveRadio(this.tokensDetails.roundTokensAssigned),
      queryParams: {
        issuerId: this.$route.params.idIssuer,
        userId: +this.$route.params.detailsId,
        tokenId: this.$route.params.tokenId,
      },
    };
  },
  computed: {
    ...mapGetters('rounds', ['rounds', 'roundsOption']),
    ...mapGetters('investors', ['getDetailGeneral', 'getInvestmentForDisplay']),
    ...mapState('global', ['issuerMainCurrencyIdentifier', 'currencies']),

    getClearFlag() {
      return [
        {
          text: this.$t('investorDetails.addIssuanceModal.label.clearAssignedTokens'),
          value: this.tokensDetails.hasActiveIssuance ? null : 'clearAfterIssuance',
          disabled: this.tokensDetails.hasActiveIssuance,
        },
      ];
    },
    isCustomTokenAmountSelected() {
      return this.activeRadio === 'customTokenAmount';
    },
    correctData() {
      return (data) => {
        const hasTotalFunded = this.getInvestmentForDisplay && this.getInvestmentForDisplay.totalFunded && this.getInvestmentForDisplay.totalFunded.amount > 0;
        const totalAmountFunded = hasTotalFunded ? this.getInvestmentForDisplay.totalFunded.amount.toFixed(4) : null;
        const tokenAmount = this.isCustomTokenAmountSelected ? data.issueAmount : +this.roundTokensAssigned;
        const pricePerUnit = totalAmountFunded ? (totalAmountFunded / tokenAmount).toFixed(4) : null;
        return {
          ...data,
          sourceType: data.tokenWalletId ? 'wallet' : 'treasury',
          issueAmount: this.isCustomTokenAmountSelected ? data.issueAmount : +this.roundTokensAssigned,
          clearAfterIssuance: this.tokensDetails.hasActiveIssuance ? false : Boolean(data.clearAfterIssuance.length),
          issuanceDate: moment(this.currentDate).toISOString(),
          tokenId: this.currentTokenId,
          totalAmountFunded,
          pricePerUnit,
          isCustomTokenAmount: this.isCustomTokenAmountSelected,
        };
      };
    },
    options() {
      const list = [];
      this.tokensDetails.wallets
      .filter(({ status }) => ![
        TOKEN_WALLET_STATUSES_CONSTANTS.REJECTED,
        TOKEN_WALLET_STATUSES_CONSTANTS.CANCELLED,
        ].includes(status))
      .forEach(({ name, address, id }) => {
        if (id) {
          list.push({ text: `${name}: ${address}`, value: id });
        }
      });
      return [
        {
          i18nKey: 'investorDetails.addIssuanceModal.option.walletsList.treasury',
          value: null,
        },
        ...list,
      ];
    },
    getCurrencyIdentifier() {
      if (this.getInvestmentForDisplay && this.getInvestmentForDisplay.totalFunded) {
        const { currencyId } = this.getInvestmentForDisplay.totalFunded;
        const currency = currencyId && this.currencies.find(item => item.id === Number(currencyId) || item.identifier === currencyId);
        return currency ? currency.identifier : '';
      }
      return '';
    },
    getTotalAmountFunded() {
      return (this.getInvestmentForDisplay && this.getInvestmentForDisplay.totalFunded) ? this.getInvestmentForDisplay.totalFunded.amount : null;
    },
    getPricePerUnit() {
      const totalFunded = this.getTotalAmountFunded;
      const tokenAmount = (this.isCustomTokenAmountSelected ? this.issueAmount : +this.roundTokensAssigned);
      return totalFunded && tokenAmount ? (totalFunded / tokenAmount).toFixed(2) : null;
    },
    normalizeReqParams() {
      return { params: { ...this.queryParams } };
    },
  },
  watch: {
    tokensDetails: {
      handler({ roundTokensAssigned }) {
        this.roundTokensAssigned = roundTokensAssigned;
        this.activeRadio = this.modelOpenActiveRadio(roundTokensAssigned);
      },
      deep: true,
    },
    tokenId(currentTokenId) {
      this.currentTokenId = currentTokenId;
    },
  },
  created() {
    const roundId = this.getActiveRoundIdByTokenId(this.currentTokenId, this.rounds);
    this.getInvestorInvestmentForDisplay({ params: { issuerId: this.$route.params.idIssuer, userId: this.$route.params.detailsId, tokenId: this.currentTokenId, roundId } });
  },
  methods: {
    ...mapActions('rounds', ['updateRound']),
    ...mapActions('investors', ['createTokenIssuance', 'getInvestorInvestmentNoStateChange', 'getInvestorInvestmentForDisplay']),
    show() {
      this.$refs.issueModal.show();
      this.currentDate = moment().format();
    },
    roundIssuanceDate() {
      const filteredRounds = this.rounds.filter(round => round.tokenId === this.currentTokenId);
      const activeRound = filteredRounds.find(round => (round.status === 'active'));
      let roundSelected;

      if (activeRound) {
        if (activeRound.isEvergreenRound) {
          return moment().format('ll');
        }
        roundSelected = { ...activeRound };
      } else {
        // If no round is active, find the one with the largest id
        const lastRound = filteredRounds.reduce((prev, current) => (prev.id > current.id ? prev : current));
        roundSelected = { ...lastRound };
      }
      return roundSelected.issuanceDate ? moment(roundSelected.issuanceDate).format('ll') : moment().format('ll');
    },
    addIssuance(data) {
      this.disabled = true;
      this.issueAmount = data.issueAmount;
      const { idIssuer, detailsId } = this.$route.params;
      this.requestPayload = {
        issuerId: idIssuer,
        userId: detailsId,
        body: this.correctData(data),
      };
      this.$refs.cpConfirmModal.show();
    },
    modelOpenActiveRadio(roundTokensAssigned) {
      if (this.tokensDetails.hasActiveIssuance) {
        return 'customTokenAmount';
      }
      return roundTokensAssigned ? 'roundAssignedTokens' : 'customTokenAmount';
    },
    setRadioToActive(value) {
      this.issueAmount = value;
      this.activeRadio = 'customTokenAmount';
    },
    handleConfirm() {
      this.createTokenIssuance(this.requestPayload)
        .then(() => {
          this.$emit('issuanceIsAdded');
        })
        .finally(() => {
          this.generalModelCancel();
          this.$refs.issueModal.hide();
        });
    },
    generalModelCancel() {
      this.disabled = false;
      this.currentDate = moment().format();
      this.issueAmount = '';
      this.activeRadio = this.modelOpenActiveRadio(this.roundTokensAssigned);
      this.requestPayload = {};
    },
    confirmModelCancel() {
      this.disabled = false;
      this.requestPayload = {};
    },
  },
};
</script>
<style scoped>
  .radio-section {
    border-top: 1px solid rgba(10, 23, 39, 0.06);
    border-bottom: 1px solid rgba(10, 23, 39, 0.06);
    padding: 18px 0 0 18px;
    margin-bottom: 18px;
  }
.radio-row {
  padding-bottom: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
  .label-section {
    border-top: 1px solid rgba(10, 23, 39, 0.06);
    border-bottom: 1px solid rgba(10, 23, 39, 0.06);
    padding: 18px 0 0 18px;
    margin-bottom: 18px;
  }
  .label-row {
    padding-bottom: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .span-text {
    align-items: center;
  }
.label-override {
  margin-bottom: 0;
  padding-right: 5px;
  flex-grow: 1;
  flex-basis: 25px;
}
.input-class-override {
  margin-bottom: 0;
  flex-grow: 1;
  flex-basis: 125px;
}
</style>
<style>
.input-class {
  margin-bottom: 0;
}
</style>
