<template>
  <div>
    <label v-if="label">{{ label }}</label>
    <cp-select
      v-model="currentTokenIdModel"
      :disabled="disabled"
      :options="tokenNamesList"
      input-class="custom-select"
      @input="onTokenChange"
    />
  </div>
</template>

<script>
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';

export default {
  name: 'CpLocalSelectorToken',
  components: {
    CpSelect,
  },
  props: {
    controlledTokenId: {
      type: String,
      default: '',
    },
    tokenNamesList: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentTokenIdModel: this.controlledTokenId || this.$route.params.tokenId,
    };
  },
  watch: {
    controlledTokenId: {
      handler(newTokenId, oldTokenId) {
        if (newTokenId && newTokenId !== oldTokenId) {
          this.currentTokenIdModel = newTokenId;
        }
      },
    },
  },
  methods: {
    onTokenChange(selectedTokenId) {
      if (selectedTokenId === 'allTokens') {
        this.$emit('onTokenSelect', null); // workaround to fix issue when passing null to cp-selector
      } else {
        this.$emit('onTokenSelect', selectedTokenId);
      }
    },
  },
};
</script>
