<template>
  <div class="card mb-5">
    <!--Documents header-->
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h4 class="mb-0 text-primary">
            {{ $t('investorDetails.documents.title') }}
          </h4>
        </div>
      </div>
    </div>

    <!--Documents table-->
    <cp-table
      v-if="isIssuerInfoLoaded"
      ref="cpTable"
      without-search
      get-data-action="investors/getInvestorDocuments"
      :url-params="queryParams"
      :fields="documentsFields"
    >
      <template
        slot="image"
        slot-scope="{ rowData }"
      >
        <img
          v-if="canViewEditDocuments"
          class="ui-w-40 d-block link"
          :src="getImageIcon(rowData.item)"
          style="cursor: pointer"
          alt="Document image"
          @click="showPreviewDocModal(rowData.item)"
        >
        <i
          v-else
          class="ion ion-ios-remove-circle-outline icon-resize"
        />
      </template>
      <template
        slot="documentTitle"
      />
      <template
        slot="type"
        slot-scope="{ rowData }"
      >
        {{ $t(i18nKeys.types[rowData.item.type]) }}
      </template>
      <template
        slot="documentFace"
        slot-scope="{ rowData }"
      >
        {{ $t(i18nKeys.faces[rowData.item.documentFace]) }}
      </template>
      <template
        slot="createdAt"
        slot-scope="{ rowData }"
      >
        {{ rowData.item.createdAt | dateFilter }}
      </template>
      <template
        slot="origin"
        slot-scope="{ rowData }"
      >
        {{ $t(i18nKeys.documentOrigin[rowData.item.origin]) }}
      </template>
      <template
        slot="token"
        slot-scope="{ rowData }"
      >
        {{ getTokenName(rowData.item) }}
      </template>
      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <div class="d-flex">
          <cp-button
            icon="ios-arrow-round-down"
            variant="outline-primary"
            :disabled="isDownloadDisabled(rowData.item.id)"
            :is-loading="downloadingDocuments[rowData.item.id]"
            @click="downloadDocument(rowData.item)"
          >
            {{ $t('investorDetails.documents.button.document.download') }}
          </cp-button>
          <div
            v-if="!canDeleteDocument(rowData.item.origin)"
            class="d-inline-block place-holder ml-3"
          />
          <cp-button
            v-if="canDeleteDocument(rowData.item.origin)"
            variant="default btn-xs icon-btn md-btn-flat ml-3"
            @click="showDeleteModal(rowData.item)"
          >
            <i class="ion ion-ios-trash" />
          </cp-button>
        </div>
      </template>
    </cp-table>

    <!--new Documents button-->
    <div class="card-footer">
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <cp-button
            :disabled="!canViewEditDocuments"
            icon="ios-add-circle-outline"
            variant="outline-primary"
            @click="showAddDocModal"
          >
            {{ $t('investorDetails.documents.button.document.add') }}
          </cp-button>
        </div>
      </div>
    </div>

    <add-document-modal
      v-if="canViewEditDocuments"
      ref="addDocModal"
      :categories="categories"
      :tokens-list="issuerTokenNamesAndIds"
      @docIsAdded="refreshTable"
    />

    <preview-document-modal
      v-if="canViewEditDocuments"
      ref="previewDocModal"
      :document-id="documentId"
      :origin="origin"
    />

    <cp-confirm-modal
      v-if="canViewEditDocuments"
      ref="cpConfirmModal"
      :title="$t('investorDetails.removeModal.title', [$t('investorDetails.documents.label.document')])"
      @onOk="deleteDocument"
    >
      {{ confirmModalText }}
    </cp-confirm-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { documents } from '../options';

import AddDocumentModal from './modals/add-document-modal';
import PreviewDocumentModal from './modals/preview-document-modal';
import CpButton from '~/components/common/standalone-components/cp-button';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';
import CpTable from '~/components/shared/cp-table';

import staticPathGeneration from '~/mixins/static-path-generation';

export default {
  name: 'CpInvestorsDetailsDocuments',
  components: {
    CpButton,
    CpConfirmModal,
    AddDocumentModal,
    PreviewDocumentModal,
    CpTable,
  },
  mixins: [staticPathGeneration],
  data() {
    return {
      queryParams: {
        issuerId: this.$route.params.idIssuer,
        userId: this.$route.params.detailsId,
      },
      origin: 'internal',
      documentId: null,
      downloadingDocuments: {},
      deleteItem: null,
      documentsFields: documents.fields,
      categories: documents.categories,
      i18nKeys: documents.i18nKeys,
      confirmModalText: this.$t(
        'investorDetails.removeModal.message',
        [this.$t('investorDetails.documents.label.document')],
      ),
    };
  },
  computed: {
    ...mapGetters('issuersInfo', ['permissionsPerIssuer', 'issuerTokenNamesAndIds']),
    getImageIcon() {
      return ({ imageUrl, contentType, thumbnailUrl }) => (
        contentType === 'application/pdf'
          ? this.getImagePath('fallback-pdf.jpg')
          : thumbnailUrl || imageUrl || this.getImagePath('fallback-image.jpg')
      );
    },
    canViewEditDocuments() {
      return this.permissionsPerIssuer.canOperatorViewEditInvestorInfo;
    },
    isIssuerInfoLoaded() {
      return this.issuerTokenNamesAndIds.length > 0;
    },
  },
  methods: {
    ...mapActions('investors', [
      'downloadUserDocumentById',
      'deleteInvestorDetailDocument',
    ]),
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    refreshTable() {
      this.$refs.cpTable.updateTableData();
    },
    showDeleteModal({ id }) {
      this.deleteItem = id;
      this.$refs.cpConfirmModal.$refs.confirmModal.show();
    },
    showPreviewDocModal({ id, origin }) {
      this.documentId = id;
      this.origin = origin;
      this.$refs.previewDocModal.$refs.previewDocument.show();
    },
    showAddDocModal() {
      this.$refs.addDocModal.$refs.uploadDocument.show();
    },
    deleteDocument() {
      this.deleteInvestorDetailDocument({
        userDocumentId: this.deleteItem,
        ...this.queryParams,
      }).then(this.refreshTable);
    },
    downloadDocument({ id, origin }) {
      this.downloadingDocuments[id] = true;
      this.downloadUserDocumentById({
        userDocumentId: id,
        origin,
        ...this.queryParams,
      }).then(
        ({ data } = {}) => {
          const { imageUrl } = data;
          if (imageUrl) {
            // to easy download file, create downloadable link and click it
            const link = document.createElement('a');
            link.target = '_blank';
            link.download = imageUrl.split('/').pop();
            link.name = imageUrl.split('/').pop();
            link.href = imageUrl;
            link.click();
          } else {
            this.CALL_ERROR_TOASTER(this.$t('investorDetails.documents.message.downloadFailure'));
          }
        },
      ).finally(() => {
        delete this.downloadingDocuments[id];
      });
    },
    isDownloadDisabled(id) {
      return this.canViewEditDocuments ? this.downloadingDocuments[id] : true;
    },
    canDeleteDocument(origin) {
      return origin !== 'securitize-id' && this.canViewEditDocuments;
    },
    getTokenName(tokenData) {
      if (!tokenData.tokenId) return '';
      return this.issuerTokenNamesAndIds.find(({ value }) => value === tokenData.tokenId).text;
    },
  },
};
</script>
<style>
  .icon-resize:before{
    font-size: 1.8em;
  }
  .place-holder {
    width: calc(1.125rem + 2px);
  }
</style>
