<template>
  <cp-confirm-modal
    ref="cpConfirmModal"
    :title="$t('investorDetails.disable2faModal.title')"
    :ok-text="$t('investorDetails.disable2faModal.button.disable')"
    @onOk="$emit('confirm')"
  >
    {{ $t('investorDetails.disable2faModal.message') }}
  </cp-confirm-modal>
</template>

<script>
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';

export default {
  name: 'CpDisable2faModal',
  components: {
    CpConfirmModal,
  },
  methods: {
    show() {
      this.$refs.cpConfirmModal.show();
    },
  },
};
</script>
