<template>
  <div class="card mb-5">
    <!--header-->
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h4 class="mb-0 text-primary">
            {{ $t('investorDetails.tokensWallets.title') }}
          </h4>
        </div>
        <cp-edit-buttons
          v-if="tokensWalletsSection.hasInvestmentRequests && activeRound"
          :edit-mode="isDetailsEditableCurrentBlock"
          :disable-edit="isButtonDisabled"
          @changeMode="changeMode"
          @update="saveChanges"
        />
      </div>
    </div>
    <!--general tokens data-->
    <div class="card-body bg-light">
      <div class="row align-items-center">
        <div class="col-md-4">
          <div class="row align-items-center">
            <label class="col-form-label col-sm-3 text-sm">{{ $t('investorDetails.investment.label.token') }}:</label>
            <cp-select
              v-model="tokensWalletsSelectorModel"
              class="col-sm-9"
              input-class="custom-select"
              :options="allTokensListToNames"
              @input="onTokenChange"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showSpinner"
      class="text-center my-2 minimum-height"
    >
      <div class="row align-items-center mb-2">
        <div class="col-md-12 text-center">
          <b-spinner class="align-middle" />
        </div>
      </div>
    </div>
    <div
      v-if="!showSpinner"
      class="card-body"
    >
      <div>
        <h5 class="my-0 mb-3">
          {{ $t('investorDetails.tokensWallets.subTitle.general') }}
        </h5>
        <div class="row">
          <div class="col-6 col-md">
            <div class="text-muted small">
              {{ $t('investorDetails.tokensWallets.label.blockchainId') }}
            </div>
            <h5 class="m-0">
              <strong>{{ tokensWalletsSection.blockchainId || '-' }}</strong>
            </h5>
          </div>
          <div class="col-6 col-md">
            <div class="text-muted small">
              {{ $t('investorDetails.tokensWallets.label.totalTokensHeld') }}
            </div>
            <h5 class="m-0">
              <strong :class="isAffiliateRedFlagEnable">
                <span v-if="!tokensWalletsSection.isSpecificToken"> - </span>
                <cp-ellipsis
                  v-else
                  :data-value="tokenHeldAmount"
                  :tooltip-value="isAffiliateTooltipEnable(tokenHeldAmount)"
                />
              </strong>
            </h5>
          </div>
          <div class="col-6 col-md">
            <div class="text-muted small">
              {{ $t('investorDetails.tokensWallets.label.totalTreasuryTokens') }}
            </div>
            <h5 class="m-0">
              <strong :class="isAffiliateRedFlagEnable">
                <span v-if="!tokensWalletsSection.isSpecificToken"> - </span>
                <cp-ellipsis
                  v-else
                  :data-value="tokensWalletsSection.tokensTreasuryForDisplay"
                  :tooltip-value="isAffiliateTooltipEnable(tokensWalletsSection.tokensTreasuryForDisplay)"
                />
              </strong>
            </h5>
          </div>
          <div class="col-6 col-md">
            <div class="text-muted small">
              {{ $t('investorDetails.tokensWallets.label.totalBonusTokens') }}
            </div>
            <h5 class="m-0">
              <strong>
                <span v-if="!tokensWalletsSection.isSpecificToken"> - </span>

                <cp-ellipsis
                  v-else
                  :data-value="tokensWalletsSection.bonusTokensForDisplay"
                />
              </strong>
            </h5>
          </div>
          <div class="col-6 col-md">
            <div class="text-muted small">
              {{ $t('investorDetails.tokensWallets.label.totalLockedTokens') }}
            </div>
            <h5 class="m-0">
              <strong>
                <span v-if="!tokensWalletsSection.isSpecificToken"> - </span>

                <cp-ellipsis
                  v-else
                  :data-value="tokensWalletsSection.totalLockedTokensForDisplay"
                />
              </strong>
            </h5>
          </div>
          <div
            v-if="isDripActive"
            class="col-6 col-md"
          >
            <div class="text-muted small">
              {{ $t('investorDetails.tokensWallets.label.dripParticipant') }}
            </div>
            <h5
              v-if="isDetailsEditableCurrentBlock && isTransferAgentOperator"
              class="m-0"
            >
              <label
                class="switcher"
                for="tokenPaymentPrefence"
              >
                <input
                  id="tokenPaymentPrefence"
                  v-model="isDrip"
                  type="checkbox"
                  class="switcher-input"
                  @change="changeTokenPreferencesDrip"
                >
                <span class="switcher-indicator">
                  <span class="switcher-yes" />
                  <span class="switcher-no" />
                </span>
              </label>
            </h5>
            <div
              v-else
              class="flex-grow-1"
            >
              <span
                :class="[getTokenPreferencesIsDrip ? 'ion-md-checkmark text-success': 'text-danger ion-md-close ']"
                class="ion  configuration-setting"
              />
            </div>
          </div>
        </div>
      </div>

      <hr class="m-0">

      <div class="card-body">
        <div class="row align-items-center">
          <div class="col-8">
            <h5 class="my-0 mb-3">
              {{ $t('investorDetails.tokensWallets.subTitle.forRound', [lastOrActiveRound]) }}
            </h5>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-6 col-md">
            <div class="text-muted small">
              {{ $t('investorDetails.tokensWallets.label.bonusTokens') }}
            </div>
            <cp-input
              v-if="isDetailsEditableCurrentBlock && isTransferAgentOperator"
              v-model="model.roundBonusTokens"
              :validate="`required|min_value:0`"
              :html-props="{min:0}"
              name="roundBonusTokens"
              input-type="number"
            />
            <h5
              v-else
              class="m-0"
            >
              <strong>
                <span v-if="!tokensWalletsSection.isSpecificToken"> - </span>
                <cp-ellipsis
                  v-else
                  :data-value="tokensWalletsSection.roundBonusTokensForDisplay"
                />
              </strong>
            </h5>
          </div>
          <div class="col-6 col-md">
            <div class="text-muted small">
              {{ $t('investorDetails.tokensWallets.label.assignedBonusTokens') }}
            </div>
            <cp-input
              v-if="isDetailsEditableCurrentBlock"
              v-model="model.roundBonusTokensAssigned"
              :validate="`required|min_value:0`"
              :html-props="{min:0}"
              name="roundBonusTokensAssigned"
              input-type="number"
            />
            <h5
              v-else
              class="m-0"
            >
              <strong>
                <span v-if="!tokensWalletsSection.isSpecificToken"> - </span>
                <cp-ellipsis
                  v-else
                  :data-value="tokensWalletsSection.roundBonusTokensAssignedForDisplay"
                />
              </strong>
            </h5>
          </div>
          <div class="col-6 col-md">
            <div class="text-muted small">
              {{ $t('investorDetails.tokensWallets.label.assignedTokens') }}
            </div>
            <cp-input
              v-if="isDetailsEditableCurrentBlock"
              v-model="model.roundTokensAssigned"
              :validate="`required|min_value:0`"
              :html-props="{min:0}"
              name="roundTokensAssigned"
              input-type="number"
              :disabled="model.hasActiveIssuance"
              :error="model.hasActiveIssuance?$t('investorDetails.tokensWallets.info.hasActiveIssuance'): null"
            />
            <h5
              v-else
              class="m-0"
            >
              <strong>
                <span v-if="!tokensWalletsSection.isSpecificToken"> - </span>
                <cp-ellipsis
                  v-else
                  :data-value="tokensWalletsSection.roundTokensAssignedForDisplay"
                  :display-warning="tokensWalletsSection.hasActiveIssuance"
                  :warning-message="$t('investorDetails.tokensWallets.info.hasActiveIssuance')"
                />
              </strong>
            </h5>
          </div>
          <div class="col-6 col-md d-md-flex justify-content-end">
            <cp-button
              v-if="showMoveBonusButton"
              variant="outline-primary"
              icon="ios-arrow-round-forward"
              :disabled="!tokensWalletsSection.roundBonusTokensAssigned || !isTransferAgentOperator"
              @click="showMoveBonusConfirmModal"
            >
              {{ $t('investorDetails.tokensWallets.button.bonus.move') }}
            </cp-button>
          </div>
        </div>
      </div>
    </div>
    <hr class="m-0">

    <div class="card-body info-token-wallets">
      <div class="row align-items-center">
        <div class="col-12 col-md">
          <small>
            <i class="ion ion-ios-information-circle-outline" />
            {{ $t('investorDetails.tokensWallets.info.aboutTokensRefertTo') }}
            <br>
            <i class="ion ion-ios-information-circle-outline" />
            {{ $t('investorDetails.tokensWallets.info.aboutTbeStandsFor') }}
          </small>
        </div>
      </div>
    </div>
    <!--Move bonus confirm-->
    <cp-confirm-modal
      v-if="showMoveBonusButton"
      ref="cpMoveBonusConfirm"
      :title="$t('investorDetails.moveBonusModal.title')"
      @onOk="moveBonusHandler"
    >
      {{ moveBonusConfirmText }}
    </cp-confirm-modal>

    <hr class="m-0">

    <!--wallets header-->
    <div class="card-header">
      <div class="row align-items-center mt-4">
        <div class="col-8">
          <h5 class="my-0">
            {{ $t('investorDetails.tokensWallets.subTitle.wallets') }}
          </h5>
        </div>
        <div class="col-4 d-flex justify-content-end">
          <cp-button
            variant="outline-primary"
            :disabled="isButtonDisabled"
            icon="ios-add-circle-outline"
            @click="showAddWalletModal"
          >
            {{ $t('investorDetails.tokensWallets.button.wallets.add') }}
          </cp-button>
        </div>
      </div>
    </div>

    <add-wallet-modal
      ref="addWalletModal"
      :token-id="tokensWalletsSelectorModel"
      @walletIsUpdated="refreshWalletsTable"
    />

    <edit-wallet-modal
      ref="editWalletModal"
      :item="editableWallet"
      @walletIsUpdated="refreshWalletsTable"
    />

    <!--wallets table-->
    <cp-table
      ref="walletsTable"
      without-search
      get-data-action="investors/getInvestorTokensWallets"
      :url-params="queryParams"
      :data-parser="walletsParser"
      :fields="tokensWallets.wallets"
    >
      <template
        slot="address"
        slot-scope="{ rowData }"
      >
        <span v-if="!rowData.item.link">
          {{ rowData.item.address }}
        </span>
        <a
          v-else
          :href="rowData.item.link"
          target="_blank"
        >
          {{ rowData.item.address }}
        </a>
      </template>

      <template
        slot="walletType"
        slot-scope="{ rowData }"
      >
        {{ $t(tokensWallets.i18nKeys.walletTypes[rowData.item.walletType]) }}
      </template>

      <template
        slot="owner"
        slot-scope="{ rowData }"
      >
        <router-link
          v-if="rowData.item.walletType === 'omnibus'"
          :to="{name: 'omnibusWalletOwnerDetails', params: {detailsId: rowData.item.owner.id, ...queryParams}}"
        >
          {{ rowData.item.owner.name }}
        </router-link>
        <span v-else>
          {{ rowData.item.owner.name }}
        </span>
      </template>

      <template
        slot="tokensHeld"
        slot-scope="{ rowData }"
      >
        <cp-ellipsis
          :data-value="rowData.item.omnibusTokens || rowData.item.tokensHeldForDisplay"
          :tooltip-value="rowData.item.tokensHeldForDisplay"
        />
      </template>

      <template
        slot="tokensPending"
        slot-scope="{ rowData }"
      >
        <cp-ellipsis :data-value="rowData.item.tokensPendingForDisplay" />
      </template>

      <template
        slot="status"
        slot-scope="{ rowData }"
      >
        <b-badge
          v-if="rowData.item.status"
          class="align-text-bottom ml-1"
          :variant="statusStyle[rowData.item.status]"
        >
          {{ getWalletStatus(rowData.item.status) }}
        </b-badge>
      </template>

      <template
        slot="creationDate"
        slot-scope="{ rowData }"
      >
        <span>
          {{ rowData.item.creationDate | standardDateFilter }}
        </span>
      </template>

      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <div class="d-flex">
          <cp-button
            :disabled="isEditDisabled(rowData.item)"
            variant="default btn-xs icon-btn md-btn-flat"
            @click="showEditWalletModal(rowData.item)"
          >
            <i class="ion ion-md-create" />
          </cp-button>

          <cp-button
            v-if="showDeleteAction(rowData.item)"
            variant="default btn-xs icon-btn md-btn-flat ml-3"
            @click="openDeleteModal(rowData.item, 'wallet')"
          >
            <i class="ion ion-ios-trash" />
          </cp-button>
        </div>
      </template>
    </cp-table>

    <hr class="m-0">

    <!--issuances header-->
    <div class="card-header">
      <div class="row align-items-center mt-4">
        <div class="col-8">
          <h5 class="my-0">
            {{ $t('investorDetails.tokensWallets.subTitle.issuances') }}
          </h5>
        </div>
        <div class="col-4 d-flex justify-content-end">
          <cp-button
            v-if="isTokensTreasury && canIssueTokens"
            variant="outline-primary"
            :disabled="isButtonDisabled"
            icon="ios-shuffle"
            @click="handleAddIssue"
          >
            {{ $t('investorDetails.tokensWallets.button.issuance.add') }}
          </cp-button>
        </div>
      </div>
    </div>
    <cp-add-issuance-modal
      v-if="canIssueTokens"
      ref="addIssuanceModal"
      :token-id="tokensWalletsSelectorModel"
      :tokens-details="tokensWalletsSection"
      :investor-details="getDetailGeneral"
      @issuanceIsAdded="refreshIssuancesInfo"
    />
    <cp-edit-issuance-modal
      ref="editIssuanceModal"
      :issuance-detail="issuanceDetail"
      :investor-details="getDetailGeneral"
      @issuanceIsEdited="refreshIssuancesInfo"
    />

    <!--issuances table-->
    <cp-table
      ref="issuancesTable"
      without-search
      get-data-action="investors/getInvestorTokensWallets"
      :url-params="queryParams"
      :data-parser="issuancesParser"
      :fields="tokensWallets.issuances"
    >
      <template
        slot="creationDate"
        slot-scope="{ rowData }"
      >
        <span>
          {{ rowData.item.creationDate | standardDateFilter }}
        </span>
      </template>

      <template
        slot="executionDate"
        slot-scope="{ rowData }"
      >
        <span>
          {{ rowData.item.executionDate | standardDateFilter }}
        </span>
      </template>

      <template
        slot="issuanceTime"
        slot-scope="{ rowData }"
      >
        <span>
          {{ rowData.item.issuanceTime | standardDateFilter }}
        </span>
      </template>

      <template
        slot="issueAmount"
        slot-scope="{ rowData }"
      >
        <span>
          {{ rowData.item.issueAmountForDisplay }}
        </span>
      </template>

      <template
        slot="source"
        slot-scope="{ rowData }"
      >
        {{ $t(tokensWallets.i18nKeys.sources[rowData.item.source]) }}
      </template>

      <template
        slot="target"
        slot-scope="{ rowData }"
      >
        {{ $t(tokensWallets.i18nKeys.walletsList[rowData.item.target]) }}
      </template>

      <template
        slot="status"
        slot-scope="{ rowData }"
      >
        <b-badge
          v-if="rowData.item.status"
          class="align-text-bottom ml-1"
          :variant="statusStyle[rowData.item.status]"
        >
          {{ getIssuanceStatuses(rowData.item.status) }}
        </b-badge>
      </template>

      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <div class="d-flex">
          <cp-button
            variant="default btn-xs icon-btn md-btn-flat ml-3"
            @click="openEditModal(rowData.item)"
          >
            <i class="ion ion-md-create" />
          </cp-button>
          <cp-button
            v-if="rowData.item.canDelete && canIssueTokens"
            variant="default btn-xs icon-btn md-btn-flat"
            @click="openDeleteModal(rowData.item, 'issuance')"
          >
            <i class="ion ion-ios-trash" />
          </cp-button>
        </div>
      </template>
    </cp-table>

    <!--Transfer to wallet from treasury button & modal-->
    <div
      v-if="issuanceToTreasury && canIssueTokens"
      class="col-4 my-3"
    >
      <cp-button
        variant="outline-primary"
        icon="ios-send"
        :disabled="isButtonDisabled"
        @click="showTransferTreasuryModal"
      >
        {{ $t('investorDetails.tokensWallets.button.wallets.transferFromTreasury') }}
      </cp-button>
    </div>

    <cp-transfer-treasury-modal
      v-if="canIssueTokens"
      ref="CpTransferTreasury"
      :wallets-list="tokensWalletsSection.wallets"
      :balance="getBalanceToTbeTransfer"
      @transferredToTreasury="transferredToTreasury"
    />

    <!--remove modal-->
    <cp-confirm-modal
      ref="cpConfirmModal"
      :title="$t('investorDetails.removeModal.title', [confirmModalEntity && $t(`investorDetails.tokensWallets.label.${confirmModalEntity}`)])"
      :item="deleteItem"
      @onOk="removeEntity"
    />

    <!--auto-issuance confirm modal-->
    <cp-confirm-modal
      ref="cpConfirmEditWithAutoIssuanceEnabled"
      :title="$t('investorDetails.tokensWallets.autoIssuanceConfirmationModal.title')"
      @onOk="saveChanges({checkAutoIssuance:false})"
    >
      {{ $t('investorDetails.tokensWallets.autoIssuanceConfirmationModal.message') }}
    </cp-confirm-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions, mapState } from 'vuex';

import CpEditButtons from '~/components/common/edit-buttons';
import CpButton from '~/components/common/standalone-components/cp-button';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';
import CpEllipsis from '~/components/common/ellipse';
import CpTable from '~/components/shared/cp-table';
import CpEditableMixin from '~/mixins/editable-invester-details-component';
import CpSelect from '@/components/common/standalone-components/inputs/cp-select';
import EditWalletModal from './modals/edit-investor-wallet-modal';
import AddWalletModal from './modals/add-investor-wallet-modal';
import CpAddIssuanceModal from './modals/add-issuance-modal';
import CpEditIssuanceModal from './modals/edit-issuance-modal.vue';
import CpTransferTreasuryModal from './modals/transfer-treasury-modal';
import { tokensWallets, statusStyle } from '../options';

export default {
  name: 'TokensWallets',
  components: {
    CpSelect,
    CpEditButtons,
    CpConfirmModal,
    CpTransferTreasuryModal,
    CpEllipsis,
    CpButton,
    CpInput,
    CpAddIssuanceModal,
    CpEditIssuanceModal,
    CpTable,
    EditWalletModal,
    AddWalletModal,
  },
  mixins: [CpEditableMixin('tokens')],
  data() {
    return {
      statusStyle,
      tokensWallets,
      showSpinner: true,
      tokenFieldIsValid: false,
      editableWallet: {},
      issuanceDetail: {},
      confirmModalEntity: null,
      deleteItem: {},
      model: {},
      tokensWalletsSelectorModel: this.$route.params.tokenId,
      queryParams: {
        issuerId: this.$route.params.idIssuer,
        userId: +this.$route.params.detailsId,
        tokenId: this.$route.params.tokenId,
      },
      isDrip: null,
      isAutoIssuanceEnabled: false,
    };
  },
  computed: {
    ...mapGetters('investors', ['getDetailGeneral', 'tokensWalletsSection', 'getTokenPreferencesIsDrip', 'isDripActive', 'getDetailTokens']),
    ...mapGetters('currentOperator', ['operatorData', 'authorizationLevel']),
    ...mapGetters('rounds', ['rounds']),
    ...mapGetters('issuersInfo', ['brokerDealerGroupPermissions']),
    ...mapState('issuersInfo', ['currentToken', 'issuerInfo']),
    allTokensListToNames() {
      const tokeDropDownList = this.issuerInfo.tokens.map(token => ({ value: token.id, text: token.symbol || token.name }));
      return [{ value: null, text: 'All tokens' }].concat(tokeDropDownList);
    },
    isTransferAgentOperator() {
      const { authorizations } = this.operatorData;
      return authorizations.includes('transfer-agent');
    },
    moveBonusConfirmText() {
      return this.$t('investorDetails.moveBonusModal.message', [this.model.roundBonusTokensAssigned]);
    },
    showMoveBonusButton() {
      return this.authorizationLevel !== 'brokerdealer' || (this.authorizationLevel === 'brokerdealer' && this.isTransferAgentOperator);
    },
    canIssueTokens() {
      if (this.authorizationLevel === 'brokerdealer') {
        return this.brokerDealerGroupPermissions && this.brokerDealerGroupPermissions.canIssueTokens;
      }
      return true;
    },
    confirmModalText() {
      return this.$t(
        'investorDetails.removeModal.message',
        [this.confirmModalEntity && this.$t(`investorDetails.tokensWallets.label.${this.confirmModalEntity}`)],
      );
    },
    isTokensTreasury() {
      return this.tokensWalletsSection.tokensTreasury !== undefined;
    },
    showDeleteAction() {
      const { authorizationLevel } = this.operatorData;
      return ({ status }) => authorizationLevel === 'megaadmin' && status === 'pending';
    },
    issuanceToTreasury() {
      const { wallets, tokensTreasury } = this.tokensWalletsSection;
      return wallets.length && tokensTreasury;
    },
    lastOrActiveRound() {
      return (this.activeRound && this.activeRound.name) || (this.lastRound && this.lastRound.name) || { name: 'Unknown' };
    },
    roundsByTokenId() {
      return this.rounds.filter(({ tokenId }) => tokenId === this.$route.params.tokenId);
    },
    activeRound() {
      return this.roundsByTokenId.find(item => item.status === 'active');
    },
    lastRound() {
      const lastRelevantRound = this.roundsByTokenId.filter(item => moment(item.startsAt).unix() < moment(new Date()).add(1, 'days').unix());
      return lastRelevantRound.sort((a, b) => moment(a.startsAt).unix() - moment(b.startsAt).unix()).pop();
    },
    tokenHeldAmount() {
      const { tokensHeldForDisplay } = this.tokensWalletsSection;
      return tokensHeldForDisplay;
    },
    getBalanceToTbeTransfer() {
      const { tokensTreasury, totalLockedTbeTokens, inProgressWithdrawalsATS = 0 } = this.tokensWalletsSection;
      if (tokensTreasury === undefined || totalLockedTbeTokens === undefined) return null;
      return tokensTreasury - totalLockedTbeTokens - inProgressWithdrawalsATS;
    },
    isButtonDisabled() {
      return !this.tokensWalletsSection.isSpecificToken;
    },
    isAffiliateRedFlagEnable() {
      return this.tokensWalletsSection.isAffiliate ? 'is-affiliate' : '';
    },
    normalizeReqParams() {
      return { params: { ...this.queryParams } };
    },
  },
  watch: {
    $route({ params: { tokenId: newTokenId } }, { params: { tokenId: oldTokenId } }) {
      if (newTokenId && newTokenId !== oldTokenId) {
        this.queryParams.tokenId = newTokenId;
        this.tokensWalletsSelectorModel = newTokenId;
      }
      this.showSpinner = true;
      this.getInvestorTokensWallets(this.normalizeReqParams).finally(() => {
        this.model = { ...this.tokensWalletsSection };
        this.showSpinner = false;
      });
    },
    getTokenPreferencesIsDrip(newValue) {
      this.isDrip = newValue;
    },
  },
  created() {
    this.changeMode(true);
    this.showSpinner = true;
    this.getAutoIssuanceStatus();
    this.getInvestorTokensWallets(this.normalizeReqParams).finally(() => {
      this.model = { ...this.tokensWalletsSection };
      this.showSpinner = false;
    });
  },
  methods: {
    ...mapActions('investors', [
      'addTokenWallerPlaceholderObject',
      'deleteInvestorDetailTokenWallet',
      'updateInvestorTokensConfirmed',
      'deleteTokenIssuanceById',
      'moveBonus',
      'getInvestorTokensWallets',
      'createUpdateTokenPreferences',
    ]),
    ...mapActions('currentOperator', ['getNotifications']),
    refreshTokensData() {
      return this.getInvestorTokensWallets(this.normalizeReqParams).then((res) => {
        this.model = { ...this.tokensWalletsSection };
        return res;
      });
    },
    showMoveBonusConfirmModal() {
      this.changeMode(true);
      this.$refs.cpMoveBonusConfirm.show();
    },
    getAutoIssuanceStatus() {
      this.isAutoIssuanceEnabled = this.getDetailTokens.isAutoIssuanceEnabled;
    },
    changeTokenPreferencesDrip(e) {
      this.isDrip = e.target.checked;
    },
    async saveTokenPreferencesDrip() {
      let method;
      if (this.isDrip) {
        method = 'drip';
      } else {
        method = 'none';
      }
      const { id: operatorId } = this.operatorData;
      await this.createUpdateTokenPreferences({
            issuerId: this.$route.params.idIssuer,
            tokenId: this.$route.params.tokenId,
            investorId: this.$route.params.detailsId,
            method,
            updatedByOperatorId: operatorId,
            updatedBy: 'operator',
          });
    },
    setInitialTokenPreferencesDrip() {
      this.isDrip = this.setInitialTokenPreferencesDrip;
    },
    moveBonusHandler() {
      this.moveBonus(this.queryParams).then(this.refreshTokensData());
    },
    isEditDisabled({ status }) {
      return status === 'rejected';
    },
    showEditWalletModal(item) {
      this.changeMode(true);
      this.editableWallet = item;
      this.$refs.editWalletModal.show();
    },
    showAddWalletModal() {
      this.changeMode(true);
      this.$refs.addWalletModal.show();
    },
    walletsParser({ wallets }) {
      return wallets;
    },
    refreshWalletsTable() {
      this.$refs.walletsTable.updateTableData();
      this.getInvestorTokensWallets(this.normalizeReqParams);
    },
    walletRemove(id) {
      this.deleteInvestorDetailTokenWallet({
        ...this.queryParams,
        tokenWalletId: id,
      }).then(this.refreshWalletsTable);
    },
    issuancesParser({ issuances }) {
      return issuances;
    },
    isAffiliateTooltipEnable(item) {
      return this.tokensWalletsSection.isAffiliate ? this.$t('investorDetails.tokensWallets.label.isAnAffiliate') : item;
    },
    refreshIssuancesInfo() {
      this.refreshWalletsTable();
      this.$refs.issuancesTable.updateTableData();
      this.getInvestorTokensWallets(this.normalizeReqParams);
      if (this.authorizationLevel !== 'brokerdealer') this.getNotifications(this.queryParams.issuerId);
      this.refreshTokensData();
    },
    handleAddIssue() {
      this.changeMode(true);
      this.$refs.addIssuanceModal.show();
    },
    issuanceRemove(id) {
      this.deleteTokenIssuanceById({
        ...this.queryParams,
        issuancesId: id,
      }).then(() => {
        this.refreshIssuancesInfo();
        this.refreshTokensData();
      });
    },
    removeEntity({ id }) {
      this[`${this.confirmModalEntity}Remove`](id);
    },
    openDeleteModal(item, entity) {
      this.changeMode(true);
      this.confirmModalEntity = entity;
      this.deleteItem = item;
      this.$refs.cpConfirmModal.$refs.confirmModal.show();
    },
    openEditModal(item) {
      this.issuanceDetail = { ...item, issuanceTime: moment(item.issuanceTime).format('YYYY-MM-DD HH:mm') };
      this.$refs.editIssuanceModal.show();
    },
    showTransferTreasuryModal() {
      this.$refs.CpTransferTreasury.show();
    },
    transferredToTreasury() {
      this.getInvestorTokensWallets(this.normalizeReqParams).then(this.refreshWalletsTable);
    },
    saveChanges(props) {
      const {
        roundTokensAssigned,
        roundBonusTokensAssigned,
        roundBonusTokens,
      } = this.model;
      const { checkAutoIssuance = true } = props || {};
      const { roundTokensAssigned: roundTokensAssignedBeforeEdit } = this.tokensWalletsSection;

      if (checkAutoIssuance && this.isAutoIssuanceEnabled && roundTokensAssignedBeforeEdit === 0 && roundTokensAssigned > 0) {
        this.$refs.cpConfirmEditWithAutoIssuanceEnabled.show();
        return;
      }

      const body = {
        roundTokensAssigned: roundTokensAssigned || 0,
        roundBonusTokensAssigned: roundBonusTokensAssigned || 0,
      };
      if (this.isTransferAgentOperator) body.roundBonusTokens = roundBonusTokens || 0;
      this.updateInvestorTokensConfirmed({
        ...this.queryParams,
        body,
      }).then(() => {
        this.refreshTokensData().then(() => {
          this.changeMode();
        });
      });
      if (this.isDripActive && this.getTokenPreferencesIsDrip !== this.isDrip) this.saveTokenPreferencesDrip();
    },
    getWalletStatus(status) {
      const { i18nKey } = tokensWallets.walletStatuses.find(({ value }) => value === status);
      return this.$t(i18nKey);
    },
    getIssuanceStatuses(status) {
      const { i18nKey } = tokensWallets.issuanceStatuses.find(({ value }) => value === status);
      return this.$t(i18nKey);
    },
    getTarget(target) {
      if (target === 'treasury') {
        return this.$t('investorDetails.label.treasury');
      }
      return target;
    },
    onTokenChange(selectedTokenId) {
      this.queryParams.tokenId = selectedTokenId;
      this.tokensWalletsSelectorModel = selectedTokenId;
      this.showSpinner = true;
      this.getInvestorTokensWallets(this.normalizeReqParams).finally(() => {
        this.showSpinner = false;
      });
      this.refreshTokensData();
    },
  },
};
</script>

<style lang="scss" scoped>
    .info-token-wallets {
        padding-top: 6px;
        padding-bottom: 6px;
        background-color: rgba(10, 23, 39, 0.025);
    }

    .is-affiliate {
        color: #bd121c;
    }
    .minimum-height {
        padding-top: 50px;
        min-height: calc(200px - 37px);
    }
</style>
