var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card mb-5"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row align-items-center mt-4"},[_c('div',{staticClass:"col-8"},[_c('h5',{staticClass:"my-0"},[_vm._v(" "+_vm._s(_vm.$t('investorDetails.transactionHistory.title'))+" ")])])])]),_c('cp-table',{ref:"cpTransactionHistoryTable",attrs:{"without-search":"","get-data-action":"investors/getInvestorTransactionHistory","url-params":_vm.queryParams,"fields":_vm.transactionHistoryFields},scopedSlots:_vm._u([{key:"transactionDate",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatTransactionDate(rowData.item.transactionDate))+" "),_c('br')])]}},{key:"transactionType",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getTransactionType(rowData.item.transactionType))+" ")])]}},{key:"tokensAmount",fn:function(ref){
var rowData = ref.rowData;
return [_c('cp-ellipsis',{attrs:{"data-value":rowData.item.tokensAmount}})]}},{key:"price",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s((rowData.item.price && _vm.formatCurrency(rowData.item.price,_vm.issuerMainCurrencyIdentifier)) || '-'))])]}},{key:"transactionAmount",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s((rowData.item.transactionAmount && _vm.formatCurrency(rowData.item.transactionAmount,_vm.issuerMainCurrencyIdentifier)) || '-'))])]}},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"d-flex"},[_c('cp-button',{attrs:{"variant":"default btn-xs icon-btn md-btn-flat"},on:{"click":function($event){return _vm.showEditTokenTransactionModal(rowData.item)}}},[_c('i',{staticClass:"ion ion-md-create"})])],1)]}}])}),_c('cp-edit-token-transaction-modal',{ref:"CpEditTokenTransactionModal",attrs:{"token-transaction":_vm.editableTokenTransaction},on:{"tokenTransactionEdited":_vm.tokenTransactionEdited}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }