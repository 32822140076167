var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card mb-5"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-6"},[_c('h4',{staticClass:"mb-0 text-primary"},[_vm._v(" "+_vm._s(_vm.$t('investorDetails.documents.title'))+" ")])])])]),(_vm.isIssuerInfoLoaded)?_c('cp-table',{ref:"cpTable",attrs:{"without-search":"","get-data-action":"investors/getInvestorDocuments","url-params":_vm.queryParams,"fields":_vm.documentsFields},scopedSlots:_vm._u([{key:"image",fn:function(ref){
var rowData = ref.rowData;
return [(_vm.canViewEditDocuments)?_c('img',{staticClass:"ui-w-40 d-block link",staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.getImageIcon(rowData.item),"alt":"Document image"},on:{"click":function($event){return _vm.showPreviewDocModal(rowData.item)}}}):_c('i',{staticClass:"ion ion-ios-remove-circle-outline icon-resize"})]}},{key:"type",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm.$t(_vm.i18nKeys.types[rowData.item.type]))+" ")]}},{key:"documentFace",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm.$t(_vm.i18nKeys.faces[rowData.item.documentFace]))+" ")]}},{key:"createdAt",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm._f("dateFilter")(rowData.item.createdAt))+" ")]}},{key:"origin",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm.$t(_vm.i18nKeys.documentOrigin[rowData.item.origin]))+" ")]}},{key:"token",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm.getTokenName(rowData.item))+" ")]}},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"d-flex"},[_c('cp-button',{attrs:{"icon":"ios-arrow-round-down","variant":"outline-primary","disabled":_vm.isDownloadDisabled(rowData.item.id),"is-loading":_vm.downloadingDocuments[rowData.item.id]},on:{"click":function($event){return _vm.downloadDocument(rowData.item)}}},[_vm._v(" "+_vm._s(_vm.$t('investorDetails.documents.button.document.download'))+" ")]),(!_vm.canDeleteDocument(rowData.item.origin))?_c('div',{staticClass:"d-inline-block place-holder ml-3"}):_vm._e(),(_vm.canDeleteDocument(rowData.item.origin))?_c('cp-button',{attrs:{"variant":"default btn-xs icon-btn md-btn-flat ml-3"},on:{"click":function($event){return _vm.showDeleteModal(rowData.item)}}},[_c('i',{staticClass:"ion ion-ios-trash"})]):_vm._e()],1)]}}],null,false,703445465)},[_c('template',{slot:"documentTitle"})],2):_vm._e(),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_c('cp-button',{attrs:{"disabled":!_vm.canViewEditDocuments,"icon":"ios-add-circle-outline","variant":"outline-primary"},on:{"click":_vm.showAddDocModal}},[_vm._v(" "+_vm._s(_vm.$t('investorDetails.documents.button.document.add'))+" ")])],1)])]),(_vm.canViewEditDocuments)?_c('add-document-modal',{ref:"addDocModal",attrs:{"categories":_vm.categories,"tokens-list":_vm.issuerTokenNamesAndIds},on:{"docIsAdded":_vm.refreshTable}}):_vm._e(),(_vm.canViewEditDocuments)?_c('preview-document-modal',{ref:"previewDocModal",attrs:{"document-id":_vm.documentId,"origin":_vm.origin}}):_vm._e(),(_vm.canViewEditDocuments)?_c('cp-confirm-modal',{ref:"cpConfirmModal",attrs:{"title":_vm.$t('investorDetails.removeModal.title', [_vm.$t('investorDetails.documents.label.document')])},on:{"onOk":_vm.deleteDocument}},[_vm._v(" "+_vm._s(_vm.confirmModalText)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }