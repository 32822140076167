<template>
  <cp-general-modal
    ref="setAffiliate"
    :title="$t('investorDetails.setAffiliateModal.title')"
    :ok-disabled="disabled"
    @onOk="setAffiliate"
  >
    <div class="form-group row">
      <label class="col-form-label col-sm-2 text-sm-right">
        {{ $t('investorDetails.setAffiliateModal.input.date') }}
      </label>
      <div class="col-sm-10">
        <div class="form-control-plaintext">
          {{ getCurrentDate }}
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-sm-2 text-sm-right">
        {{ $t('investorDetails.setAffiliateModal.input.comment.text') }}
      </label>
      <div class="col-sm-10">
        <cp-text-area
          name="comment"
          :rows="3"
          :placeholder="$t('investorDetails.setAffiliateModal.input.comment.placeholder')"
          validate="required"
        />
      </div>
    </div>
  </cp-general-modal>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpTextArea from '~/components/common/standalone-components/inputs/cp-textarea';

export default {
  name: 'SetAffiliateModal',
  components: {
    CpGeneralModal,
    CpTextArea,
  },
  data() {
    return {
      disabled: false,
    };
  },
  computed: {
    getCurrentDate() {
      return moment().format('ll');
    },
  },
  methods: {
    ...mapActions('affiliateManagement', ['createHolderAffiliateRecords']),
    show() {
      this.$refs.setAffiliate.show();
    },
    setAffiliate(data) {
      this.disabled = true;

      this.createHolderAffiliateRecords({
        issuerId: this.$route.params.idIssuer,
        userId: this.$route.params.detailsId,
        body: { ...data, status: 'pending', tokenId: this.$route.params.tokenId },
      }).then(() => {
        this.$emit('affiliateIsSet');
        this.$refs.setAffiliate.hide();
      }).finally(() => {
        this.disabled = false;
      });
    },
  },
};
</script>
