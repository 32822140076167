<template>
  <div>
    <div class="card mb-5 fundraise-round">
      <cp-card-head
        :card-name="$t('investorDetails.legalSigners.title')"
        without-edit
        :button-title="$t('investorDetails.legalSigners.addLegalSignerButton')"
        button-icon="ios-add"
        :is-sec-id-kyc-provider="isSecIdKycProvider"
        :create-elem="showAddLegalSignerButton"
        @onCreateElem="showAddLegalSignerModal"
      />
      <cp-table
        ref="cpLegalSignersTable"
        without-pagination
        without-search
        get-data-action="investors/getLegalSignersList"
        :url-params="queryParams"
        :fields="legalSignerFields"
      >
        <template
          slot="name"
          slot-scope="{ rowData: {item}}"
        >
          <span>
            {{ getLegalSignerName(item) }}
          </span>
        </template>
        <template
          slot="taxId"
          slot-scope="{ rowData: {item}}"
        >
          <span>
            {{ getLegalSignerTaxId(item) }}
          </span>
        </template>
        <template
          slot="actions"
          slot-scope="{ rowData }"
        >
          <div class="d-flex">
            <cp-button
              variant="default btn-xs icon-btn md-btn-flat"
              @click="$emit('viewLegalSigner', rowData.item)"
            >
              <i :class="viewLegalSignerButtonIcon" />
            </cp-button>
            <cp-button
              v-if="showDeleteLegalSignerButton"
              variant="default btn-xs icon-btn md-btn-flat ml-3"
              @click="showDeleteLegalSignerModal(rowData.item)"
            >
              <i class="ion ion-ios-trash" />
            </cp-button>
          </div>

          <div class="d-flex" />
        </template>
      </cp-table>
      <cp-confirm-modal
        ref="cpConfirmDeleteLegalSignerModal"
        ok-text="Delete"
        title="Delete Legal Signer"
        @onOk="deleteLegalSignerByLegalSignerId"
      >
        {{ $t('investorDetails.legalSigners.confirmDeleteLegalSignerModal') }}
      </cp-confirm-modal>
      <cp-add-legal-signer-modal
        ref="cpAddLegalSignerModal"
        @legalSignerCreated="updateLegalSignerTableData"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CpCardHead from './card-head';
import CpTable from '~/components/shared/cp-table';
import CpButton from '~/components/common/standalone-components/cp-button';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';
import CpAddLegalSignerModal from './modals/add-legal-signer-modal';
import { legalSigners } from '../options';

export default {
  name: 'CpInvestorDetailsLegalSigners',
  components: {
    CpTable,
    CpCardHead,
    CpButton,
    CpConfirmModal,
    CpAddLegalSignerModal,
  },
  data() {
    return {
      legalSignerFields: legalSigners.legalSignerFields,
      queryParams: {
        issuerId: this.$route.params.idIssuer,
        userId: this.$route.params.detailsId,
      },
      selectedLegalSignerIdToDelete: null,
    };
  },
  computed: {
    ...mapGetters('investors', ['getDetailKyc']),
    ...mapGetters('issuersInfo', ['permissionsPerIssuer']),
    kycProvider() {
      return this.getDetailKyc.kycProvider;
    },
    canViewEditInvestorInfo() {
      return this.permissionsPerIssuer.canOperatorViewEditInvestorInfo;
    },
    showAddLegalSignerButton() {
      return !this.isSecIdKycProvider && this.canViewEditInvestorInfo;
    },
    showDeleteLegalSignerButton() {
      return !this.isSecIdKycProvider && this.canViewEditInvestorInfo;
    },
    isSecIdKycProvider() {
      return this.kycProvider === 'securitize-id';
    },
    viewLegalSignerButtonIcon() {
      return this.isSecIdKycProvider || !this.canViewEditInvestorInfo ? 'ion ion-ios-eye' : 'ion ion-md-create';
    },
  },
  watch: {
    kycProvider(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$refs.cpLegalSignersTable.updateTableData();
      }
    },
  },
  methods: {
    ...mapActions('investors', ['getLegalSignersList', 'deleteLegalSignerById']),
    showDeleteLegalSignerModal(item) {
      this.selectedLegalSignerIdToDelete = item.signerId || item.id;
      this.$refs.cpConfirmDeleteLegalSignerModal.show();
    },
    showAddLegalSignerModal() {
      this.$refs.cpAddLegalSignerModal.show();
    },
    deleteLegalSignerByLegalSignerId() {
      const params = { ...this.queryParams, legalSignerId: this.selectedLegalSignerIdToDelete };
      this.deleteLegalSignerById({ params })
        .then(() => {
          this.$refs.cpLegalSignersTable.updateTableData();
        });
    },
    isSignerTypeEntity(item) {
      return item.signerType === 'entity';
    },
    getLegalSignerName(item) {
      if (item.name) {
        return item.name;
      }
      if (this.isSignerTypeEntity(item)) {
        return item.legalName;
      }
      return `${item.individualName.firstName} ${item.individualName.lastName}`;
    },
    getLegalSignerTaxId(item) {
      return item.taxId;
    },
    updateLegalSignerTableData() {
      this.$refs.cpLegalSignersTable.updateTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
    .fundraise-round {
        .table {
            tr {
                td:nth-child(8), td:nth-child(9) {
                    white-space: nowrap;
                    max-width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        .card-header {
            border-bottom: none;
        }
    }
</style>
