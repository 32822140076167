<template>
  <cp-general-modal
    ref="transferTreasury"
    :title="$t('investorDetails.transferTreasuryModal.title')"
    title-icon="ion ion-ios-send"
    :default-value="initData"
    :ok-disabled="disabled"
    @onOk="handleSubmit"
  >
    {{ balance !== null? $t('investorDetails.transferTreasuryModal.amountToWithdraw',[balance]) : $t('investorDetails.transferTreasuryModal.loading') }}
    <cp-select
      v-if="walletsList.length"
      :options="getWalletsAddresses"
      name="walletId"
      :label="$t('investorDetails.transferTreasuryModal.input.issueTo')"
    />

    <cp-input
      v-else
      name="walletId"
      :label="$t('investorDetails.transferTreasuryModal.input.issueTo')"
    />

    <cp-input
      name="amount"
      :label="$t('investorDetails.transferTreasuryModal.amountToWithdrawInputLabel')"
      input-type="number"
      :validate="`required|larger_than_zero|max_value:${balance}`"
    />
  </cp-general-modal>
</template>

<script>
import { mapActions } from 'vuex';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';

export default {
  name: 'TransferTreasuryModal',
  components: {
    CpInput,
    CpGeneralModal,
    CpSelect,
  },
  props: {
    walletsList: {
      type: Array,
      default: () => [],
    },
    balance: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      disabled: false,
    };
  },
  computed: {
    initData() {
      return {
        walletId: this.walletsList[0] ? this.walletsList[0].id : '',
      };
    },
    getWalletsAddresses() {
      return this.walletsList.map(({ address, name, id }) => ({
        text: name || address,
        value: id,
      }));
    },
  },
  methods: {
    ...mapActions('investors', ['sendTreasuryToWallet']),
    show() {
      this.$refs.transferTreasury.show();
    },
    handleSubmit(props) {
      const { walletId, amount } = props;
      const { id: tokenWalletId, name: walletName } = this.walletsList.find(({ id }) => walletId === id);
      this.disabled = true;

      this.sendTreasuryToWallet({
        issuerId: this.$route.params.idIssuer,
        userId: this.$route.params.detailsId,
        tokenWalletId,
        walletName,
        amount,
      }).then(() => {
        this.$emit('transferredToTreasury');
        this.$refs.transferTreasury.hide();
      }).finally(() => {
        this.disabled = false;
      });
    },
  },
};
</script>
