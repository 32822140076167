<template>
  <div class="row mt-4">
    <div class="col-12">
      <div
        v-if="isEditable"
        class="card bg-light"
      >
        <div class="card-header">
          <h6 class="mb-0">
            {{ title }}
          </h6>
        </div>
        <div class="card-body">
          <div
            class="cp-comment-height"
          >
            <div class="form-group">
              <b-textarea
                v-validate.initial="'json'"
                :class="[ inputClass, { 'border border-danger': error || controlledError }]"
                :value="currentValue"
                :placeholder="$t('component.cpTextArea.label.placeholder')"
                :name="name"
                @input="handleInput"
              />
              <span
                v-if="error || controlledError"
                class="invalid-feedback d-block"
              >
                {{ error || controlledError }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="prettifiedValues"
        class="overflow-auto"
      >
        <hr class="my-0 mx-0">
        <div class="card-header bg-overwrite">
          <div class="row align-items-end">
            <div class="col-md-6 pr-0 pl-0">
              <h5 class="my-0 mt-4">
                {{ title }}
              </h5>
            </div>
          </div>
        </div>
        <div class="long-string-wrapper grid-table">
          <div
            v-for="(val, objName, index) in prettifiedValues"
            :key="objName+index"
          >
            <div class="inner-grid-table table-background">
              <span>
                {{ objName }}:
              </span>
              <span class="bold">
                {{ val }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';

export default {
  name: 'CpComments',
  components: {
  },
  props: {
    error: {
      type: String,
      default: '',
    },
    inputClass: {
      type: [String, Object, Array],
      default: 'html-content form-group',
    },
    title: {
      type: String,
      default: null,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      controlledValue: this.value,
      controlledError: '',
      lastValidJson: null,
    };
  },
  computed: {
    currentValue() {
      return this.value !== null ? this.value : this.controlledValue;
    },
    prettifiedValues() {
      let newValue;
      try {
        newValue = JSON.parse(this.value);
        const showUIJSON = { ...(newValue.showUI || {}), ...(newValue.paymentPreferences || {}), ...(newValue.dripPreferences || {}) };
        if (Array.isArray(newValue)) {
          newValue = newValue.reduce((reducer, value) => {
            if (value.showUI && isEmpty(reducer)) return value.showUI;
            return reducer;
          }, {});
          this.storeLastValidJson(newValue);
          return newValue;
        }
        this.storeLastValidJson(showUIJSON);
        return showUIJSON;
      } catch (e) {
        return this.lastValid();
      }
    },
  },
  methods: {
    handleInput(event) {
      if (event === '') event = null;
      this.$emit('input', event);
    },
    storeLastValidJson(validJson) {
      this.lastValidJson = validJson;
    },
    lastValid() {
      return this.lastValidJson;
    },
  },
};
</script>

<style lang="scss" scoped>
  .bg-overwrite{
   background-image: linear-gradient(#fafafb, #fefefe, #ffffff);
    margin-bottom: 20px;
  }
  .grid-table {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    row-gap: 10px;
    .inner-grid-table {
      display: grid;
      column-gap: 10px;
      row-gap: 10px;
      grid-template-columns: 1fr 1fr;
      .bold {
        font-weight: 900;
      }
      span {
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        padding: 10px;
      }
    }
  }
  .table-background {
    background-color: rgba(10, 23, 39, 0.025);
  }
  .cp-comment-height {
    .form-group {
      margin: 0;
      height: 100%;
      textarea {
        height: 100%;
      }
    }
  }
  .long-string-wrapper {
    position: relative;
    p {
      width:98%;
      margin: 0;
      text-overflow: ellipsis;
      cursor: pointer;
      word-break: break-all;
      overflow:hidden;
      white-space: nowrap;
    }
  }
</style>
