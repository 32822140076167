<template>
  <div>
    <cp-general-modal
      ref="issueModal"
      ok-title="Confirm transaction Edit"
      :title="$t('investorDetails.editIssuanceModal.title')"
      :default-value="issuanceDetail"
      :ok-disabled="disabled"
      @onOk="editIssuance"
    >
      <cp-input
        name="description"
        :label="$t('investorDetails.addIssuanceModal.input.reason.text')"
        :placeholder="
          $t('investorDetails.addIssuanceModal.input.reason.placeholder')
        "
      />
      <br>
      <cp-time-date-picker
        v-model="issuanceDetail.issuanceTime"
        name="issuanceTime"
        :label="$t('investorDetails.editIssuanceModal.input.issuanceTime')"
        validate="required"
      />
      <br>
      <cp-select
        v-if="isTransferAgentOperator"
        v-model="issuanceDetail.status"
        name="status"
        :disabled="isAllowToEdit"
        :label="$t('investorDetails.editIssuanceModal.input.status')"
        :options="generateStatusOptions"
      />
    </cp-general-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import CpGeneralModal from '~/components/common/modals-components/general-modal.vue';
import {
  CpInput,
  CpTimeDatePicker,
} from '~/components/common/standalone-components/inputs';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select.vue';
import { tokensWallets } from '../../options';
import { STATUSES_CONSTANTS } from '../token-issuance.constants';

export default {
  name: 'CpEditIssuanceModal',
  components: {
    CpInput,
    CpSelect,
    CpTimeDatePicker,
    CpGeneralModal,
  },
  props: {
    investorDetails: {
      type: Object,
      required: true,
    },
    issuanceDetail: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tokensWallets,
      STATUSES_CONSTANTS,
    };
  },
  computed: {
    ...mapGetters('currentOperator', ['operatorData']),
    isTransferAgentOperator() {
      const { authorizations } = this.operatorData;
      return authorizations.includes('transfer-agent');
    },
    isAllowToEdit() {
      return !([STATUSES_CONSTANTS.SUCCESS, STATUSES_CONSTANTS.AMENDED].includes(this.issuanceDetail.status));
    },
    generateStatusOptions() {
      if (([STATUSES_CONSTANTS.SUCCESS, STATUSES_CONSTANTS.AMENDED].includes(this.issuanceDetail.status))) {
        return this.tokensWallets.issuanceStatuses.filter(item => [STATUSES_CONSTANTS.SUCCESS, STATUSES_CONSTANTS.AMENDED].includes(item.value));
      }
      return this.tokensWallets.issuanceStatuses;
    },
  },
  methods: {
    ...mapActions('investors', ['editTokenIssuance']),
    show() {
      this.$refs.issueModal.show();
    },
    editIssuance(data) {
      const { idIssuer, detailsId } = this.$route.params;
      this.requestPayload = {
        issuanceId: data.id,
        issuerId: idIssuer,
        userId: detailsId,
        body: {
          description: data.description,
          issuanceTime: moment(data.issuanceTime).format(),
          status: data.status,
        },
      };
      this.editTokenIssuance(this.requestPayload)
        .then(() => {
          this.$emit('issuanceIsEdited');
        })
        .finally(() => {
          this.$refs.issueModal.hide();
        });
    },
  },
};
</script>
<style scoped>
.radio-section {
  border-top: 1px solid rgba(10, 23, 39, 0.06);
  border-bottom: 1px solid rgba(10, 23, 39, 0.06);
  padding: 18px 0 0 18px;
  margin-bottom: 18px;
}
.radio-row {
  padding-bottom: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.label-override {
  margin-bottom: 0;
  padding-right: 5px;
  flex-grow: 1;
  flex-basis: 25px;
}
.input-class-override {
  margin-bottom: 0;
  flex-grow: 1;
  flex-basis: 125px;
}
</style>
<style>
.input-class {
  margin-bottom: 0;
}
</style>
