<template>
  <div>
    <label v-if="label">{{ label }}</label>
    <cp-select
      v-model="currentRoundIdModel"
      :disabled="disabled"
      :options="roundNames"
      input-class="custom-select"
      @input="onRoundChange"
    />
  </div>
</template>

<script>
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';

export default {
  name: 'CpLocalSelectorRound',
  components: {
    CpSelect,
  },
  props: {
    controlledRoundId: {
      type: [String, Number],
      default: undefined,
    },
    roundNamesList: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentRoundIdModel: this.controlledRoundId || undefined,
      roundNames: this.roundNamesList,
      previousRoundId: this.controlledRoundId || undefined,
    };
  },
  watch: {
    controlledRoundId: {
      handler(newRoundId, oldRoundId) {
        if (newRoundId && newRoundId !== oldRoundId) {
          this.previousRoundId = newRoundId;
          this.currentRoundIdModel = newRoundId;
        }
        if (newRoundId === null) {
          this.previousRoundId = newRoundId;
          this.currentRoundIdModel = newRoundId;
        }
      },
    },
    roundNamesList: {
      handler(newRoundNames, oldRoundNames) {
        if (newRoundNames && newRoundNames !== oldRoundNames) {
          this.roundNames = newRoundNames;
        }
      },
    },
  },
  methods: {
    onRoundChange(selectedRoundId) {
      if (this.previousRoundId !== selectedRoundId) {
        this.previousRoundId = selectedRoundId;
        this.$emit('onRoundSelect', selectedRoundId);
      }
    },
  },
};
</script>
