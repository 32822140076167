<template>
  <span class="d-none d-sm-inline-block col-sm-8"><b>{{ investmentStatusSet }}</b></span>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  components: {
  },
  props: {
    hasInvestment: {
      type: Boolean,
      default: false,
    },
    roundId: {
      type: [String, Number],
      default: null,
    },
    investmentStatus: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      investmentOptions: [
        { i18nKey: 'investorDetails.investment.option.investmentStatus.none', value: 'none' },
        { i18nKey: 'investorDetails.investment.option.investmentStatus.inProgress', value: 'in-progress' },
        { i18nKey: 'investorDetails.investment.option.investmentStatus.pending', value: 'pending' },
        { i18nKey: 'investorDetails.investment.option.investmentStatus.confirmed', value: 'confirmed' },
        { i18nKey: 'investorDetails.investment.option.investmentStatus.na', value: 'na' },
      ],
      disabled: false,
    };
  },
  computed: {
    investmentStatusSet() {
      const investmentKey = !this.roundId ? 'na' : this.investmentStatus || 'none';
      const investmentI18n = this.investmentOptions.find(t => t.value === investmentKey);
      return this.$t(investmentI18n.i18nKey);
    },
  },
  methods: {
    ...mapActions('investors', ['updateInvestorInvestmentById']),
    onChangeHandle(selectedStatus) {
      if (this.investmentStatus !== selectedStatus) {
        this.disabled = true;

        this.updateInvestorInvestmentById({
          issuerId: this.$route.params.idIssuer,
          userId: this.$route.params.detailsId,
          roundId: this.roundId,
          body: {
            status: selectedStatus,
          },
        }).finally(() => {
          this.disabled = false;
        });
      }
    },
  },
};
</script>
