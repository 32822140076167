<template>
  <div>
    <div class="card mb-5">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-md-6">
            <h4 class="mb-0 text-primary">
              {{ $t('investorDetails.kyc.title', [stockExchangeRegulation]) }}
            </h4>
          </div>
          <cp-edit-buttons
            :edit-mode="isDetailsEditableCurrentBlock"
            :disable-edit="isEditButtonDisabled"
            @changeMode="editModeControl"
            @update="save"
          />
        </div>
      </div>
      <div class="card-body">
        <b-alert
          variant="danger"
          :show="infoMissing && getMissingFields"
        >
          {{ getMissingFields }}
        </b-alert>
        <div class="d-flex align-items-center justify-content-between flex-wrap">
          <div class="status-current">
            <div class="text-muted small">
              {{ $t('investorDetails.kyc.label.currentRegulationStatus', [stockExchangeRegulation]) }}
            </div>
            <cp-editable-input
              v-model="model.kycStatus"
              :edit-mode="isDetailsEditableCurrentBlock"
              :text="kycStatusName"
              :options="kycStatuses"
              :text-class="getStatusColor"
              @change="onChangeStatus"
            />
          </div>
          <div class="my-1 ml-2">
            <img
              v-if="model.registrationSource === 'OFN'"
              class="registration-source-image"
              src="./logo-openfinance.png"
              :title="model.registrationSource"
              alt="open-finance"
            >
          </div>
          <div class="col-md-5 my-1">
            <div class="progress">
              <div
                :class="getStatusBarColor"
                :style="{ width: getProgressBarStatus + '%', }"
              >
                {{ getProgressBarStatus }}%
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="d-flex">
          <div class="d-flex justify-content-between align-items-center  w-50 p-3 pr-lg-5 bg-grey">
            <span>
              {{ $t('investorDetails.kyc.label.lastRegulationChange', [stockExchangeRegulation]) }}
            </span>
            <span>
              <strong>
                {{ getDetailKyc.kycStatusChangingDate | dateFilter }}
              </strong>
            </span>
          </div>
          <div class="mx-3" />
          <div class="d-flex justify-content-between align-items-center  w-50 p-3 pr-lg-5 bg-grey">
            <span>
              {{ $t('investorDetails.kyc.label.expirationDate', [stockExchangeRegulation]) }}
            </span>
            <span>
              <strong>
                {{ getDetailKyc.kycStatusExpiryDate | dateFilter }}
              </strong>
            </span>
          </div>
        </div>
        <div class="d-flex mt-3">
          <div class="d-flex justify-content-between align-items-center  w-50 p-3 pr-lg-5 bg-grey">
            <span>
              {{ $t('investorDetails.kyc.label.transactionId', [stockExchangeRegulation]) }}
            </span>
            <span>
              {{ model.onfidoApplicantId || '-' }}
            </span>
          </div>
          <div class="mx-3" />
          <div class="d-flex justify-content-between align-items-center  w-50 p-3 pr-lg-5 bg-grey">
            <span>
              {{ $t('investorDetails.kyc.label.kycProvider', [stockExchangeRegulation]) }}
            </span>
            <strong>
              {{ $t(`investorDetails.kyc.option.kycProvider['${getDetailKyc.kycProvider}']`) || '-' }}
            </strong>
          </div>
        </div>
        <hr>

        <div class="d-flex align-items-center justify-content-between flex-wrap">
          <div class="status-current">
            <div class="text-muted small">
              {{ $t('investorDetails.kyc.label.currentAccreditationStatus') }}
            </div>
            <b-select
              v-if="isDetailsEditableCurrentBlock"
              v-model="model.accreditedStatus"
              :options="getKycAccreditation()"
            />
            <span
              v-else
              :class="getAccreditationStatusColor"
            >
              {{ kycAccreditationStatusName }}
            </span>
          </div>
          <span
            v-b-tooltip="getTooltipText"
            class="d-inline-block"
            tabindex="0"
          >
            <cp-button
              variant="outline-primary"
              icon="ios-finger-print"
              :is-loading="kycReportWorking"
              :disabled="isFboInvestor"
              @click="onAccreditationEmail"
            >
              {{ $t('investorDetails.kyc.button.sendAccreditationEmail') }}
            </cp-button>
          </span>
        </div>
        <hr>
        <div class="d-flex">
          <div class="d-flex justify-content-between align-items-center  w-50 p-3 pr-lg-5 bg-grey">
            <span>
              {{ $t('investorDetails.kyc.label.lastAccreditationChange') }}
            </span>
            <span>
              <strong>
                {{ getDetailKyc.accreditedDeclarationDate | dateFilter }}
              </strong>
            </span>
          </div>
          <div class="mx-3" />
          <div class="d-flex justify-content-between align-items-center  w-50 p-3 pr-lg-5 bg-grey">
            <span>
              {{ $t('investorDetails.kyc.label.accreditedExpiryDate') }}
            </span>
            <span>
              <strong>
                {{ getDetailKyc.accreditedExpiryDate | dateFilter }}
              </strong>
            </span>
          </div>
        </div>

        <hr>

        <div class="d-flex align-items-center justify-content-between flex-wrap">
          <div class="status-current">
            <div class="text-muted small">
              {{ $t('investorDetails.kyc.label.qualificationStatusFor', [currentToken.symbol || currentToken.name]) }}
            </div>
            <b-select
              v-if="isDetailsEditableCurrentBlock"
              v-model="model.userTokenQualificationStatus"
              :options="getKycTokenQualificationStatus()"
            />
            <span
              v-else
              :class="getUserTokenQualificationStatusColor"
            >
              {{ userTokenQualificationStatusName }}
            </span>
          </div>
        </div>
        <cp-comments
          v-if="getDetailKyc.kycComments || isDetailsEditableCurrentBlock"
          v-model="model.kycComments"
          custom-height="200px"
          :is-editable="isDetailsEditableCurrentBlock"
          :comments-array="commentsArray"
        />
      </div>
    </div>

    <!--View KYC Report modal-->
    <b-modal
      v-if="showByAuthorizationLevel"
      v-model="previewOpen"
      size="lg"
      class="cp-preview-modal"
      ok-only
      centered
    >
      <div
        slot="modal-header"
        style="width:100%; text-align: left"
      >
        <h5 class="modal-title d-flex justify-content-between">
          {{ $t('investorDetails.generalPropertiesModal.title') }}
          <cp-button
            v-if="getDetailKyc.hasReport && previewHtml"
            variant="outline-primary m-0"
            icon="ios-finger-print"
            :is-loading="kycReportWorking"
            @click="KYCReport"
          >
            {{ $t('investorDetails.generalPropertiesModal.button.download', [stockExchangeRegulation]) }}
          </cp-button>
        </h5>
      </div>
      <iframe
        id="preview-kyc-iframe"
        :style="previewHtml ? 'height:700px;width:100%;' : 'height:700px;width:100%;display:none'"
        frameborder="0"
      />
      <div class="d-flex justify-content-center align-items-center">
        <Spinner
          v-if="!previewHtml"
          name="wave"
          color="#04bec4"
          fade-in="quarter"
        />
      </div>
    </b-modal>
    <cp-confirm-modal
      ref="confirmKycStatusModal"
      :item="{kycStatus: model.kycStatus}"
      :title="$t('investorDetails.confirmKycStatusModal.title')"
      @onOk="onConfirmChangeKycStatus"
      @onCancel="onCancelChangeKycStatus"
    >
      {{ $t('investorDetails.confirmKycStatusModal.text') }}
    </cp-confirm-modal>
  </div>
</template>

<script>
import Spinner from 'vue-spinkit';
import ClickOutside from 'vue-click-outside';

import {
  mapGetters, mapActions, mapMutations, mapState,
} from 'vuex';
import { kyc } from './options';

import CpEditButtons from '~/components/common/edit-buttons';
import CpButton from '~/components/common/standalone-components/cp-button';
import CpComments from '~/components/common/standalone-components/cp-comments';
import CpEditableInput from '~/components/common/editable-input';

import CpEditableMixin from '~/mixins/editable-invester-details-component';
import CpValidationFields from '~/mixins/validation-fields';
import { i18nKeyListConvert } from '~/utilities/i18n-util';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';

export default {
  name: 'CpInvestorDetailsKyc',
  components: {
    Spinner,
    CpEditableInput,
    CpEditButtons,
    CpButton,
    CpComments,
    CpConfirmModal,
  },
  directives: {
    ClickOutside,
  },
  mixins: [
    CpEditableMixin('kyc'),
    CpValidationFields,
  ],
  data() {
    return {
      model: {},
      previewOpen: false,
      previewHtml: false,
      gettingReport: false,
      kycCheckWorking: false,
      kycEmailWorking: false,
      infoMissing: false,
      frontDoc: false,
      updateProcess: false,
      kycReportWorking: false,
      kycStatuses: kyc.statuses,
      initialState: {},
      kycAccreditation: kyc.accreditation,
      kycTokenQualification: kyc.tokenQualification,
      generalFields: {
        gender: this.$t('investorDetails.kyc.field.general.gender'),
        birthdate: this.$t('investorDetails.kyc.field.general.birthdate'),
        address1: this.$t('investorDetails.kyc.field.general.address1'),
        zipCode: this.$t('investorDetails.kyc.field.general.zipCode'),
        city: this.$t('investorDetails.kyc.field.general.city'),
      },
      queryParams: {
        issuerId: this.$route.params.idIssuer,
        userId: this.$route.params.detailsId,
      },
    };
  },
  computed: {
    ...mapGetters('investors', ['getDetailKyc', 'getDetailGeneral']),
    ...mapState('currentOperator', ['authorizationLevel']),
    ...mapState('issuersInfo', ['currentToken']),
    noneStatus() {
      return this.getDetailKyc.kycStatus === 'none';
    },
    stockExchangeRegulation() {
      const entity = ['entity', 'fbo-entity'].includes(this.getDetailGeneral.investorType);
      return entity ? 'KYB' : 'KYC';
    },
    showByAuthorizationLevel() {
      return this.authorizationLevel !== 'brokerdealer';
    },
    getMissingFields() {
      const fields = this.errorFields.map(val => this.generalFields[val]);
      if (!this.frontDoc) {
        fields.push(this.$t('investorDetails.kyc.message.documents'));
      }
      return fields.length ? this.$t('investorDetails.kyc.message.missingField', [fields.join(', ')]) : null;
    },
    getTransactionId() {
      return this.getDetailKyc.onfidoApplicantId || '-';
    },
    commentsArray() {
      return (this.getDetailKyc.kycComments || '').split('\n');
    },
    withoutKYC() {
      const { kycStatus } = this.getDetailKyc;
      return (kycStatus !== 'none' && kycStatus !== 'processing');
    },
    getAccreditationStatusColor() {
      const { accreditedStatus } = this.getDetailKyc;
      const colors = {
        pending: 'warning',
        confirmed: 'success',
        rejected: 'danger',
        expired: 'danger',
      };


      return `badge badge-outline-${colors[accreditedStatus] || 'dark'}`;
    },

    getUserTokenQualificationStatusColor() {
      const { userTokenQualificationStatus } = this.getDetailKyc;
      const colors = {
        pending: 'warning',
        confirmed: 'success',
        rejected: 'danger',
        expired: 'danger',
      };
      return `badge badge-outline-${colors[userTokenQualificationStatus] || 'dark'}`;
    },

    getStatusColor() {
      let statusColor = 'badge-outline-danger';
      switch (this.getDetailKyc.kycStatus) {
        case 'processing':
          statusColor = 'badge-outline-dark';
          break;
        case 'verified':
          statusColor = 'badge-outline-success';
          break;
        case 'updates-required':
        case 'manual-review':
        case 'rejected':
        case 'expired':
        default:
      }
      return `badge ${statusColor}`;
    },
    getStatusBarColor() {
      let statusColor = 'bg-danger';
      switch (this.getDetailKyc.kycStatus) {
        case 'processing':
        case 'updates-required':
        case 'manual-review':
          statusColor = 'bg-warning';
          break;
        case 'verified':
          statusColor = 'bg-success';
          break;
        case 'rejected':
        case 'expired':
        default:
      }
      return `progress-bar ${statusColor}`;
    },
    kycStatusName() {
      const kycStatus = this.kycStatuses.find(
        ({ value }) => value === this.getDetailKyc.kycStatus,
      );
      return kycStatus ? this.$t(kycStatus.i18nKey) : '-';
    },
    kycAccreditationStatusName() {
      const kycAccreditation = this.kycAccreditation.find(
        ({ value }) => value === this.getDetailKyc.accreditedStatus,
      );
      return kycAccreditation ? this.$t(kycAccreditation.i18nKey) : '-';
    },
    userTokenQualificationStatusName() {
      const kycAccreditation = this.kycAccreditation.find(
          ({ value }) => value === this.getDetailKyc.userTokenQualificationStatus,
      );
      return kycAccreditation ? this.$t(kycAccreditation.i18nKey) : '-';
    },
    getProgressBarStatus() {
      switch (this.getDetailKyc.kycStatus) {
        case 'processing':
        case 'updates-required':
          return 50;
        case 'manual-review':
          return 80;
        case 'verified':
        case 'rejected':
        case 'expired':
          return 100;
        default:
          return 0;
      }
    },
    isFboInvestor() {
      return ['fbo-individual', 'fbo-entity'].includes(this.getDetailGeneral.investorType);
    },
    getTooltipText() {
      return this.isFboInvestor ? this.$t('investorDetails.kyc.button.fboTooltipText') : '';
    },
  },
  created() {
    this.changeMode(true);
    this.initialState = { ...this.getDetailKyc };
    this.model = { ...this.getDetailKyc };
  },
  methods: {
    ...mapActions('investors', [
      'checkInvestorDetailPerformManualKYC',
      'getInvestorKYCLastReportById',
      'updateKYCDetails',
      'sendAccreditationEmail',
      'getInvestorKYCStatusById',
    ]),
    ...mapActions('currentOperator', ['getNotifications']),
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    hideError() {
      this.infoMissing = false;
    },
    editModeControl(ect) {
      this.changeMode(ect);
      this.model = { ...this.getDetailKyc };
    },
    updateKycData() {
      this.getInvestorKYCStatusById({ ...this.queryParams, params: { tokenId: this.$route.params.tokenId } }).finally(() => {
        this.model = { ...this.getDetailKyc };
      });
    },
    KYCReport() {
      this.kycReportWorking = true;
      this.getInvestorKYCLastReportById(this.queryParams).finally(() => {
        this.kycReportWorking = false;
      });
    },
    onAccreditationEmail() {
      this.kycReportWorking = true;
      this.sendAccreditationEmail(this.queryParams).finally(() => {
        this.kycReportWorking = false;
      });
    },
    save() {
      if (this.updateProcess) {
        return;
      }
      this.updateProcess = true;

      this.updateKYCDetails({
        ...this.queryParams,
        body: { ...this.model },
        params: { tokenId: this.$route.params.tokenId, changedByOperator: true },
      }).then(() => {
        this.changeMode();
      }).finally(() => {
        this.updateProcess = false;
        if (this.showByAuthorizationLevel) this.getNotifications(this.queryParams.issuerId);
      });
    },
    getKycAccreditation() {
      return i18nKeyListConvert(this.kycAccreditation, 'text');
    },
    getKycTokenQualificationStatus() {
      return i18nKeyListConvert(this.kycTokenQualification, 'text');
    },
    onConfirmChangeKycStatus() {
      return false;
    },
    onCancelChangeKycStatus() {
      this.model.kycStatus = this.initialState.kycStatus;
    },
    onChangeStatus() {
      if (this.initialState.kycProvider === 'securitize-id' || this.getDetailGeneral.securitizeIdProfileId) {
        this.$refs.confirmKycStatusModal.show();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .cp-preview-modal {
    text-align: center;

    iframe {
      width: 100%;
      height: 700px;
    }
  }

  .bg-grey {
    background-color: rgba(10, 23, 39, 0.025)
  }

  .registration-source-image {
    max-height: 30px;
  }

  .status-current {
    min-width: 15%;
  }
</style>
