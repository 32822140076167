<template>
  <div class="card mb-5">
    <div class="card-header">
      <div class="row align-items-center mt-4">
        <div class="col-8">
          <h5 class="my-0">
            {{ $t('investorDetails.transactionHistory.title') }}
          </h5>
        </div>
      </div>
    </div>
    <cp-table
      ref="cpTransactionHistoryTable"
      without-search
      get-data-action="investors/getInvestorTransactionHistory"
      :url-params="queryParams"
      :fields="transactionHistoryFields"
    >
      <template
        slot="transactionDate"
        slot-scope="{ rowData }"
      >
        <span>
          {{
            formatTransactionDate(rowData.item.transactionDate)
          }} <br>
        </span>
      </template>
      <template
        slot="transactionType"
        slot-scope="{ rowData }"
      >
        <span>
          {{ getTransactionType(rowData.item.transactionType) }}
        </span>
      </template>
      <template
        slot="tokensAmount"
        slot-scope="{ rowData }"
      >
        <cp-ellipsis :data-value="rowData.item.tokensAmount" />
      </template>
      <template
        slot="price"
        slot-scope="{ rowData }"
      >
        <span>{{
          (rowData.item.price &&
            formatCurrency(rowData.item.price,issuerMainCurrencyIdentifier)) ||
            '-'
        }}</span>
      </template>
      <template
        slot="transactionAmount"
        slot-scope="{ rowData }"
      >
        <span>{{
          (rowData.item.transactionAmount &&
            formatCurrency(rowData.item.transactionAmount,issuerMainCurrencyIdentifier)) ||
            '-'
        }}</span>
      </template>

      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <div class="d-flex">
          <cp-button
            variant="default btn-xs icon-btn md-btn-flat"
            @click="showEditTokenTransactionModal(rowData.item)"
          >
            <i class="ion ion-md-create" />
          </cp-button>
        </div>
      </template>
    </cp-table>
    <cp-edit-token-transaction-modal
      ref="CpEditTokenTransactionModal"
      :token-transaction="editableTokenTransaction"
      @tokenTransactionEdited="tokenTransactionEdited"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { transactionHistory } from './options';
import { formatDate } from '@/utilities/date-utils';
import CpTable from '~/components/shared/cp-table';
import CpEllipsis from '~/components/common/ellipse';
import CpButton from '~/components/common/standalone-components/cp-button';
import CpEditTokenTransactionModal from './modals/edit-token-transaction-modal';
import CpCurrencyFormatter from '~/mixins/currency-formatter';

export default {
  name: 'CpInvestorDetailsTransactionHistory',
  components: {
    CpTable,
    CpEllipsis,
    CpButton,
    CpEditTokenTransactionModal,
  },
  mixins: [
    CpCurrencyFormatter,
  ],
  data() {
    return {
      transactionHistoryFields: transactionHistory.fields,
      editableTokenTransaction: {},
      queryParams: {
        tokenId: this.$route.params.tokenId,
        issuerId: this.$route.params.idIssuer,
        userId: this.$route.params.detailsId,
      },
    };
  },
  computed: {
    ...mapState('global', ['issuerMainCurrencyIdentifier']),
  },
  watch: {
    $route({ params: { tokenId: newTokenId } }, { params: { tokenId: oldTokenId } }) {
      if (newTokenId !== oldTokenId) this.queryParams.tokenId = newTokenId;
    },
  },
  methods: {
    ...mapActions('investors', ['getInvestorTransactionHistory']),
    getTransactionType(type) {
      const lowerCaseType = type && type.toLowerCase();
      const i18nKey = transactionHistory.transactionTypes[lowerCaseType];
      return this.$t(i18nKey) || 'testType';
    },
    formatTransactionDate(date) {
      return formatDate(date);
    },
    showEditTokenTransactionModal(item) {
      this.editableTokenTransaction = item;
      this.$refs.CpEditTokenTransactionModal.show();
    },
    tokenTransactionEdited() {
      this.$refs.cpTransactionHistoryTable.updateTableData();
    },
  },
};
</script>
