<template>
  <cp-general-modal
    ref="editDepositWallet"
    :title="$t('investorDetails.editDepositWalletModal.title')"
    :default-value="wallet"
    :ok-disabled="disabled"
    @onOk="handleSubmit"
  >
    <cp-text-area
      name="investorData"
      :label="$t('investorDetails.editDepositWalletModal.input.investorData.text')"
      :rows="3"
      :placeholder="$t('investorDetails.editDepositWalletModal.input.investorData.placeholder')"
    />
  </cp-general-modal>
</template>

<script>
import { mapActions } from 'vuex';
import CpTextArea from '~/components/common/standalone-components/inputs/cp-textarea';
import CpGeneralModal from '~/components/common/modals-components/general-modal';

export default {
  name: 'EditDepositWallet',
  components: {
    CpTextArea,
    CpGeneralModal,
  },
  props: {
    wallet: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      disabled: false,
      queryParams: {
        issuerId: this.$route.params.idIssuer,
        userId: this.$route.params.detailsId,
      },
    };
  },
  methods: {
    ...mapActions('investors', ['updateDepositWalletById']),
    show() {
      this.$refs.editDepositWallet.show();
    },
    handleSubmit({ investorData }) {
      this.disabled = true;
      this.updateDepositWalletById({
        ...this.queryParams,
        walletId: this.wallet.id,
        body: { investorData, tokenId: this.wallet.tokenId },
      }).then(() => {
        this.$emit('depositWalletIsUpdated');
      }).finally(() => {
        this.disabled = false;
        this.$refs.editDepositWallet.hide();
      });
    },
  },
};
</script>
