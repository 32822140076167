<template>
  <div class="card mb-5">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h4 class="mb-0 text-primary">
            {{ $t('investorDetails.general.title') }}
          </h4>
        </div>
        <cp-edit-buttons
          :edit-mode="isDetailsEditableCurrentBlock"
          :disable-edit="disableGeneralInfoEdit"
          :tooltip-message="this.$t('investorDetails.tooltip.edit.message')"
          :show-tooltip="disableGeneralInfoEdit"
          @changeMode="changeModeProxy"
          @update="onUpdateSidPIIDetails"
        />
      </div>
    </div>
    <div class="card-body">
      <cp-input-container
        ref="cpInputContainer"
        v-model="model"
        :default-value="getDetailGeneral"
      >
        <div class="mb-4 bg-light full-width">
          <div :class="['col-md-6', 'd-flex', 'investor-type-field', {'justify-content-between': isDetailsEditableCurrentBlock}]">
            <span>{{ $t('investorDetails.general.input.investorType') }}</span>
            <cp-select
              v-if="isDetailsEditableCurrentBlock && canEditInvestorType"
              key="investorType"
              :value="model.investorType"
              :options="getInvestorTypeOptions"
              name="investorType"
              @input="onChangeInvestorType"
            />
            <strong
              v-else
              class="investor-type-value"
            >
              {{ investorTypeField }}
            </strong>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <table class="table table-striped table-borderless mb-0">
              <tbody>
                <tr v-if="isIndividual">
                  <td>
                    {{ $t('investorDetails.general.input.firstName') }}
                  </td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="firstName"
                      name="firstName"
                    />
                    <strong v-else>
                      {{ getDetailGeneral.firstName | nullToMinus }}
                    </strong>
                  </td>
                </tr>
                <tr v-if="isIndividual">
                  <td>{{ $t('investorDetails.general.input.middleName') }}</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="middleName"
                      name="middleName"
                    />
                    <strong v-else>
                      {{ getDetailGeneral.middleName | nullToMinus }}
                    </strong>
                  </td>
                </tr>
                <tr v-if="isIndividual">
                  <td>
                    {{ $t('investorDetails.general.input.lastName') }}
                  </td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="lastName"
                      name="lastName"
                    />
                    <strong v-else>
                      {{ getDetailGeneral.lastName | nullToMinus }}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ $t('investorDetails.general.input.email') }}
                    <span
                      v-if="isDetailsEditableCurrentBlock"
                      class="text-danger text-sm-left"
                    >*</span>
                  </td>
                  <td class="text-break">
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="email"
                      name="email"
                      input-type="email"
                      validate="required|email"
                      :disabled="isFboInvestor"
                    />
                    <strong v-else>
                      {{ getDetailGeneral.email }}
                    </strong>
                  </td>
                </tr>
                <tr v-if="isFboInvestor">
                  <td>
                    {{ $t('investorDetails.general.input.fboEmail') }}
                    <span
                      v-if="isDetailsEditableCurrentBlock"
                      class="text-danger text-sm-left"
                    >*</span>
                  </td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="fboEmail"
                      name="fboEmail"
                      input-type="email"
                      validate="required|email"
                    />
                    <strong v-else>
                      {{ getDetailGeneral.fboEmail }}
                    </strong>
                  </td>
                </tr>
                <tr v-if="isIndividual">
                  <td>{{ $t('investorDetails.general.input.phonePrefix') }}</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="phonePrefix"
                      name="phonePrefix"
                      :disabled="!canOperatorViewEditInvestorInfo"
                    />
                    <strong v-else>
                      {{ getDetailGeneral.phonePrefix | nullToMinus }}
                    </strong>
                  </td>
                </tr>
                <tr v-if="isIndividual">
                  <td>{{ $t('investorDetails.general.input.phone') }}</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="phoneNumber"
                      name="phoneNumber"
                      :disabled="!canOperatorViewEditInvestorInfo"
                      validate="phone"
                    />
                    <strong v-else>
                      {{ getDetailGeneral.phoneNumber | nullToMinus }}
                    </strong>
                  </td>
                </tr>
                <tr v-if="isIndividual">
                  <td>
                    {{ $t('investorDetails.general.input.birthday') }}
                  </td>
                  <td>
                    <cp-date-picker
                      v-if="isDetailsEditableCurrentBlock"
                      key="birthdate"
                      v-model="model.birthdate"
                      name="birthdate"
                      :disabled="!canOperatorViewEditInvestorInfo"
                      initial-view="year"
                      :open-date="openDate"
                      :disabled-dates="disabledDates"
                    />
                    <strong v-else-if="canOperatorViewEditInvestorInfo">
                      {{ getDetailGeneral.birthdate | dateFilter }}
                    </strong>
                    <strong v-else-if="!canOperatorViewEditInvestorInfo">
                      {{ getDetailGeneral.birthdate }}
                    </strong>
                  </td>
                </tr>
                <tr v-if="isIndividual">
                  <td>
                    {{ $t('investorDetails.general.input.gender') }}
                  </td>
                  <td>
                    <cp-select
                      v-if="isDetailsEditableCurrentBlock"
                      key="gender"
                      :options="general.gender"
                      name="gender"
                    />
                    <strong v-else>
                      {{ getGender }}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('investorDetails.general.input.custodian') }}</td>
                  <td>
                    <cp-select
                      v-if="isDetailsEditableCurrentBlock && canEditCustodian"
                      :disabled="!canOperatorViewEditInvestorInfo || disableCustodian"
                      :options="getCustodianOptions"
                      :value="getCustodianId"
                      :validate="{ required: isCustodian }"
                      name="custodianId"
                    />
                    <strong v-else>
                      {{ getCustodianName }}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('investorDetails.general.input.address') }}</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      :disabled="!canOperatorViewEditInvestorInfo"
                      name="address1"
                    />
                    <strong v-else>
                      {{ getDetailGeneral.address1 | nullToMinus }}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('investorDetails.general.input.additionalInfo') }}</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="additionalInfo"
                      :disabled="!canOperatorViewEditInvestorInfo"
                      name="additionalInfo"
                    />
                    <strong v-else>
                      {{ getDetailGeneral.additionalInfo | nullToMinus }}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('investorDetails.general.input.postalCode') }}</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="zipCode"
                      name="zipCode"
                      :disabled="!canOperatorViewEditInvestorInfo"
                      validate="max:10"
                    />
                    <strong v-else>
                      {{ getDetailGeneral.zipCode | nullToMinus }}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('investorDetails.general.input.city') }}</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="city"
                      :disabled="!canOperatorViewEditInvestorInfo"
                      name="city"
                    />
                    <strong v-else>
                      {{ getDetailGeneral.city | nullToMinus }}
                    </strong>
                  </td>
                </tr>
                <tr v-if="isEntity">
                  <td>{{ $t('investorDetails.general.input.entityName') }}</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="entityName"
                      name="entityName"
                      :disabled="!canOperatorViewEditInvestorInfo"
                    />
                    <strong v-else>
                      {{ getDetailGeneral.entityName | nullToMinus }}
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-6">
            <table class="table table-striped table-borderless mb-0">
              <tbody>
                <tr>
                  <td>
                    {{ $t('investorDetails.general.input.country') }}
                    <span
                      v-if="isDetailsEditableCurrentBlock"
                      class="text-danger text-sm-left"
                    >*</span>
                  </td>
                  <td>
                    <cp-select
                      v-if="isDetailsEditableCurrentBlock"
                      key="countryCode"
                      :options="countryOptions"
                      name="countryCode"
                      validate="required"
                    />
                    <strong v-else>
                      <cp-country-name
                        :country-code="getDetailGeneral.countryCode"
                        :locale="locale"
                      />
                    </strong>
                  </td>
                </tr>
                <tr v-if="model.countryCode === 'US'">
                  <td>
                    {{ $t('investorDetails.general.input.state') }}
                    <span
                      v-if="isDetailsEditableCurrentBlock"
                      class="text-danger text-sm-left"
                    >*</span>
                  </td>
                  <td>
                    <cp-select
                      v-if="isDetailsEditableCurrentBlock"
                      key="state"
                      :options="stateOptions"
                      name="state"
                      validate="required"
                    />
                    <strong v-else>
                      {{ generalDetailState | nullToMinus }}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('investorDetails.general.input.taxId') }}</td>

                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="taxId"
                      :disabled="!canOperatorViewEditInvestorInfo"
                      name="taxId"
                    />
                    <strong v-else>
                      {{ getDetailGeneral.taxId | nullToMinus }}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('investorDetails.general.input.taxCountryCode') }}</td>
                  <td>
                    <cp-select
                      v-if="isDetailsEditableCurrentBlock"
                      key="taxCountryCode"
                      :disabled="!canOperatorViewEditInvestorInfo"
                      placeholder
                      :options="taxCountryOptions"
                      name="taxCountryCode"
                    />
                    <strong v-else-if="canOperatorViewEditInvestorInfo">
                      <cp-country-name
                        :country-code="getDetailGeneral.taxCountryCode"
                        :locale="locale"
                      />
                    </strong>
                    <strong v-else-if="!canOperatorViewEditInvestorInfo">
                      {{ getDetailGeneral.taxCountryCode }}
                    </strong>
                  </td>
                </tr>
                <tr v-if="isIndividual">
                  <td>{{ $t('investorDetails.general.input.taxId2') }}</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="taxId2"
                      :disabled="!canOperatorViewEditInvestorInfo"
                      name="taxId2"
                    />
                    <strong v-else>
                      {{ getDetailGeneral.taxId2 | nullToMinus }}
                    </strong>
                  </td>
                </tr>
                <tr v-if="isIndividual">
                  <td>{{ $t('investorDetails.general.input.taxCountryCode2') }}</td>
                  <td>
                    <cp-select
                      v-if="isDetailsEditableCurrentBlock"
                      key="taxCountryCode2"
                      :disabled="!canOperatorViewEditInvestorInfo"
                      placeholder
                      :options="taxCountryOptions"
                      :value="getDetailGeneral.taxCountryCode2"
                      name="taxCountryCode2"
                    />
                    <strong v-else-if="canOperatorViewEditInvestorInfo">
                      <cp-country-name
                        :country-code="getDetailGeneral.taxCountryCode2"
                        :locale="locale"
                      />
                    </strong>
                    <strong v-else-if="!canOperatorViewEditInvestorInfo">
                      {{ getDetailGeneral.taxCountryCode2 | nullToMinus }}
                    </strong>
                  </td>
                </tr>
                <tr v-if="isIndividual">
                  <td>{{ $t('investorDetails.general.input.taxId3') }}</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="taxId3"
                      :disabled="!canOperatorViewEditInvestorInfo"
                      name="taxId3"
                    />
                    <strong v-else>
                      {{ getDetailGeneral.taxId3 | nullToMinus }}
                    </strong>
                  </td>
                </tr>
                <tr v-if="isIndividual">
                  <td>{{ $t('investorDetails.general.input.taxCountryCode3') }}</td>
                  <td>
                    <cp-select
                      v-if="isDetailsEditableCurrentBlock"
                      key="taxCountryCode3"
                      :disabled="!canOperatorViewEditInvestorInfo"
                      placeholder
                      :options="taxCountryOptions"
                      :value="getDetailGeneral.taxCountryCode3"
                      name="taxCountryCode3"
                    />
                    <strong v-else-if="canOperatorViewEditInvestorInfo">
                      <cp-country-name
                        :country-code="getDetailGeneral.taxCountryCode3"
                        :locale="locale"
                      />
                    </strong>
                    <strong v-else-if="!canOperatorViewEditInvestorInfo">
                      {{ getDetailGeneral.taxCountryCode3 | nullToMinus }}
                    </strong>
                  </td>
                </tr>
                <tr v-if="isIndividual">
                  <td>{{ $t('investorDetails.general.input.countryOfBirth') }}</td>
                  <td>
                    <cp-select
                      v-if="isDetailsEditableCurrentBlock"
                      key="countryOfBirth"
                      :disabled="!canOperatorViewEditInvestorInfo"
                      placeholder
                      :options="taxCountryOptions"
                      :value="getDetailGeneral.countryOfBirth"
                      name="countryOfBirth"
                    />
                    <strong v-else-if="canOperatorViewEditInvestorInfo">
                      <cp-country-name
                        :country-code="getDetailGeneral.countryOfBirth"
                        :locale="locale"
                      />
                    </strong>
                    <strong v-else-if="!canOperatorViewEditInvestorInfo">
                      {{ getDetailGeneral.countryOfBirth | nullToMinus }}
                    </strong>
                  </td>
                </tr>
                <tr v-if="isIndividual">
                  <td>{{ $t('investorDetails.general.input.cityOfBirth') }}</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="cityOfBirth"
                      :disabled="!canOperatorViewEditInvestorInfo"
                      name="cityOfBirth"
                    />
                    <strong v-else>
                      {{ getDetailGeneral.cityOfBirth | nullToMinus }}
                    </strong>
                  </td>
                </tr>
                <tr v-if="isIndividual">
                  <td>{{ $t('investorDetails.general.input.identityDocumentNumber') }}</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="identityDocumentNumber"
                      :disabled="!canOperatorViewEditInvestorInfo"
                      name="identityDocumentNumber"
                    />
                    <strong v-else>
                      {{ getDetailGeneral.identityDocumentNumber | nullToMinus }}
                    </strong>
                  </td>
                </tr>
                <tr v-if="isEntity">
                  <td>{{ $t('investorDetails.general.input.entityDba') }}</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="entityDba"
                      name="entityDba"
                      :disabled="!canOperatorViewEditInvestorInfo"
                    />
                    <strong v-else>
                      {{ getDetailGeneral.entityDba | nullToMinus }}
                    </strong>
                  </td>
                </tr>
                <tr v-if="isEntity">
                  <td>{{ $t('investorDetails.general.input.entityLeiCode') }}</td>
                  <td>
                    <cp-input
                      v-if="isDetailsEditableCurrentBlock"
                      key="entityLeiCode"
                      name="entityLeiCode"
                      validate="min:20|max:20|regex:^[A-Za-z0-9]*$"
                      :disabled="!canOperatorViewEditInvestorInfo"
                    />
                    <strong v-else>
                      {{ getDetailGeneral.entityLeiCode | nullToMinus }}
                    </strong>
                    <p
                      v-if="isSpecialChars"
                      class="error-msg"
                    >
                      No special characters allowed
                    </p>
                  </td>
                </tr>
                <tr v-if="isEntity">
                  <td>{{ $t('investorDetails.general.input.entityType') }}</td>
                  <td>
                    <cp-select
                      v-if="isDetailsEditableCurrentBlock"
                      key="entityType"
                      placeholder
                      :disabled="!canOperatorViewEditInvestorInfo"
                      :options="general.entityType"
                      :value="getDetailGeneral.entityType"
                      name="entityType"
                    />
                    <strong v-else>
                      {{ getEntityType | nullToMinus }}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('investorDetails.general.input.creationDate') }}</td>
                  <td>
                    <strong>
                      {{ getDetailGeneral.createdAt | dateFilter }}
                    </strong>
                  </td>
                </tr>
                <tr v-if="isEntity">
                  <td>{{ $t('investorDetails.general.input.incorporationDate') }}</td>
                  <td>
                    <strong>
                      {{ getDetailGeneral.incorporationDate | dateFilterWOTz | nullToMinus }}
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <cp-comments
          v-if="getDetailGeneral.operatorComments || isDetailsEditableCurrentBlock"
          v-model="model.operatorComments"
          :is-editable="isDetailsEditableCurrentBlock"
          :comments-array="commentsArray"
        />
        <cp-json-textarea
          v-if="canOperatorViewEditInvestorInfo && (getDetailGeneral.jsonPrivate || isDetailsEditableCurrentBlock)"
          v-model="model.jsonPrivateToDisplay"
          name="jsonPrivate"
          :title="this.$t('investorDetails.general.input.jsonTextareaPrivate.label')"
          :is-editable="isDetailsEditableCurrentBlock"
        />
        <cp-json-textarea
          v-if="getDetailGeneral.jsonOpen || isDetailsEditableCurrentBlock"
          v-model="model.jsonOpen"
          name="jsonOpen"
          :title="this.$t('investorDetails.general.input.jsonTextareaOpen.label')"
          :is-editable="isDetailsEditableCurrentBlock"
        />
      </cp-input-container>
    </div>
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6" />
        <cp-edit-buttons
          :edit-mode="isDetailsEditableCurrentBlock"
          :disable-edit="disableGeneralInfoEdit"
          :tooltip-message="$t('investorDetails.tooltip.edit.message')"
          :show-tooltip="disableGeneralInfoEdit"
          @changeMode="changeModeProxy"
          @update="onUpdateSidPIIDetails"
        />
      </div>
    </div>
    <cp-confirm-modal
      ref="confirmInvestorTypeModal"
      :item="model"
      :title="$t('investorDetails.confirmInvestorTypeModal.title')"
      @onOk="onConfirmChangeInvestorType"
      @onCancel="onCancelChangeInvestorType"
    >
      {{ $t('investorDetails.confirmInvestorTypeModal.text') }}
    </cp-confirm-modal>
    <cp-confirm-modal
      ref="confirmChangePIIModal"
      :item="model"
      :title="$t('investorDetails.confirmPIIModel.title')"
      @onOk="onConfirmChangePIIDetails"
      @onCancel="onCancelChangePIIDetails"
    >
      {{ $t('investorDetails.confirmPIIModel.text') }}
    </cp-confirm-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions, mapState } from 'vuex';
import { general, getGenderByValue, getTypeByValue, entityTypeMap } from './options';
import states from '~/utilities/us-states-list';

import CpEditButtons from '~/components/common/edit-buttons';
import { CpDatePicker, CpSelect, CpInput } from '~/components/common/standalone-components/inputs';
import CpComments from '~/components/common/standalone-components/cp-comments';
import cpJsonTextarea from '~/components/common/standalone-components/cp-json-textarea';
import CpCountryName from '~/components/common/country-name';
import CpInputContainer from '~/components/common/cpInputContainer';
import CpEditableMixin from '~/mixins/editable-invester-details-component';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';

export default {
  name: 'CpInvestorsDetailsGeneral',
  components: {
    CpEditButtons,
    CpDatePicker,
    CpComments,
    CpInputContainer,
    CpSelect,
    CpInput,
    CpCountryName,
    CpConfirmModal,
    cpJsonTextarea,
  },
  mixins: [
    CpEditableMixin('general'),
  ],
  data() {
    return {
      states,
      general,
      disabledDates: {
        from: new Date(moment().subtract(15, 'years')),
      },
      openDate: new Date('1990-01-01'),
      model: {
        jsonOpen: null,
        jsonPrivate: null,
        jsonPrivateToDisplay: null,
      },
      dobIsValid: true,
      locale: undefined,
      isEntity: '',
      isIndividual: '',
      isCustodian: false,
      isSpecialChars: false,
      previousInvestorType: '',
    };
  },
  computed: {
    ...mapGetters('investors', ['getDetailGeneral', 'getDetailKyc']),
    ...mapGetters('global', ['countries']),
    ...mapGetters('issuersInfo', ['permissionsPerIssuer']),
    ...mapState('custodians', ['custodiansList']),
    ...mapState('currentOperator', ['authorizationLevel', 'authorizations']),
    getGender() {
      const { gender } = this.getDetailGeneral;
      const genderKey = gender && getGenderByValue(gender);
      return genderKey ? this.$t(genderKey) : '-';
    },
    canOperatorViewEditInvestorInfo() {
      if (this.permissionsPerIssuer) return this.permissionsPerIssuer.canOperatorViewEditInvestorInfo;
      return false;
    },
    commentsArray() {
      return (this.getDetailGeneral.operatorComments || '').split('\n');
    },
    countryOptions() {
      return (this.countries || []).map(item => ({
        text: item.name,
        value: item.countryCode,
      }));
    },
    taxCountryOptions() {
      if (!this.canOperatorViewEditInvestorInfo) return [{ value: this.getDetailGeneral.taxCountryCode, text: this.getDetailGeneral.taxCountryCode }];
      return this.countryOptions;
    },
    stateOptions() {
      return Object.keys(this.states).map(val => ({ text: this.states[val], value: val }));
    },
    generalDetailState() {
      const currentState = this.stateOptions.find(el => el.value === this.model.state);
      return currentState ? currentState.text : null;
    },
    investorTypeField() {
      const investorTypeKey = getTypeByValue(this.getDetailGeneral.investorType);
      return investorTypeKey && this.$t(investorTypeKey);
    },
    getInvestorTypeOptions() {
      if (this.canEditCustodian) return general.types.filter(type => type.value !== 'none');
      return general.types.filter(type => !type.value.startsWith('fbo') && type.value !== 'none');
    },
    isFboInvestor() {
      return ['fbo-individual', 'fbo-entity'].includes(this.model.investorType);
    },
    isNoneInvestor() {
      return ['none'].includes(this.model.investorType);
    },
    getCustodianOptions() {
      if (!this.canOperatorViewEditInvestorInfo) return [{ value: this.getDetailGeneral.custodianId, text: this.getCustodianName }];
      const custodianOptions = [{ value: null, text: '-' }];
      custodianOptions.push(...this.custodiansList.map(custodian => ({ value: custodian.id, text: custodian.name })));
      return custodianOptions;
    },
    getCustodianName() {
      return this.getDetailGeneral.custodianName || '-';
    },
    getCustodianId() {
      return this.getDetailGeneral.custodianId || null;
    },
    disableCustodian() {
      return !this.isFboInvestor;
    },
    canEditInvestorType() {
      return this.isFboInvestor ? this.canEditCustodian : true;
    },
    canEditCustodian() {
      return ['megaadmin', 'superadmin'].includes(this.authorizationLevel);
    },
    canEditSecuritizeIdInvestor() {
      const hasSecuritizeId = this.authorizations.includes('securitize-id');
      const hasSecuritizeIdService = this.authorizations.includes('securitize-id-service');
      return (this.authorizationLevel === 'megaadmin' && hasSecuritizeId) || hasSecuritizeIdService;
    },
    disableGeneralInfoEdit() {
      const { securitizeIdProfileId, registrationSource } = this.getDetailGeneral;
      const { kycStatus, kycProvider } = this.getDetailKyc;
      let isDisabled = false;
      const normalizedRegSource = registrationSource ? registrationSource.trim().toUpperCase() : registrationSource;

      if ((securitizeIdProfileId && !this.canEditSecuritizeIdInvestor)
              && kycStatus !== 'none'
              && kycProvider === 'securitize-id'
              && normalizedRegSource !== 'OFN') {
        isDisabled = true;
      }
      if (this.isEditButtonDisabled || !this.getDetailGeneral.isEditable) isDisabled = true;

      return isDisabled;
    },
    getEntityType() {
      return entityTypeMap[this.getDetailGeneral.entityType] || null;
    },
  },
  watch: {
    getDetailGeneral: {
      immediate: true,
      handler({ investorType }) {
        this.previousInvestorType = investorType;
        this.setInvestorViewType(investorType);
      },
    },
  },
  created() {
    this.changeMode(true);
    this.model = { ...this.getDetailGeneral };
    const { idIssuer: issuerId, tokenId } = this.$route.params;

    this.prepareDripPrefences(tokenId);

    if (this.canEditCustodian) this.getCustodians({ params: { issuerId } });

    this.$store.watch(({ global }) => {
      if (global && global.locale !== this.locale) {
        this.locale = global.locale;
      }
    });
  },
  methods: {
    getTypeByValue,
    ...mapActions('investors', ['getInvestorDetailsGeneral', 'updateInvestorDetailGeneral', 'getInvestorKYCStatusById']),
    ...mapActions('custodians', ['getCustodians']),
    setInvestorViewType(type) {
      this.isEntity = ['entity', 'fbo-entity'].includes(type);
      this.isIndividual = !this.isEntity;
    },
    prepareDripPrefences(tokenId) {
      const jsonPrivateParsed = this.model.jsonPrivate && JSON.parse(this.model.jsonPrivate);

      if (jsonPrivateParsed && 'dripPreferences' in jsonPrivateParsed && jsonPrivateParsed.dripPreferences.length > 0) {
        const dripFound = jsonPrivateParsed.dripPreferences.find(drip => drip.tokenId === tokenId);
        if (dripFound) {
          this.model.jsonPrivateToDisplay = JSON.stringify({ ...jsonPrivateParsed, dripPreferences: dripFound });
        }
      }
    },
    changeModeProxy() {
      if (!this.isDetailsEditableCurrentBlock) {
        this.model = {
          operatorComments: null,
          ...this.getDetailGeneral,
        };
      } else {
        this.setInvestorViewType(this.previousInvestorType);
      }
      this.changeMode();
    },
    onCancelChangeInvestorType() {
      this.model.investorType = this.previousInvestorType;
    },
    onChangeInvestorType(val, previousValue) {
      this.previousInvestorType = previousValue;
      this.$refs.confirmInvestorTypeModal.$refs.confirmModal.show();
    },
    onConfirmChangeInvestorType() {
      if (!this.isFboInvestor && ['fbo-individual', 'fbo-entity'].includes(this.previousInvestorType)) {
        this.model.custodianId = null;
        this.model.custodianName = null;
        this.model.email = this.model.fboEmail;
      }

      if (this.isFboInvestor && !['fbo-individual', 'fbo-entity'].includes(this.previousInvestorType)) {
        this.model.fboEmail = this.model.email;
      }
      this.isCustodian = this.isFboInvestor;
      this.setInvestorViewType(this.model.investorType);
    },
    allowedFields(fields) {
      if (this.canOperatorViewEditInvestorInfo) return fields;
      const {
        additionalInfo,
        cityOfBirth,
        countryOfBirth,
        identityDocumentNumber,
        phonePrefix,
        taxCountryCode2,
        taxCountryCode3,
        taxId2,
        taxId3,
        address1,
        address2,
        birthdate,
        city,
        custodianName,
        phoneNumber,
        taxCountry,
        taxCountryCode,
        taxId,
        zipCode,
        entityName,
        entityDba,
        entityLeiCode,
        jsonPrivate,
        ...allowedFields
      } = fields;
      return allowedFields;
    },
    onUpdateSidPIIDetails() {
      if (this.getDetailKyc.kycProvider === 'securitize-id') {
        this.$refs.confirmChangePIIModal.show();
      } else {
        this.saveChanges();
      }
    },
    onConfirmChangePIIDetails() {
      this.saveChanges();
    },
    onCancelChangePIIDetails() {
      this.changeModeProxy();
    },
    saveChanges() {
      if (this.model.countryCode !== 'US') this.model.state = null;
      if (this.model.entityLeiCode === '') this.model.entityLeiCode = null;

      this.$refs.cpInputContainer.validateForm().then(() => {
        if (this.$refs.cpInputContainer.isValid()) {
          const { idIssuer, detailsId } = this.$route.params;
          this.updateInvestorDetailGeneral({
            userId: detailsId,
            issuerId: idIssuer,
            body: this.allowedFields(this.model),
          }).then(() => {
            this.changeMode();
            this.model = { ...this.getDetailGeneral };
            this.getInvestorKYCStatusById({
              userId: detailsId,
              issuerId: idIssuer,
            });
            this.getInvestorDetailsGeneral({
              userId: detailsId,
              issuerId: idIssuer,
            });
          });
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
  .investor-type-field {
    padding: 10px 24px 10px 34px;
    align-items: baseline;
    span {
      margin-right: 5px;
    }
    div {
      width: 70%;
    }
  }
  .full-width {
    margin-top: -24px;
    margin-right: -24px;
    margin-left: -24px;
  }
  .investor-type-value {
    text-transform: capitalize;
  }
  .sec-saving::after{
    content:"";
    position: absolute;
    width:100%;
    height:100%;
    background: #fff;
    opacity: 0.4;
  }
  .error-msg{
    font-size: 85%;
    color: #f7321e !important;
    margin: 0.25rem;
    display: block !important;
  }
</style>
