// TO BE USED with selector.vue parent component/
import { mapState } from 'vuex';

export default {
  data() {
    return {
      tokensSelectorModel: this.$route.params.tokenId,
    };
  },
  computed: {
    ...mapState('issuersInfo', ['issuerInfo']),
    allTokensList() {
      const allTokensList = this.issuerInfo.tokens.map(token => ({ value: token.id, text: token.symbol || token.name }));
      return [{ value: 'allTokens', text: 'All tokens' }].concat(allTokensList);
    },
  },
  watch: {
    $route({ params: { tokenId: newTokenId } }, { params: { tokenId: oldTokenId } }) {
      if (newTokenId && newTokenId !== oldTokenId) {
        this.tokensSelectorModel = newTokenId;
      }
    },
  },
};
