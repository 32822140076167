<template>
  <cp-general-modal
    ref="CpAddTransaction"
    :title="$t('investorDetails.addTransactionModal.title')"
    :ok-disabled="disabled"
    @onOk="addTransaction"
    @onInput="showData"
    @onCancel="resetModalData"
    @shown="resetModalData"
  >
    <div class="modal-body">
      <div class="row">
        <div class="col-md-6">
          <cp-date-picker
            name="transactionTime"
            :label="$t('investorDetails.addTransactionModal.input.date')"
            validate="required"
          />
        </div>
        <div class="col-md-6">
          <cp-select
            v-model="selectedTransaction"
            placeholder
            :options="transactionTypes"
            name="direction"
            :label="$t('investorDetails.addTransactionModal.input.direction')"
            validate="required"
            @input="selectedTransactionType"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <cp-input
            v-model="amount"
            name="amount"
            :label="$t('investorDetails.addTransactionModal.input.amount.text')"
            :placeholder="$t('investorDetails.addTransactionModal.input.amount.placeholder')"
            :validate="validateAmount"
          />
        </div>
        <div class="col-md-6">
          <cp-select
            v-model="selectedCurrency"
            placeholder
            :options="addTransactionCurrencies"
            :label="$t('investorDetails.addTransactionModal.input.currency')"
            name="currencyId"
            validate="required"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="form-label">
              {{ $t('investorDetails.addTransactionModal.input.worth', [issuerMainCurrencyIdentifier]) }}
            </label>
            <div
              v-if="!isLiquidity"
              class="form-control-plaintext"
            >
              {{ issuerMainCurrencyIdentifier | currencySymbol }} {{ getPriceRate }}
            </div>
            <cp-input
              v-if="isLiquidity"
              name="usdWorth"
              :value="editedAmount"
              :placeholder="$t('investorDetails.addTransactionModal.input.amount.placeholder')"
              validate="required|decimal|min_value:0|excluded:0"
              @input="setEditedAmount"
            />
          </div>
        </div>
        <div class="col-md-6">
          <cp-input
            :label="$t('investorDetails.addTransactionModal.input.txid')"
            name="externalTransactionConfirmation"
          />
        </div>
      </div>
    </div>
  </cp-general-modal>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import moment from 'moment';
import CpDatePicker from '~/components/common/standalone-components/inputs/cp-date-picker';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpCurrencyFormatter from '~/mixins/currency-formatter';

export default {
  name: 'TransactionModal',
  components: {
    CpSelect,
    CpInput,
    CpDatePicker,
    CpGeneralModal,
  },
  mixins: [
    CpCurrencyFormatter,
  ],
  props: {
    roundId: {
      type: [String, Number],
      default: null,
    },
    depositWallets: {
      type: Array,
      default: () => [],
    },
    investmentRequest: {
      type: Object,
      default: () => {},
    },
    tokenId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      disabled: false,
      currencyId: 0,
      currentRoundId: this.roundId,
      currentTokenId: this.tokenId || this.$route.params.tokenId,
      currentDepositWallets: this.depositWallets,
      currentInvestmentRequest: this.investmentRequest,
      isLiquidity: false,
      amount: null,
      editedAmount: null,
      selectedCurrency: null,
      selectedTransaction: null,
      editedAmountTouched: false,
      transactionTypes: [
        { value: 'deposit', i18nKey: 'investorDetails.option.transactionType.deposit' },
        { value: 'withdraw', i18nKey: 'investorDetails.option.transactionType.withdraw' },
        { value: 'liquidation', i18nKey: 'investorDetails.option.transactionType.liquidation' },
        { value: 'refund', i18nKey: 'investorDetails.option.transactionType.refund' },
      ],
    };
  },
  computed: {
    ...mapGetters('global', ['currencyById', 'currencies', 'currencyByIdentifier']),
    ...mapState('global', ['issuerMainCurrencyIdentifier']),
    getCurrenciesForSelectElement() {
      return this.currencies.map(({ id, identifier, minimumInvestmentAmount }) => ({
        value: { id, minimumInvestmentAmount },
        text: identifier,
      }));
    },
    getPriceRate() {
      return this.calculatePriceRate(this.currencyId, this.amount);
    },
    addTransactionCurrencies() {
      const wallets = this.currentDepositWallets;
      const walletCurrencyMap = wallets.reduce((agr, wallet) => {
        agr[wallet.type] = true;
        return agr;
      }, {});
      return this.getCurrenciesForSelectElement.filter(
        ({ value, text }) => walletCurrencyMap[value] || walletCurrencyMap[text],
      );
    },
    shouldCalculatedEditedAmount() {
      return this.amount && this.selectedCurrency && this.selectedCurrency.id && this.isLiquidity && !this.editedAmount && !this.editedAmountTouched;
    },
    validateAmount() {
      if (this.selectedCurrency && this.selectedCurrency.minimumInvestmentAmount) {
       const minValue = this.selectedCurrency.minimumInvestmentAmount;
        return `required|min_value:${minValue}`;
      }
      return 'required';
    },
  },
  watch: {
    roundId: {
      handler(newId, OldId) {
        if (newId !== OldId) {
          this.currentRoundId = newId;
        }
      },
    },
    tokenId: {
      handler(newId, OldId) {
        if (newId !== OldId) {
          this.currentTokenId = newId;
        }
      },
    },
    depositWallets: {
      handler(newVal) {
        this.currentDepositWallets = newVal;
      },
    },
    shouldCalculatedEditedAmount: {
      handler(newVal) {
        if (newVal) this.editedAmount = this.calculatePriceRate(this.currencyId, this.amount);
      },
    },
  },
  methods: {
    ...mapActions('investors', ['addInvestorDetailTransaction']),
    show() {
      this.$refs.CpAddTransaction.show();
    },
    showData({ currencyId, amount }) {
      this.currencyId = currencyId && currencyId.id;
      this.amount = amount;
    },
    setTime(date) {
      return moment(date).startOf('day').toISOString(true);
    },
    addTransaction(data) {
      this.disabled = true;
      const { idIssuer: issuerId, detailsId: userId } = this.$route.params;
      data.depositWalletId = this.currentDepositWallets.find(depositWallet => depositWallet.identifier === data.currencyId.identifier).id;
      data.usdWorth = this.editedAmount;

      if (!this.isLiquidity) data.usdWorth = undefined;

      this.addInvestorDetailTransaction({
        issuerId,
        userId,
        roundId: this.currentRoundId,
        body: {
          externalTransactionConfirmation: '',
          ...data,
          currencyId: data.currencyId.id,
          transactionTime: this.setTime(data.transactionTime),
          investmentRoundId: this.currentRoundId,
          tokenId: this.currentTokenId,
          investmentRequestId: this.investmentRequest.id,
        },
      }).then(() => {
        this.$refs.CpAddTransaction.hide();
        this.$emit('transactionIsAdded');
      }).finally(() => {
        this.disabled = false;
      });
    },
    selectedTransactionType(value) {
      this.isLiquidity = value === 'liquidation';
      if (!this.isLiquidity && this.editedAmount) {
        this.editedAmountTouched = false;
        this.editedAmount = 0;
      }
    },
    setEditedAmount(value) {
      this.editedAmountTouched = true;
      this.editedAmount = value;
    },
    resetModalData() {
      this.currencyId = 0;
      this.isLiquidity = false;
      this.editedAmount = null;
      this.amount = null;
      this.editedAmountTouched = false;
      this.selectedCurrency = null;
      this.selectedTransaction = null;
    },
    calculatePriceRate(currencyId, amount) {
      const currency = this.currencyById(currencyId);
      const value = (amount && !Number.isNaN(+amount)) ? amount : 0;
      if (!currency) {
        return 0;
      }
      const { rate } = currency;
      return value * (rate || 0);
    },
  },
};
</script>
