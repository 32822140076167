var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cp-general-modal',{ref:"CpSubscriptionAgreementStatusLogs",attrs:{"title":_vm.$t('investorDetails.subscriptionAgreementStatusLogsModal.title')}},[_c('div',{staticClass:"modal-body"},[_c('cp-table',{ref:"subscriptionAgreementStatusLogsTable",staticStyle:{"max-height":"50vh","overflow-y":"auto"},attrs:{"without-search":"","without-pagination":"","get-data-action":"investors/getSubscriptionAgreementStatusLogs","url-params":{roundId: _vm.roundId,investmentRequestId: _vm.investmentRequestId,tokenId: _vm.tokenId,issuerId: _vm.issuerId,investorId: _vm.investorId },"fields":_vm.fields},scopedSlots:_vm._u([{key:"subscriptionAgreementSignedAt",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFilter")(rowData.item.subscriptionAgreementSignedAt)))])]}},{key:"signerType",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s(rowData.item.signerType))])]}},{key:"signerDetails",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s((rowData.item.signerDetails && rowData.item.signerDetails.name) || (_vm.getDetailGeneral && _vm.getDetailGeneral.name)))])]}},{key:"subscriptionAgreementStatus",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s(rowData.item.subscriptionAgreementStatus))])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }